import React, {useEffect,useState }  from "react";

function ModalCongratulation(data) {
  const [modal, setModal]= useState(false);
  const {examResults} = data;
  const [cl, setCl]= useState(false);
  useEffect(()=>{
    const test =  document.getElementById('test');
    if(test && !cl){
      test.click();
      setCl(true);
    }
  })
  return (
    <div className="row" >
      <div className="col-xl-1">
        <div className="card">
          <div className="card-body">
            
            
            <button
              type="button" id="test"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop" style={{display:"none"}}
            >
              Launch static backdrop modal
            </button>

            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              style={{ display: modal? "block":"none" }}
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      Modal title
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  
                  <div className={`modal-body ${examResults.note/examResults.totalNote>0.5 ? "bg-success":"bd-danger"}`}>
                    <>
                    <h3>
                      Your result: {examResults.note} / {examResults.totalNote}
                    </h3>
                    </>

                    <section>
                      <div className="rt-container">
                        <div className="col-rt-12">
                          <div className="Scriptcontent">
                            {/* DEMO CONTENT */}
                            <div className="mt-4 mb-4"> {/* className="congrats" */}
                              {examResults.note/examResults.totalNote>0.5 ? (<h1>Congratulations🥳🥳  you've done it🎊🎊 !!</h1>):(<h1>Sorry😞😞, you will do better the next time.</h1>)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>


                    {/* <div className="row card-body" dangerouslySetInnerHTML={{ __html:`
                      <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
                      <lottie-player src="https://lottie.host/2c20ad54-2d01-44f3-a052-fc12a479edec/HQB6Dhcqsa.json" background="#fff" speed="1" style="width: 700px; height: 500px" loop  autoplay direction="1" mode="normal"></lottie-player>`
                      }}>
                    </div> */}
                    </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalCongratulation;