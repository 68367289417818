import React, { useState, useEffect } from "react"
import Axios from "axios";
import {useNavigate} from 'react-router-dom';
import NavBar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import ENCRYPTION_KEY from '../../key';
import encryptTextWithKey from './../../utils';
const CryptoJS = require('crypto-js');




function SchoolAdd() {

    const [schoolName, setSchoolName] = useState('');
    const [directorEmail, setSetSchoolDirectorEmail] = useState('');
    const [schoolCity, setSchoolCity] = useState('');
    const [schoolAddress, setSchoolAddress] = useState('');
    const [schoolLocation, setSchoolLocation] = useState('');
    const [schoolCreationDate, setSchoolCreationDate] = useState('');
    const [schoolWebSite, setSchoolWebSite ] = useState('')

    const [AddStatus, setAddStatus] = useState('');


    const [userInfo, setUserInfo] = useState([])
    const [isLoggedIn, setIsLoggedIn] = useState(false)


    const navigate = useNavigate();


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    const createTeacher = (e) => {
        e.preventDefault();
        if (schoolName.trim() === '' || schoolCreationDate.trim()=== "") {
            setAddStatus("Veuillez au moins remplir le nom et la date de creation")
        } else {
            
            const data = {
                name: schoolName,
                address: schoolAddress,
                city: schoolCity,
                location:schoolLocation,
                date: schoolCreationDate ,
                email:directorEmail,
                website:schoolWebSite,
            }
            
            Axios.post(`https://${domainName}/api/school-add`, data).then(() => {
                setSchoolName('');
                setSchoolAddress('');
                setSchoolCity('');
                setSchoolCreationDate('');
                setSetSchoolDirectorEmail('');


                setAddStatus(`The school has been created!`);
                navigate('/school-list');
            })
        }
    }
    useEffect(()=>{
        try {
            const encryptedData = localStorage.getItem('userInfo');
            if (encryptedData) {
              const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
              if (decryptedData) {
                const userInfo = JSON.parse(decryptedData);
                console.log("decryptDate:", decryptedData);
                setUserInfo(userInfo);
                setIsLoggedIn(true);
                }
            }}catch(err){
                
            }
    });

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Add school </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">School Informations</h5>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The School's Name:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="School Name" value={schoolName}
                                                onChange={(event) => setSchoolName(event.target.value)} required />
                                            </div>
                                         </div>
                                    </div>
                                        

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">School Campus Address</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="School Address" value={schoolAddress}
                                                onChange={(event) => setSchoolAddress(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">School Campus CitY</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="School City" value={schoolCity}
                                                onChange={(event) => setSchoolCity(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">School Campus Location Place</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Loaction Place" value={schoolLocation}
                                                onChange={(event) => setSchoolLocation(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">School Official Website</label>
                                        <div class="col-lg-10">
                                            <div class="input-group">
                                                <span class="input-group-text">https://</span>
                                                <input type="text" class="form-control "onChange={(event) => setSchoolWebSite(event.target.value)}  value={schoolWebSite}required/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Creation's Date</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="date"  class="form-control input-height" placeholder="" aria-describedby="The of First Day Class" value={schoolCreationDate}
                                                onChange={(event) => setSchoolCreationDate(event.target.value)}  required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Director's Email </label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="email" class="form-control input-height" placeholder="" aria-describedby="The Full Name of the Teacher" value={directorEmail}
                                                onChange={(event) => setSetSchoolDirectorEmail(event.target.value)} required />
                                            </div>
                                         </div>
                                    </div>
                                        


                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default SchoolAdd