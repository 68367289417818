import React, { swal, useState, useEffect } from 'react'

import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import { NavLink } from "react-router-dom";
import Axios from 'axios';


function ExamsUpdate(){

    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');
    
    return (

    <div classNameName="main-wrapper">

            <NavBar user="Teacher"/>
            
            <SideBar />
            <div class="page-wrapper">
                    <div class="content container-fluid">

                    <div class="page-header">
                    <div class="row align-items-center">
                    <div class="col">
                    <h3 class="page-title">Modifier un  Examen</h3>
                    <ul class="breadcrumb">
                    <li class="breadcrumb-item active">Modifier un Examen</li>
                    </ul>
                    </div>
                    </div>
                    </div>

                    <div class="row">
                    <div class="col-sm-12">
                    <div class="card">
                    <div class="card-body">
                    <form>
                    <div class="row">
                    <div class="col-12">
                    <h5 class="form-title"><span>Information sur l'examen</span></h5>
                    </div>
                    <div class="col-12 col-sm-6">
                    <div class="form-group">
                    <label>Nom de l'examen</label>
                    <input type="text" class="form-control" value ="INFO241"/>
                    </div>
                    </div>
                    
                    <div class="col-12 col-sm-6">
                    <div class="form-group">
                    <label>Sujet</label>
                    <input type="text" class="form-control" value ="Introduction au SI"/>
                    </div>
                    </div>
                    
                    <div class="col-12 col-sm-6">
                    <div class="form-group">
                    <label>Heure de début</label>
                    <input type="time" class="form-control" value ="8h45 AM"/>
                    </div>
                    </div>
                    <div class="col-12 col-sm-6">
                    <div class="form-group">
                    <label>Heure de fin</label>
                    <input type="time" class="form-control" value ="10h45 AM"/>
                    </div>
                    </div>
                    <div class="col-12 col-sm-6">
                    <div class="form-group">
                    <label>Date d'évènements</label>
                    <input type="date" class="form-control" value ="01-11-2023"/>
                    </div>
                    </div>

                    <div class="col-12 col-sm-6">
                    <div class="form-group">
                    <label for="pdfFile">Sélectionnez une épreuve en PDF :</label>
                    <input type="file" id="pdfFile" accept=".pdf" />
                    </div>
                    </div>

                    <div class="col-12">
                    <button type="submit" class="btn btn-primary">Soumettre</button>
                    </div>
                    </div>
                    </form>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>

                    </div>

        
                



    )}
    export default  ExamsUpdate;