import React, { useState, useEffect } from 'react';
import NavBar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import Axios from 'axios';
import CryptoJS from 'crypto-js'
import ENCRYPTION_KEY from '../../key';
import { useNavigate, Link } from 'react-router-dom';

function SchoolList() {
  const [userInf, setUserInf] = useState([]);
  const [userCode, setUserCode] = useState('');

  if(userCode === ''){
    try {
        const encryptedData = sessionStorage.getItem('userInfo');
        if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            const userInfo = JSON.parse(decryptedData);
            setUserInf(userInfo);
            setUserCode(userInfo.code)

        }
    }catch(err){
            
        }
    }
    
  const [schoolsList, setSchoolsList] = useState([]);
const [hoveredIndex, setHoveredIndex] = useState(null);


  const navigate = useNavigate();


  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');
  

  useEffect(() => {
    Axios.get(`https://${domainName}/api/school-list`)
      .then((response) => {
        console.log(response.data);
        setSchoolsList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  
function result1(){
  return (
    <div className="main-wrapper">
      <NavBar />
      <SideBar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Schools List</h3>
                <ul className="breadcrumb"></ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">All Schools</h5>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <label>
                              Show{' '}
                              <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>{' '}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6"></div>
                      </div>
                      <div className="row">
					  <div className="col-sm-12">
					    <table className="datatable table table-stripped dataTable no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
					      <thead>
					        <tr role="row">
					          <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '155.797px' }}>
					            School Name
					          </th>
					          <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Position: activate to sort column ascending" style={{ width: '252.953px' }}>
					            Address
					          </th>
					          <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Office: activate to sort column ascending" style={{ width: '113.062px' }}>
					            Location
					          </th>
					          <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{ width: '48.3594px' }}>
					            City
					          </th>
					          <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Start date: activate to sort column ascending" style={{ width: '100.781px' }}>
					            Country
					          </th>
					          <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Salary: activate to sort column ascending" style={{ width: '86.0469px' }}>
					            Budget
					          </th>
					          <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{ width: '48.3594px' }}>
					            Director's Mail
					          </th>

					        </tr>
					      </thead>
					      <tbody>
					        {schoolsList.map((school, index) => (
					          <tr key={index}
					          onClick={() =>  navigate(`/school-details/${school.id}`)} // Remplacez "school.id" par l'identifiant approprié pour la redirection vers la page de détails
						      style={{ cursor: 'pointer' }}
						      onMouseEnter={() => setHoveredIndex(index)}
						      onMouseLeave={() => setHoveredIndex(null)}
						      style={{
						        cursor: 'pointer',
						        backgroundColor: hoveredIndex === index ? 'lightgray' : 'inherit',
						      }}
						      >
					            <td>{school.schoolName}</td>
					            <td>{school.address}</td>
					            <td>{school.location}</td>
					            <td>{school.city}</td>
					            <td>{school.country}</td>
					            <td>{school.budget}</td>
					            <td>{school.directorEmail}</td>
					          </tr>
					        ))}
					      </tbody>
					    </table>
					  </div>
					</div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5"></div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="DataTables_Table_0_paginate"
                          >
                            <ul className="pagination">
                              <li
                                className="paginate_button page-item previous disabled"
                                id="DataTables_Table_0_previous"
                              >
                                <Link
                                  to="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="0"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  Previous
                                </Link>
                              </li>
                              <li className="paginate_button page-item active">
                                <Link
                                  to="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="1"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  1
                                </Link>
                              </li>
                              <li className="paginate_button page-item ">
                                <Link
                                  to="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="2"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  2
                                </Link>
                              </li>
                              <li className="paginate_button page-item next" id="DataTables_Table_0_next">
                                <Link
                                  to="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="3"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  Next
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


function sortTableBySchoolName() {
  const table = document.getElementById("DataTables_Table_0");
  const tbody = table.querySelector("tbody");
  const rows = Array.from(tbody.querySelectorAll("tr"));

  rows.sort((a, b) => {
    const schoolNameA = a.querySelector("td:nth-child(3) a").innerText.toLowerCase();
    const schoolNameB = b.querySelector("td:nth-child(3) a").innerText.toLowerCase();
    if (schoolNameA < schoolNameB) return -1;
    if (schoolNameA > schoolNameB) return 1;
    return 0;
  });

  rows.forEach(row => tbody.appendChild(row));
}

function sortTableByBudget() {
  const table = document.getElementById("DataTables_Table_0");
  const rows = Array.from(table.querySelectorAll("tbody tr"));

  rows.sort((a, b) => {
    const budgetA = parseFloat(a.querySelector("td:nth-child(4)").innerText);
    const budgetB = parseFloat(b.querySelector("td:nth-child(4)").innerText);
    return budgetA - budgetB;
  });

  rows.forEach(row => table.querySelector("tbody").appendChild(row));
}

function sortTableByCity() {
  const table = document.getElementById("DataTables_Table_0");
  const rows = Array.from(table.querySelectorAll("tbody tr"));

  rows.sort((a, b) => {
    const cityA = a.querySelector("td:nth-child(5)").innerText.toLowerCase();
    const cityB = b.querySelector("td:nth-child(5)").innerText.toLowerCase();
    return cityA.localeCompare(cityB);
  });

  rows.forEach(row => table.querySelector("tbody").appendChild(row));
}

function sortTableByCountry() {
  const table = document.getElementById("DataTables_Table_0");
  const rows = Array.from(table.querySelectorAll("tbody tr"));

  rows.sort((a, b) => {
    const countryA = a.querySelector("td:nth-child(6)").innerText.toLowerCase();
    const countryB = b.querySelector("td:nth-child(6)").innerText.toLowerCase();
    return countryA.localeCompare(countryB);
  });

  rows.forEach(row => table.querySelector("tbody").appendChild(row));
}

function sortTableByNumberOfStudents() {
  const table = document.getElementById("DataTables_Table_0");
  const rows = Array.from(table.querySelectorAll("tbody tr"));

  rows.sort((a, b) => {
    const numberOfStudentsA = parseInt(a.querySelector("td:nth-child(8)").innerText);
    const numberOfStudentsB = parseInt(b.querySelector("td:nth-child(8)").innerText);
    return numberOfStudentsA - numberOfStudentsB;
  });

  rows.forEach(row => table.querySelector("tbody").appendChild(row));
}


return(
  <div className="main-wrapper">
    <NavBar />
    <SideBar />
      
    <div className="page-wrapper">
    <div class="content container-fluid">

      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <div class="page-sub-header">
              <h3 class="page-title">Schools</h3>

            </div>
          </div>
        </div>
      </div>

      <div class="School-group-form">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Search by ID ..." />
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Search by Name ..." />
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Search by Phone ..." />
            </div>
          </div>
          <div class="col-lg-2">
            <div class="search-School-btn">
              <button type="button" class="btn btn-primary">Search</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table comman-shadow">
            <div class="card-body">

              <div class="page-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h3 class="page-title">Schools</h3>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a to="#" class="btn btn-outline-gray me-2 active"><i class="feather-list"></i></a>
                    <a to="#" class="btn btn-outline-gray me-2"><i class="feather-grid"></i></a>
                    <a to="#" class="btn btn-outline-primary me-2"><i class="fas fa-download"></i> Download</a>
                    {userInf && userInf.type==='admin' && (<Link to="/school-add"  onClick={(e) => {e.preventDefault()
                     navigate("/school-add")}} class="btn btn-primary"><i class="fas fa-plus"></i></Link>)}
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length" id="DataTables_Table_0_length">
                        <label>
                          Show 
                          <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select custom-select-sm form-control form-control-sm">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select> entries
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <table class="table border-0 star-School table-hover table-center mb-0 datatable table-striped dataTable no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                        <thead class="School-thread">
                          <tr role="row">
                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Select all rows: activate to sort column ascending" style={{ width: '37.75px' }}>
                              <div class="form-check check-tables">
                                <input class="form-check-input" type="checkbox" value="something" />
                              </div>
                            </th>
                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="ID: activate to sort column ascending" style={{ width: '63.8125px' , cursor: 'pointer' }} >ID</th>
                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" style={{ width: '194.375px', cursor: 'pointer' }} onClick={sortTableBySchoolName} >School Name</th>
                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Class: activate to sort column ascending" style={{ width: '56.3906px' , cursor: 'pointer' }}>Budget</th>
                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="DOB: activate to sort column ascending" style={{ width: '93.8438px' , cursor: 'pointer' }}>City</th>
                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Parent Name: activate to sort column ascending" style={{ width: '118.391px' , cursor: 'pointer' }}>Country</th>
                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Mobile Number: activate to sort column ascending" style={{ width: '138.328px' , cursor: 'pointer' }}> Address</th>
                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Address: activate to sort column ascending" style={{ width: '355.5px', cursor: 'pointer' }}> Number Of Students</th>
                            <th class="text-end" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Action: activate to sort column descending" aria-sort="ascending" style={{ width: '93.6094px' , cursor: 'pointer' }}>Action</th>
                          </tr>
                        </thead>

                        <tbody >
                       {schoolsList.map((school, index) => (
                          <tr
                            role="row"
                            className={school.id % 2 === 0 ? "odd" : "event"}
                            key={index}
                            style={{ cursor: "pointer", backgroundColor: hoveredIndex === index ? "lightgray" : "inherit" }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                          >
                            <td className="">
                              <div className="form-check check-tables">
                                <input className="form-check-input" type="checkbox" value="something" />
                              </div>
                            </td>
                            <td>{school.id}</td>
                            <td>
                              <h2 className="table-avatar">
                                <Link to={`school-details/${school.id}`} className="avatar avatar-sm me-2">
                                </Link>
                                <Link to="">{school.schoolName}</Link>
                              </h2>
                            </td>
                            <td>{school.budget}</td>
                            <td>{school.city}</td>
                            <td>{school.country}</td>
                            <td>{school.address}</td>
                            <td>{school.numberOfStudents}</td>
                            <td className="text-end sorting_asc">
                              <div className="actions ">
                                <Link to={`../school-details/${school.id}`} className="btn btn-sm bg-success-light me-2">
                                  <i className="feather-eye"></i>
                                </Link>
                                <Link to={`../school-update/${school.id}`} className="btn btn-sm bg-danger-light">
                                  <i className="feather-edit"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 11 entries</div>
                      </div>
                      <div class="col-sm-12 col-md-7">
                        <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                          <ul class="pagination">
                            <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous">
                              <Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Previous</Link>
                            </li>
                            <li class="paginate_button page-item active">
                              <Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</Link>
                            </li>
                            <li class="paginate_button page-item ">
                              <Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link">2</Link>
                            </li>
                            <li class="paginate_button page-item next" id="DataTables_Table_0_next">
                              <Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">Next</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
);
}
export default SchoolList;