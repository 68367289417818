import React, { useState, useEffect } from 'react';

import NavBar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import ENCRYPTION_KEY from '../../key';
import ModalCongratulation from './ModalCongratulation';
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import Axios from 'axios';

function ExamsTeacher() {
  const [questions, setQuestions] = useState([]);
  const [questionsR, setQuestionsR] = useState([]);
  const [responses, setResponses] = useState([]);
  const [status, setStatus] = useState('') 
  const [userCode, setUserCode] = useState('');
  const [userInf, setUserInf] = useState([]);
  const [compteARebours, setCompteaRebours] = useState(0);
  const [isTeacher, setIsTeacher] = useState(false);
  const [exam, setExam] = useState('');
  const [examResults, setExamResults] = useState([]);
  const navigateTo = useNavigate();


  const {examId} = useParams();

  if(userCode === ''){
    try {
        const encryptedData = sessionStorage.getItem('userInfo');
        if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            const userInfo = JSON.parse(decryptedData);
            if (decryptedData && userInfo.type !== "admin" ) {
              if (userInfo.type === 'teacher') {
                setIsTeacher(true)
              }
            setUserCode(userInfo.code);
            setUserInf(userInfo);
            }
        }else{
            alert("Page only permit to teachers and students");
            navigateTo('/login');
        }}catch(err){
            
        }
    }

    function submiting  (){
      if (responses.length !== 0) {
        console.log(responses);
        Axios.post(`https://${domainName}/api/exam-response/${userCode}`, {responses: responses, usercode: userCode})
          .then((res) => {
            setStatus(res.data.message);
            window.history.back();  

          })
          .catch((err) => {
            console.log(err);
            setStatus(`${err.name}: ${err.message}`);
          });
      } else {
        alert("something went wrong  !!");
      }
    }
    
  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');

  useEffect(()=>{
    if (questions && questions.length === 0) {
        Axios.get(`https://${domainName}/api/exam-get/${examId}/${userCode}`)
        .then((res)=>{
            console.log(res.data);
            if (res.data.message) {
              setStatus(res.data.message && res.data.message === "You have already done this exam");
               Axios.get(`https://${domainName}/api/exam-responses/${examId}/${userCode}`)
                      .then((resp)=>{
                        console.log(resp.data)
                          setQuestionsR(resp.data.questions);
                      })
                      .catch((err)=>{
                          console.log(err.message);
                      });

                      Axios.get(`https://${domainName}/api/exam-student-results/${examId}/${userCode}`)
                      .then((respon)=>{
                          setExamResults(respon.data[0]);
                      })
                      .catch((error)=>{
                          console.log(error.message);
                      });
                      return;
            }
            if (isTeacher && res.data.exam.teacherCode !== userInf.code) {
              alert("You don't have acces of this course as a teacher if your're not the owner !!");
              window.history.back();

            }
            setQuestions(res.data.questions);
            setExam(res.data.exam);
            const dateHeureInput = new Date(res.data.exam.deadline);
            
            const dateHeureActuelle = new Date();
            
            const difference = dateHeureActuelle.getTime() - dateHeureInput.getTime();
            if (difference > 0) {
              alert("The deadline for this exam has passed!!");
              window.history.back();
            }
        })
        .catch((err)=>{
            setStatus(err.message);
        });
    }

    // Compte à rebours
    const durationInMinutes = parseInt(exam.duration);
    let secondsRemaining = durationInMinutes * 60;

    const interval = setInterval(() => {
      const minutes = Math.floor(secondsRemaining / 60);
      const seconds = secondsRemaining % 60;

      setCompteaRebours(`${minutes}:${seconds}`);

      if (minutes < 3 ) {
        const t= document.getElementById('compte-rebours')
        if(t){
          t.style.color = 'red';
        }
      }

      if (secondsRemaining <= 0) {
        clearInterval(interval);
        submiting()
        const id = setTimeout(() => {
        window.history.back();
        }, 1000);
        
        // Définir une fonction de rappel pour annuler la temporisation
        const cancel = () => {
            clearTimeout(id);
        };
        
        // Annuler la temporisation après 5 secondes
        setTimeout(cancel, 5000);
      } else {
        secondsRemaining--;
      }
    }, 1000);

    return () => clearInterval(interval);

  }, [questions])


      const renderResponses = (question, index) => {
        if (question && question.type) {
          switch (question.type) {
            case 'text':
              return (
                <div className="form-group card-body container-fluid" >
                  <label className="col-form-label col-md-12 label-multiline">{question.questionText}</label>
                  <p className="label-multiline" >{question.responseText} <span className="text-danger">You have {question.score} pts</span></p>
                 {question.comment &&( 
                 <> <h4 style={{"font-style":"underline"}}> Comment of teacher</h4>
                  <p className="label-multiline">{question.comment.content}</p></>)}
                </div>
              );
            case 'multipleChoice':
              return (
                <div className="form-group row">
                  <label className="col-form-label col-md-12 border-none label-multiline">{questionsR[index].questionText}</label>
                  {questionsR[index].options && questionsR[index].options.length > 0 ? (
                    <div>
                      {questionsR[index].options.map((opt, opInde) => (
                        <div className="form-check" key={opInde}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={opt.is_true}
                            disabled={true}
                          />
                          <label className="form-check-label">{opt.option}</label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span style={{ cursor: 'danger', color: 'red' }}>
                      Something went wrong
                    </span>
                  )}
                </div>
              );
            case 'trueFalse':
              return (
                <div className="form-group row">
                <label className="col-form-label col-md-12 label-multiline ">{question.questionText}</label>
                <div className="form-check">
                  <input
                    type="radio"
                    style={{ marginRight: '7px', cursor: 'not-allowed' }}
                    name="trueFalse"
                    checked={parseInt(question.is_true) >0}
                    disabled={true}
                  />
                  <label className="form-check-label">True</label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    style={{ marginRight: '7px', cursor: 'not-allowed' }}
                    name="trueFalse"
                    checked={parseInt(question.is_true) === 0}
                    disabled={true}
                  />
                  <label className="form-check-label">False</label>
                </div>
              </div>
              
              );
            default:
              return null;
          }
        }
      };
      

  const renderQuestionForm = (question, index) => {
    if (question && question.type ) {
      switch (question.type) {
        case 'text':
          return (
            <div className="form-group row">
              <label class="col-form-label col-md-12 label-multiline"  >{question.questionText}  (<span style={{ color:'gray'}}>{question.points}pts</span>)</label>
                
              
              <textarea className = "form-control" type="text" onBlur={(e)=>{
                 setResponses((prevResponses) => {
                  const newResp =  [...prevResponses]; 
                  newResp[index] = {response: e.target.value, type:'text',  questionId: question.id,  isTeacher:isTeacher};
                  return newResp;
              })}}></textarea>
            </div>
          );
          case 'multipleChoice':
            return (
              <div className="form-group row">
                <label
                  className=" col-form-label col-md-12 border-none"
                  >{questions[index].questionText}   (<span style={{ color:'gray'}}>{questions[index].points}pts</span>)</label>
                {questions[index].options && questions[index].options.length > 0 ? (
                  <div>
                    {questions[index].options.map((opt, opInde) => (
                      <div className="form-check" key={opInde}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onClick={(e) => {
                            setResponses((prevResponses) => {
                              const newResp =  [...prevResponses];
                              let updatedQuestion = { ...newResp[index] };
                              if (Object.keys(updatedQuestion).length > 0) {
                              updatedQuestion.options[opInde] = { optionId:opt.optionId, response: e.target.checked};
                            } else {
                              updatedQuestion = {options:{}, type:'multipleChoice',  questionId: question.id, isTeacher:isTeacher };
                              updatedQuestion.options[opInde] = { optionId:opt.optionId, response: e.target.checked};
                              }
                              newResp[index] = updatedQuestion;
                              return newResp;
                            });
                          }}
                        />
                        <label className="form-check-label "  >{opt.option}</label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span
                    style={{ cursor: 'danger', color: 'red' }}
                   
                  >
                    Sommething went wrong
                  </span>
                )}
              </div>
            );
          
        case 'trueFalse':

          return (
            <form>
            <div className="form-group row">
                <label class="col-form-label col-md-12 "  >{question.questionText}  (<span style={{ color:'gray'}}>{question.points}pts</span>)</label>
              <div className="form-check">
                <input type="radio" style={{"margin-right":"7px", cursor: 'pointer',}} name = "trueFalse" 
                 //checked={  responses[index] && responses[index].response && responses[index].response  === 'true' ? 'unchecked' : 'checked'}
                  onClick={(e) => {
                    setResponses((prevResponses) => {
                      const newResp =  [...prevResponses]; 
                      newResp[index] = {response: e.target.checked, type:'trueFalse', questionId: question.id , isTeacher:isTeacher};
                      return newResp;
                    });
                    console.log(responses);
                  }}
                />
                <label class="form-check-label">True</label>
              </div>
              <div className="form-check">
                <input type="radio" class="" name="trueFalse" style={{"margin-right":"7px", cursor: 'pointer',}}
               //  checked={ responses[index] && responses[index].response && responses[index].response  === 'false'}
                 onClick={(e) => {
                    setResponses((prevResponses) => {
                      const newResp =  [...prevResponses]; 
                      newResp[index] = {response: !e.target.checked, type:'trueFalse', questionId: question.id, isTeacher:isTeacher};
                      return newResp;
                    });
                    console.log(responses);
                  }}
                />
                <label class="form-check-label">False</label>
              </div>
            </div>
            </form>
          );
        default:
          return null;
      }
    }
  };

  

  return (
    <div className="main-wrapper">
      <NavBar user="Teacher" />
      <SideBar />
      <div class="page-wrapper">
        <div class="content container-fluid">
          <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                { compteARebours  ? (<h3 class="page-title">Examination finish in <span id='compte-rebours'>{compteARebours} </span></h3>):(<h3 class="page-title">Your Responses </h3>)}
                
              </div>
            </div>
          </div>

          <div class="row">
            <div className="card">
            </div>
              <form action="#">
              {questions && questions.map ? (
                <>
                  {questions.map((question, index) => (
                    question !== undefined && (
                      <div key={index} className="card card-body">
                        <h4>Question {index + 1}</h4>
                        {renderQuestionForm(question, index)}
                      </div>
                    )
                  ))}
                  {status.length  === 0 && ( <>
                  <div className="card card-body">
                    <h5 className="btn btn-info" type="button" onClick={()=>{submiting()}}>
                      Send 
                    </h5>
                  </div>
                  </>)}
                </>
              ) : (
                <>
                  <h2>No questions available for you</h2>
                </>
              )}
                
            
                <div className="card card-body">
                    {questionsR && questionsR.length > 0 && questionsR.map ? (
                      <>
                      {examResults && examResults.note && <ModalCongratulation examResults = {examResults} />}
                        {questionsR.map((question, index) => (
                          question !== undefined && question !== null && (
                            <div key={index} className="card card-body">
                              <h4>Question {index + 1}</h4>
                              {renderResponses(question, index)}
                            </div>
                          )
                        ))}
                      </>
                    ) : null}

                  <h5  style= {{color:'green'}} onClick={() => {  console.clear(); }}> {status}  back to <Link to='/MatiereTeacher' > Courses </Link></h5>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamsTeacher;
