import React, { useState, useEffect } from "react"
import Axios from "axios";
import {  useParams, useNavigate } from 'react-router-dom';

import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
const CryptoJS = require('crypto-js');




function FacUpdate() {

    const [faculty, setFac] = useState([]);
    const [FacultyName, setFacultyName] = useState('');
    const [directorEmail, setSetFacultyDirectorEmail] = useState('');
    const [FacultyCity, setFacultyCity] = useState('');
    const [FacultyAddress, setFacultyAddress] = useState('');
    const [FacultyLocation, setFacultyLocation] = useState('');
    const [FacultyCreationDate, setFacultyCreationDate] = useState('');
    const [FacultyWebSite, setFacultyWebSite ] = useState('');
    const [FacultyDescription, setFacultyDescription ] = useState('');
    const [FacultyBuget, setFacultyBudget ] = useState();

    const [AddStatus, setAddStatus] = useState('');


    const {id}  = useParams();

    let navigate = useNavigate();


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    
    const createTeacher = (e) => {
        e.preventDefault();

        const dateObject = new Date(FacultyCreationDate);

        // Obtenez les composants de la date
        const year = dateObject.getUTCFullYear();
        const month = ("0" + (dateObject.getUTCMonth() + 1)).slice(-2);
        const day = ("0" + dateObject.getUTCDate()).slice(-2);

        // Obtenez les composants de l'heure
        const hours = ("0" + dateObject.getUTCHours()).slice(-2);
        const minutes = ("0" + dateObject.getUTCMinutes()).slice(-2);
        const seconds = ("0" + dateObject.getUTCSeconds()).slice(-2);

        // Format de date et heure pour MySQL (AAAA-MM-JJ HH:MM:SS)
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;


        const data = {
            name: FacultyName,
            address: FacultyAddress,
            city: FacultyCity,
            location:FacultyLocation,
            date: formattedDate ,
            email:directorEmail,
            website:FacultyWebSite,
            description:FacultyDescription,
            id:faculty.id,
            budget:FacultyBuget,
        }
        console.log(data);
        
        Axios.post(`https://${domainName}/api/fac-add/${id}`, data).then(() => {
            setFacultyName('');
            setFacultyAddress('');
            setFacultyCity('');
            setFacultyCreationDate('');
            setSetFacultyDirectorEmail('');
            setFacultyDescription('');

            console.log("Updated sucessfuly")

            setAddStatus(`The Faculty has been Updated!`)
            navigate(`/fac-details/${id}`)
        })
    }
   
  useEffect(()=>{
    if(faculty.length===0) {
      Axios.get(`https://${domainName}/api/faculty-details/${id}`)
          .then((response) => {
            console.log(response.data);
            setFac(response.data.faculty);
          })
          .catch((error) => {
            console.error(error);
          });
    }

    if (faculty.length !== 0 && FacultyName.trim() === '') {
      const facultyDetails = faculty;
      setSetFacultyDirectorEmail(facultyDetails.deanEmail);
      setFacultyName(facultyDetails.facName);
      setFacultyCity(facultyDetails.city);
      setFacultyAddress(facultyDetails.address);
      setFacultyLocation(facultyDetails.location);
      setFacultyCreationDate(facultyDetails.creationDate);
      setFacultyWebSite(facultyDetails.website);
      setFacultyDescription(facultyDetails.description ? facultyDetails.description : '');
      setFacultyBudget(facultyDetails.budget);
    }

}, [faculty, FacultyName, id]);


  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Update Faculty </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">Faculty Informations</h5>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Faculty's Name:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Faculty Name" value={FacultyName}
                                                onChange={(event) => setFacultyName(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>


                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Faculty's Budget:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="number" class="form-control input-height" placeholder="" aria-describedby="Faculty Name" value={FacultyBuget}
                                                onChange={(event) => setFacultyBudget(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>


                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Faculty's Desciption:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Faculty Description" value={FacultyDescription}
                                                onChange={(event) => setFacultyDescription(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>
                                        

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Faculty Campus Address</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Faculty Address" value={FacultyAddress}
                                                onChange={(event) => setFacultyAddress(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Faculty Campus CitY</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Faculty City" value={FacultyCity}
                                                onChange={(event) => setFacultyCity(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Faculty Campus Location Place</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Loaction Place" value={FacultyLocation}
                                                onChange={(event) => setFacultyLocation(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Faculty Official Website</label>
                                        <div class="col-lg-10">
                                            <div class="input-group">
                                                <span class="input-group-text">https://</span>
                                                <input type="text" class="form-control "onChange={(event) => setFacultyWebSite(event.target.value)}  value={FacultyWebSite}required/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Creation's Date</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="date"  class="form-control input-height" placeholder="" aria-describedby="The of First Day Class" value={FacultyCreationDate}
                                                onChange={(event) => setFacultyCreationDate(event.target.value)}  required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Dean's Email </label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="email" class="form-control input-height" placeholder="" aria-describedby="The Full Name of the Teacher" value={directorEmail}
                                                onChange={(event) => setSetFacultyDirectorEmail(event.target.value)} required />
                                            </div>
                                         </div>
                                    </div>
                                        


                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default FacUpdate