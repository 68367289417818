import React, { swal, useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import { NavLink } from "react-router-dom";
import Axios from 'axios';

function TimeTable(){
    return (

        <div className="main-wrapper">

            <NavBar user="Teacher"/>
            
            <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
            <div class="col">
            <h3 class="page-title">Time Table</h3>
            <ul class="breadcrumb">
           
            
            </ul>
            </div>
            </div>
            </div>

            <div class="row">
            <div class="col-sm-12">
            <div class="card card-table">
            <div class="card-body">

            <div class="page-header">
            <div class="row align-items-center">
            <div class="col">
            <h3 class="page-title">Emploi de temps</h3>
            </div>
            <div class="col-auto text-end float-end ms-auto download-grp">
            <a href="#" class="btn btn-outline-primary me-2"><i class="fas fa-download"></i> Télécharger</a>           
            <NavLink to={'/TimeTeacher'}><a class="btn btn-primary"><i class="fas fa-plus"></i></a></NavLink>
            
            </div>
            </div>
            </div>

            <div class="table-responsive">
            <table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
            <thead class="student-thread">
            <tr>
            <th>ID</th>
            <th>Nom</th>
            <th>Niveau</th>
            <th>Cour</th>
            <th>Heure de debut</th>
            <th>Heure de fin</th>
            <th>Date</th>
            <th class="text-end">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>PRE2309</td>
            <td>
            <h2 class="table-avatar">
            <a class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" src="assets/img/profiles/avatar-01.jpg" alt="User Image"/></a>
            <a>Aaliyah</a>
            </h2>
            </td>
            <td>2</td>
            <td>INFO241</td>
            <td>7:00 AM</td>
            <td>10:00 PM</td>
            <td>01-11-2023</td>
            <td class="text-end">
            <div class="actions">
            <a href="javascript:;" class="btn btn-sm bg-success-light me-2">
            <i class="feather-eye"></i>
            </a>
            <NavLink to ={'/TimeUpdate'} class="btn btn-sm bg-danger-light">
            <i class="feather-edit"></i></NavLink>
           
            </div>
            </td>
            </tr>
           
            
            </tbody>
            </table>
            </div>
            </div>
            </div>
            </div>
            </div>
                    
            </div>
            
        </div>
        </div>

    )}
    export default  TimeTable;