import React, { swal, useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import Axios from 'axios';
import ENCRYPTION_KEY from '../../key'
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'

function Profile(){

    const navigateTo = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [editedInformations, SetEditInformation] = useState([]);
  const [userCode, setUserCode] = useState('');
  const [userInf, setUserInf] = useState([]);
  const [info, setInf] = useState([]);
  const [taille, setTaille] = useState(0.1);
  const [showModal, setShowModal] = useState(false);
  const [fileInput, setFileInput] = useState(null);


  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    
    // Vérifiez si un fichier a été sélectionné
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('image', selectedFile);
  
        // Envoyez le fichier vers le backend
        await Axios.post(`https://${domainName}/api/profile-update/${userCode}`, formData);
  
        // Mettez à jour l'état ou effectuez d'autres actions si nécessaire
        console.log('File uploaded successfully!');
      } catch (error) {
        // Gérez les erreurs
        console.error('Error uploading file:', error);
      }
    }
  };
  
  const handleEditButtonClick = () => {
    if (fileInput) {
      fileInput.click();
    }
  };
  

  if(userCode === ''){
    try {
        const encryptedData = sessionStorage.getItem('userInfo');
        if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            if (decryptedData) {
            const userInfo = JSON.parse(decryptedData);
            setUserCode(userInfo.code);
            console.log(userInfo);
            setInf(userInfo);
            }
        }else{
            alert("Page only permit to teachers and students");
            navigateTo('/login');
        }}catch(err){
            
        }
    }


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    useEffect(()=>{
        if (userInf.length === 0 || userInf === null || userInf  === undefined) {
            Axios.get(`https://${domainName}/api/profile-info/${userCode}`)
            .then((res)=>{
                setUserInf(res.data);
                console.log(res.data);

                const data = {
                    LoginCode: res.data.user.username,
                    LoginPassword:res.data.user.password ,//cipher1.update(loginPassword, 'utf8', 'hex') + cipher1.final('hex')//CryptoJS.AES.encrypt(JSON.stringify(loginPassword), ENCRYPTION_KEY.ENCRYPTION_KEY, { mode: CryptoJS.mode.ECB }).toString(),
                }   
                Axios.post(`https://${domainName}/api/login`,data ).then((response) => {
                    sessionStorage.removeItem('userInfo');
                    if (response.data.message  === "Credentials don't match!") {
                        alert('You need to sign in again !') 
                    }
                    else if (response.data.is_teacher === true) {
                        const userInfo = {
                           name: response.data.teacher.fullname,
                           code: response.data.teacher.code,
                           type: "teacher",
                            user:response.data.teacher
        
                        }
        
                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userInf), ENCRYPTION_KEY.ENCRYPTION_KEY).toString();
                    sessionStorage.setItem('userInfo', encryptedData);

        
                    } else if (response.data.is_student === true) {
                        
                       const  userInf = {
                            name: response.data.student.firstname + " " + response.data.student.lastname,
                            code: response.data.student.matricule,
                            type: "student",
                            user:response.data.student
                         }
                     const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userInf), ENCRYPTION_KEY.ENCRYPTION_KEY).toString();
                     sessionStorage.setItem('userInfo', encryptedData);
        
                    }else{
                        const userInf = {
                            name: response.data.user.username,
                            code: response.data.user.username,
                            type: "admin",
                            user:response.data.user
        
                         }
                     const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userInf), ENCRYPTION_KEY.ENCRYPTION_KEY).toString();
                     sessionStorage.setItem('userInfo', encryptedData);
                    }
                })
                .catch((err)=>{
                    console.log(err.message + ": You're not connected" )
                })

            })
            .catch((err)=>{
                console.log("we got an error in our code: ");
                console.log(err);
            })
        }


    const animation = setInterval(() => {
        setTaille(Math.sin(Date.now() / 1000));
      }, 100);
  
      setTimeout(() => {
        clearInterval(animation);
        setTaille(0);
      }, 3000);


    })


  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">

        <div class="content container-fluid">

<div class="page-header">
<div class="row">
<div class="col">
<h3 class="page-title">Profile</h3>
<ul class="breadcrumb">
<li class="breadcrumb-item"><a href="#">Dashboard</a></li>
</ul>
</div>
</div>
</div>

<div class="row">
<div class="col-md-12">
    {info.length !== 0  ? 
        (<>
            <div class="profile-header">
            <div class="row align-items-center">
            <div class="col-auto ">
            <a href="#">
            <img class="rounded-circle" onClick={handleImageClick}  alt="User avatar" src={`${userInf && userInf.student && userInf.student.profilePicture ? "/uploaded/" + userInf.student.profilePicture : userInf && userInf.teacher && userInf.teacher.profilePicture ? "/uploaded/" + userInf.teacher.profilePicture :"/uploaded/default-user.jpg"}`}/>
            </a>
            {/* Modal pour afficher l'image en grand */}
                <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                    <div className="modal-dialog ">
                    <div className="modal-content ">
                        <div className="modal-header">
                        <h5 className="modal-title">Profil Picture</h5>
                        <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                        <img
                            className="rounded profile-image"
                            alt="User avatar"
                            src={`${
                            userInf && userInf.student && userInf.student.profilePicture
                                ? "/uploaded/" + userInf.student.profilePicture
                                : userInf && userInf.teacher && userInf.teacher.profilePicture
                                ? userInf.teacher.profilePicture
                                : "/uploaded/default-user.jpg"
                            }`}

                            style = {{minWidth:'90%', minHeight:'95%'}}
                            
                        />
                        </div>
                        <div className="modal-footer">
                            <span className="btn blue-hover"  style={{marginRight:'70%'}} onClick={handleEditButtonClick}> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                </svg>
                            </span>
                        {/* Ajoutez ici l'icône de modification et toute autre action nécessaire */}
                            <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                Close
                            </button>
                        </div>
                        <input type="file" style={{ display: 'none' }} ref={(input) => setFileInput(input)} onChange={handleFileChange} />
                    </div>
                    </div>
                </div>
            </div>
            <div class="col ms-md-n2 profile-user-info">
            <h4 class="user-name mb-0">  {info.teacher ? info.teacher.fullname : info.student ? info.student.firstname + info.student.lastname : info.user.username}</h4>
            <h6 class="text-muted">{info.teacher ? 'teacher': info.student ? 'student': 'admin'}</h6>
            <div class="user-Location"><i class="fas fa-map-marker-alt"></i> {info.teacher ? info.teacher.ville  !== null ? info.teacher.ville : 'Undedfined' + ' , ' + info.teacher.pays !== null ? info.teacher.pays : 'Undefined' : info.student ? info.student.firstname + info.student.lastname : info.user.username}</div>
            <div class="about-text">{info.teacher && info.teacher.bio ? info.teacher.bio : info.student && info.student.bio ? info.student.bio : "No bio Defined"}</div>
            </div>
            <div class="col-auto profile-btn">
            <a href="" class="btn btn-primary">
            Edit
            </a>
            </div>
            </div>
            </div>
            <div class="profile-menu">
            <ul class="nav nav-tabs nav-tabs-solid">
            <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#per_details_tab">About</a>
            </li>
            <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#password_tab">Password</a>
            </li>
            </ul>
            </div>
            <div class="tab-content profile-tab-cont">

            <div class="tab-pane fade active show" id="per_details_tab">

            <div class="row">
            <div class="col-lg-9">
            <div class="card">
            <div class="card-body">
            <h5 class="card-title d-flex justify-content-between">
            <span>Personal Details</span>
            <a class="edit-link" data-bs-toggle="modal" href="#edit_personal_details"><i class="far fa-edit me-1"></i>Edit</a>
            </h5>
            <div class="row">
            <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">Code </p>
            <p class="col-sm-9">{info.teacher ? info.teacher.code: info.student ? info.student.matricule : info.user.username}</p>
            </div>
            <div class="row">
            <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">Full Name</p>
            <p class="col-sm-9">{info.teacher ? info.teacher.fullname: info.student ? info.student.firstname + info.student.lastname : info.user.username}</p>
            </div>
            <div class="row">
            <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">Date of Birth</p>
            <p class="col-sm-9">{info.teacher ?  info.teacher && info.teacher.dateOfBirth !== null ? info.teacher.dateOfBirth :  info.student && info.student.dateOfBirth !== null ? info.student.dateOfBirth : "No date of birth is defined" :"Your're an Admin"}</p>
            </div>
            <div class="row">
            <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">Email ID</p>
            <p class="col-sm-9"><a href="#" class="__cf_email__" data-cfemail="">{info.teacher ?  info.teacher && info.teacher.email !== null ? info.teacher.email :  info.student && info.student.email !== null ? info.student.email : "No date of birth is defined" : "Your're an Admin"}</a></p>
            </div>
            <div class="row">
            <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">Mobile</p>
            <p class="col-sm-9">{info.teacher ?  info.teacher && info.teacher.phoneNumber !== null ? info.teacher.phoneNumber :  info.student && info.student.phoneNumber !== null ? info.student.phoneNumber : "No Phone Number is defined" : "Your're an Admin"}</p>
            </div>
            <div class="row">
            <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">Address</p>
            <p class="col-sm-9">{info.teacher &&  info.teacher.address ?  info.teacher.address : info.student && info.student.address ?  info.student.address : "No address defined"}</p>
            </div>

            <div class="row">
            <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">City</p>
            <p class="col-sm-9">{info.teacher &&  info.teacher.ville ?  info.teacher.ville : info.student && info.student.ville ?  info.student.ville : "Not defined"}</p>
            </div>

            <div class="row">
            <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">Country</p>
            <p class="col-sm-9">{info.teacher &&  info.teacher.country ?  info.teacher.country : info.student && info.student.pays ?  info.student.pays : "No address defined"}</p>
            </div>
            </div>
            </div>
            </div>
            <div class="col-lg-3">

            <div class="card">
            <div class="card-body">
            <h5 class="card-title d-flex justify-content-between">
            <span>Account Status</span>

            </h5>
            <button class="btn btn-success" type="button"><i class="fe fe-check-verified"></i> Active</button>
            </div>
            </div>


            <div class="card">
            <div class="card-body">
            <h5 class="card-title d-flex justify-content-between">
            <span>Skills </span>
            <a class="edit-link" href="#"><i class="far fa-edit me-1"></i> Edit</a>
            </h5>
            <div class="skill-tags">
            <span>Html5</span>
            <span>CSS3</span>
            <span>WordPress</span>
            <span>Javascript</span>
            <span>Android</span>
            <span>iOS</span>
            <span>Angular</span>
            <span>PHP</span>
            </div>
            </div>
            </div>

            </div>
            </div>

            </div>


            <div id="password_tab" class="tab-pane fade">
            <div class="card">
            <div class="card-body">
            <h5 class="card-title">Change Password</h5>
            <div class="row">
            <div class="col-md-10 col-lg-6">
            <form>
            <div class="form-group">
            <label>Old Password</label>
            <input type="password" class="form-control"/>
            </div>
            <div class="form-group">
            <label>New Password</label>
            <input type="password" class="form-control"/>
            </div>
            <div class="form-group">
            <label>Confirm Password</label>
            <input type="password" class="form-control"/>
            </div>
            <button class="btn btn-primary" type="submit">Save Changes</button>
            </form>
            </div>
            </div>
            </div>
            </div>

            </div>

            </div></>):(<>
        <h3> The content is loading .. <br/> PLease Wait ....</h3>
    </>
    )}
            </div>
        
</div>
</div>

        </div>

    </div>
  )
}

export default Profile