import { useState } from "react";
import NavBar from "../../Components/NavBar";
import SideBar from "../../Components/SideBar";
import '../../App.css';
import axios  from "axios";
import { useParams } from "react-router-dom";
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
import { Link, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'
function EmploiDeTemp() {
  const navigateTo = useNavigate();
    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');
    const [showForm, setShowForm] = useState(false);
    const [formFields, setFormFields] = useState([[]]);
    const [message, setMessage] = useState('');
    const [userCode, setUserCode] = useState('');
    const [userInf, setUserInf] = useState([]);
  
    const updateFormField = (formIndex, fieldIndex, value) => {
    const updatedFields = [...formFields];
    updatedFields[formIndex][fieldIndex] = value;
    setFormFields(updatedFields);
  };
      if(userCode === ''){
        try {
            const encryptedData = sessionStorage.getItem('userInfo');
            if (encryptedData) {
                const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
                if (decryptedData) {
                const userInfo = JSON.parse(decryptedData);
                setUserCode(userInfo.code);
                setUserInf(userInfo);
                console.log("user" + userInfo.code)
                }
            }else{
                alert("Page only permit to teachers and students");
                navigateTo('/login');
            }}catch(err){

            }
        }
  const addForm = () => {
    setFormFields([...formFields, []]);
  };
  const decreaseForm = () => {
      setFormFields(formFields.slice(0, -1))
  }
  const handleSave = () => {
    // Récupérer les informations de tous les formulaires
    formFields.forEach((form) => {
      const [dateValue, creneauxValue, occupationValue] = form;
      axios.post(`https://${domainName}/api/save-emploie/${userCode}`,{

        Date: dateValue,
        creneaux: creneauxValue,
        occupation: occupationValue
      })
        .then((response) => {
           setMessage('Emploie de temp enreigistre avec success')
           setTimeout(() => {
            setMessage('');
          }, 3000);
        setFormFields([])
        })
        .catch((error) => {
          console.log(error);
        })
    
    });
  };

  const handleAddTemp = () => {
    setShowForm(true);
  };

  return (
    <div className="main-wrapper">
      <NavBar user="Teacher" />
      <SideBar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Ajoute votre emploi de temp</h3>
              </div>
            </div>
          </div>

          <div className="card-header card-title">
            <div className="mb-3">
            <button
                className="btn btn-primary"
                style={{ marginRight: '10px' }}
                onClick={addForm}
                type="button"
              >
                Add New
              </button>
            </div>
          </div>

          <div className= 'card-title'>
            {message && <div className="alert alert-success">{message}</div>}
            {formFields.map((form, index) => (
              <div key={index} className="form-group">
                <label className="col-form-label col-md-2">Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={form[0] || ''}
                  onChange={(e) => {
                    updateFormField(index, 0, e.target.value);
                  }}
                />

                <label className="col-form-label col-md-2">
                  Créneaux | <em>ex: 12h-13h</em>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={form[1] || ''}
                  onChange={(e) => {
                    updateFormField(index, 1, e.target.value);
                  }}
                />

                <label className="col-form-label col-md-2">Occupation</label>
                <input
                  type="text"
                  className="form-control"
                  value={form[2] || ''}
                  onChange={(e) => {
                    updateFormField(index, 2, e.target.value);
                  }}
                />

                <hr />
              </div>
            ))}

            <div className="mb-3">
             {formFields.length > 0 && (
              <div>
                 <button className="btn btn-success" onClick={handleSave} type="button"   style={{ marginRight: '10px' }}>
                 Save
               </button>
               <button className="btn btn-primary" onClick={decreaseForm} type="button">
                 Annuluer
               </button>
              </div>
             )
             
               
            
            
            }
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmploiDeTemp;