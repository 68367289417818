import React, { useState } from "react";
import Axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import ENCRYPTION_KEY from './../../src/key'
import encryptTextWithKey from './../utils'

function Login (){

    const [loginCode, setLoginCode] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [loginStatus, setLoginStatus] = useState('');
    const [step, setStep] = useState(1);
    const navigateTo = useNavigate();


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    const loginUser = (e) => {
        e.preventDefault();

        const ciphed =  encryptTextWithKey(loginPassword,ENCRYPTION_KEY.ENCRYPTION_KEY );
        console.log("ciphed: " + ciphed);

        const data = {
            LoginCode: loginCode,
            LoginPassword:ciphed ,//cipher1.update(loginPassword, 'utf8', 'hex') + cipher1.final('hex')//CryptoJS.AES.encrypt(JSON.stringify(loginPassword), ENCRYPTION_KEY.ENCRYPTION_KEY, { mode: CryptoJS.mode.ECB }).toString(),
        }   
        console.log(data, 'key: ' +  ENCRYPTION_KEY.ENCRYPTION_KEY );
        setLoginStatus('');
        Axios.post(`https://${domainName}/api/login`,data ).then((response) => {
            console.log(response.data);
            // eslint-disable-next-line eqeqeq
            if (response.data.message  =="Credentials don't match!"|| loginCode == '' || loginPassword ==  '') {
               // navigateTo('/') 
                setLoginStatus(`code or password is incorrect !`)
            }
            else if (response.data.is_teacher === true) {
                const userInf = {
                   name: response.data.teacher.fullname,
                   code: response.data.teacher.code,
                   type: "teacher",
                    user:response.data.teacher

                }

            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userInf), ENCRYPTION_KEY.ENCRYPTION_KEY).toString();
            sessionStorage.setItem('userInfo', encryptedData);
                navigateTo('/DashboadTeacher');

            } else if (response.data.is_student === true) {
                
               const  userInf = {
                    name: response.data.student.firstname + " " + response.data.student.lastname,
                    code: response.data.student.matricule,
                    type: "student",
                    user:response.data.student
                 }
             const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userInf), ENCRYPTION_KEY.ENCRYPTION_KEY).toString();
             sessionStorage.setItem('userInfo', encryptedData);
             navigateTo('/StudendDashboard');

            }else if (response.data.is_parent === true) {
                
               const  userInf = {
                    name: response.data.parent.fullname ,
                    code: response.data.parent.id,
                    type: "parent",
                    user:response.data.parent
                 }
             const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userInf), ENCRYPTION_KEY.ENCRYPTION_KEY).toString();
             sessionStorage.setItem('userInfo', encryptedData);
             navigateTo('/DashboadParent');

            }else{
                const userInf = {
                    name: response.data.user.username,
                    code: response.data.user.username,
                    type: "admin",
                    user:response.data.user

                 }
             const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userInf), ENCRYPTION_KEY.ENCRYPTION_KEY).toString();
             sessionStorage.setItem('userInfo', encryptedData);
             console.log("It's an administrator");
                navigateTo('/home')
            }
        })
        .catch((err)=>{
            setLoginStatus(err.message + ": You're not connected" )
        })
    }

    const onSubmit = () => {
        setLoginCode('')
        setLoginPassword('')
    }

    return (
        <div>

            <div className="main-wrapper login-body">
                <div className="login-wrapper">
                    <div className="container">
                        <div className="loginbox">
                            <div className="login-left">
                                <img className="img-fluid" src="assets/img/download.png" alt="Logo"  />
                            </div>
                            
                            <div className="login-right">
                                <div className="login-right-wrap">
                                <a href="/" class="logo" style={{ marginBottom: '15px' }}>
                                      <img src="/assets/img/logo.png" alt="Logo" />
                                    </a>
                                    <br />
                                    <h1>Welcome !</h1> <br />
                                    {step === 1 && (<>
                                        <h2>Sign in</h2>

                                    <form action="" onSubmit={onSubmit}>
                                        
                                        <div className="form-group">
                                            <label>ID - (Code) or Email <span className="login-danger">*</span></label>
                                            <input className="form-control" type="text" id='code' required name="email"
                                                onChange={(event) => setLoginCode(event.target.value)} />
                                            <span className="profile-views"><i className="fas fa-user-circle"></i></span>
                                        </div>

                                        <div className="form-group">
                                            <label>Mot de passe <span className="login-danger">*</span></label>
                                            <input className="form-control pass-input" type="password" id='password' name="password" required
                                                onChange={(event) => setLoginPassword(event.target.value)} />
                                            <span className="profile-views feather-eye toggle-password"></span>
                                        </div>

                                        <div className="forgotpass">
                                            <div className="remember-me">
                                                <label className="custom_check mr-2 mb-0 d-inline-flex remember-me"> Remember me
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <a href="forgot-password.html">Forgot Password?</a>
                                        </div>

                                        <p className="account-subtitle" style={{color:"red"}}>{loginStatus}</p>

                                        <div className="form-group">
                                            <button onClick={loginUser} className="btn btn-primary btn-block" type="submit">Login</button>
                                        </div>
                                         <div className=" dont-have">
                                           Other way you can <Link onClick={()=>{setStep(2)}} to="#">Register</Link>
                                        </div>


                                    </form>
                                    </>)}
                                    {step === 2 && (<>

                                    <div className="login-or">
                                        <p className="form-group" ><Link className="btn btn-primary btn-block" to="/student-preinscription">Student Registration</Link></p>
                                    </div>

                                    <div className="login-or">
                                        <span className="or-line"></span>
                                        <span className="span-or">or</span><br/>
                                        <p className="form-group" ><Link className="btn btn-primary btn-block" to="/teacher-inscription">Teacher Registration</Link></p>
                                    </div>

                                   {/*  <div className="login-or">
                                        <span className="or-line"></span>
                                        <span className="span-or">or</span><br/>
                                        <p className="form-group" ><Link className="btn btn-primary btn-block" to="/parent-form">Parent Registration</Link></p>
                                    </div> */}

                                    <div className=" dont-have">
                                          No sure ? return in  <Link onClick={()=>{setStep(1)}} to="#">Login</Link>
                                        </div>
                                    </>)}
                                    <div className="social-login">
                                        <a href="#"><i className="fab fa-google-plus-g"></i></a>
                                        <a href="#"><i className="fab fa-facebook-f"></i></a>
                                        <a href="#"><i className="fab fa-twitter"></i></a>
                                        <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Login