import React, { useState, useEffect } from "react"
import Axios from "axios";
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
import { Link,  useParams, useNavigate } from 'react-router-dom';
const CryptoJS = require('crypto-js');




function SchoolUpdate() {

  const [school, setSchool] = useState([]);
    const [schoolName, setSchoolName] = useState('');
    const [directorEmail, setSetSchoolDirectorEmail] = useState('');
    const [schoolCity, setSchoolCity] = useState('');
    const [schoolAddress, setSchoolAddress] = useState('');
    const [schoolLocation, setSchoolLocation] = useState('');
    const [schoolCreationDate, setSchoolCreationDate] = useState('');
    const [schoolWebSite, setSchoolWebSite ] = useState('');
    const [schoolDescription, setSchoolDescription ] = useState('');
    const [schoolBudget, setSchoolBudget ] = useState(0);

    const [AddStatus, setAddStatus] = useState('');


    const [userInfo, setUserInfo] = useState([])
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const navigate = useNavigate()


  const {id}  = useParams()


    const createTeacher = (e) => {
        e.preventDefault();

        
        const dateObject = new Date(schoolCreationDate);

        // Obtenez les composants de la date
        const year = dateObject.getUTCFullYear();
        const month = ("0" + (dateObject.getUTCMonth() + 1)).slice(-2);
        const day = ("0" + dateObject.getUTCDate()).slice(-2);

        // Obtenez les composants de l'heure
        const hours = ("0" + dateObject.getUTCHours()).slice(-2);
        const minutes = ("0" + dateObject.getUTCMinutes()).slice(-2);
        const seconds = ("0" + dateObject.getUTCSeconds()).slice(-2);

        // Format de date et heure pour MySQL (AAAA-MM-JJ HH:MM:SS)
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        console.log(schoolWebSite);

        const data = {
            id:id,
            name: schoolName,
            address: schoolAddress,
            city: schoolCity,
            location:schoolLocation,
            date: formattedDate ,
            email:directorEmail,
            website:schoolWebSite,
            budget:schoolBudget,
            description:schoolDescription,
        }
        
        Axios.post('http://localhost/api/school-add', data).then(() => {
            setSchoolName('');
            setSchoolAddress('');
            setSchoolCity('');
            setSchoolCreationDate('');
            setSetSchoolDirectorEmail('');
            setSchoolBudget(0);
            setSchoolDescription(0);


            setAddStatus(`The school has been updated sucessfully!`);
            navigate(`../school-details/${id}`);
        })
    }
    useEffect(()=>{
        try {
           
            if(school.length===0){
            Axios.get(`http://localhost/api/school-details/${id}`)
              .then((response) => {
                console.log(response.data);
                setSchool(response.data.school);
                console.log(school);



              })
                .catch((error) => {
                        console.error(error);
              });}


            if(!userInfo){
       const encryptedData = localStorage.getItem('userInfo');
            if (encryptedData) {
              const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
              if (decryptedData) {
                const userInfo = JSON.parse(decryptedData);
                console.log("decryptDate:", decryptedData);
                setUserInfo(userInfo);
                setIsLoggedIn(true);
                }
            }}

            if(school.length !=0 && schoolName===''){

                setSetSchoolDirectorEmail(school.directorEmail);
                setSchoolName(school.schoolName);
                setSchoolCity(school.city);
                setSchoolAddress(school.address);
                setSchoolLocation(school.location);
                setSchoolCreationDate(school.creationDate);
                setSchoolWebSite(school.website);
                setSchoolDescription(school.description?school.description:'');
                setSchoolBudget(school.budget);
            }
        }
            catch(err){
                console.log('Une erreurs\'est produite dans la recuperation des information de l\'utilisateru dans le local storage', err);
            }
      
    });

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Update school </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">School Informations</h5>
                            </div>  
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The School's Name:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="School Name" value={schoolName} 
                                                onChange={(event) => setSchoolName(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>
                                        

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">School Campus Address</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="School Address" value={schoolAddress}
                                                onChange={(event) => setSchoolAddress(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">School Campus CitY</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="School City" value={schoolCity} 
                                                onChange={(event) => setSchoolCity(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">School Campus Location Place</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Loaction Place" value={schoolLocation}
                                                onChange={(event) => setSchoolLocation(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Budget Of School </label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="number" class="form-control input-height" placeholder="" aria-describedby="Loaction Place" value={schoolBudget}
                                                onChange={(event) => setSchoolBudget(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">School Official Website</label>
                                        <div class="col-lg-10">
                                            <div class="input-group">
                                                <span class="input-group-text">https://</span>
                                                <input type="text" class="form-control" onChange={(event) => setSchoolWebSite(event.target.value)}  value={schoolWebSite} required/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">School Descrption</label>
                                        <div class="col-lg-10">
                                            <div class="input-group">
                                                <span class="input-group-text">D</span>
                                                <input type="text" class="form-control" onChange={(event) => setSchoolDescription(event.target.value)}  value={schoolDescription} required/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Creation's Date</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="date"  class="form-control input-height" placeholder="" aria-describedby="The of First Day Class" value={schoolCreationDate}  
                                                onChange={(event) => setSchoolCreationDate(event.target.value)}  required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Director's Email </label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="email" class="form-control input-height" placeholder="" aria-describedby="The Full Name of the Teacher" value={directorEmail} 
                                                onChange={(event) => setSetSchoolDirectorEmail(event.target.value)} required />
                                            </div>
                                         </div>
                                    </div>
                                        


                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default SchoolUpdate