import React, { swal, useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import Axios from 'axios';
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'

function StudentsList(){

    const navigateTo = useNavigate();
    const [students, setStudents ] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [userCode, setUserCode] = useState('');
  const [userInf, setUserInf] = useState([]);
  const [filiere, setFiliere] = useState([]);

  const {ueId} = useParams();

  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');
  
  if(userCode === ''){
    try {
        const encryptedData = sessionStorage.getItem('userInfo');
        if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            if (decryptedData) {
            const userInfo = JSON.parse(decryptedData);
            setUserCode(userInfo.code);
            setUserInf(userInfo);
            }
        }else{
            alert("Page only permit to teachers and students");
            navigateTo('/login');
        }}catch(err){
            
        }
    }



       useEffect(() => {
        if(students.length ===0 || students === undefined){
        Axios.get(`https://${domainName}/api/students-list/${ueId}`)
          .then((response) => {
            console.log(response.data);
            setStudents(response.data.students);
            setFiliere(response.data.filiere)
          })
          .catch((error) => {
            console.error(error);
          });}

      },[students]);
    return (

    <div className="main-wrapper">
        <NavBar />
        <SideBar />
        <div class="page-wrapper">
            <div class="content container-fluid">
            {students &&students.department ? (
                <>
            <div className="row">
                <div className="col-md-6">
                    <div className="invoice-info">
                        <strong className="customer-text-one">Faculty : </strong>
                        <h6 className="invoice-name">Dapartment:</h6>
                        <p className="invoice-details invoice-details-two">
                           Sector : <br /> <br />
                            Level : <br /> <br />
                            SUmber of Students :
                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="invoice-info invoice-info2">
                        <strong className="customer-text-one">{students &&students.faculty? students.faculty.facName:""}</strong>
                        <h6>{students &&students.department? students.department.name:".."}</h6>
                        <p className="invoice-details">
                        { filiere ? filiere.name:".."} <br />
                        {students && students.niveau? students.niveau.name:".."}
                        </p>
                        <div className="invoice-item-box">
                            <p> {students &&students.classmates? students.classmates.length:""}</p>
                        </div>
                    </div>
                </div>
            </div>

                <div class="row">
                     <div class="table-responsive">
                     <table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                         <thead class="student-thread">
                             <tr>
                                 <th>id</th>
                                 <th>matricule</th>
                                 <th>First && Lastname</th>
                                 <th>email</th>
                                 <th class="text-end">Action</th>
                             </tr>
                         </thead>
                         <tbody>
                         {students && students.classmates ? students.classmates.map((student, index)=>(
                            <> <tr
                             key = {index}
                             onClick={() => navigateTo("JavaScript:void(0);")}
         
                             >
                                 <td>{student.id}</td>
                                 <td>{student.matricule}</td>
                                 <td >
                                     <h2 class="table-avatar">
                                     <Link to="#"  className="avatar avatar-sm me-2" ><img src={`${student.profilePicture}`} alt="profile" className="avatar-img rounded-circle" />{student.firstname +' ' + student.lastname}</Link>
                                 </h2>
                                 </td>
                                 <td>{student.email}</td>
                                 <td class="text-end">
                                     <div class="actions">
                                     <NavLink to={`/course-list/${student.id}`}><a class="btn btn-sm bg-success-light me-2"> <i class="feather-eye"></i></a></NavLink>          
                                 
                                 </div>
                                 </td>
                             </tr>
                             </>
                             )):(
                                <>
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3>Loading courses in Progress ..</h3>
                                    </div>
                                </div>
                                </>
                            )}
                         </tbody>
                     </table>
                     </div>
                </div>
                </>
            ):(
                <>
                <div class="row">
                     <div class="table-responsive">
                     <table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                         <thead class="student-thread">
                             <tr>
                                 <th>id</th>
                                 <th>matricule</th>
                                 <th>First && Lastname</th>
                                 <th>email</th>
                                 <th>Faculty</th>
                                 <th>Department</th>
                                 <th>Secto</th>
                                 <th>Level</th>
                                 <th>Option</th>
                                 <th class="text-end">Action</th>
                             </tr>
                         </thead>
                         <tbody>
                         {students && students.length >0 ? students.map((student, index)=>(
                             <tr
                             key = {index}
                             onClick={() => navigateTo("JavaScript:void(0);")}
         
                             >
                                 <td>{student.id}</td>
                                 <td>{student.matricule}</td>
                                 <td >
                                     <h2 class="table-avatar">
                                     <Link to="#"  className="avatar avatar-sm me-2" ><img src={`${student.profilePicture}`} alt="profile" className="avatar-img rounded-circle" />{student.firstname +' ' + student.lastname}</Link>
                                 </h2>
                                 </td>
                                 <td>{student.email}</td>
                                 <td>{student.facname}</td>
                                 <td>{student.department_name}</td>
                                 <td>{student.filiere_name}</td>
                                 <td>{student.niveau_name}</td>
                                 <td>{student.option_name}</td>
                                 <td class="text-end">
                                     <div class="actions">
                                     <NavLink to={`/course-list/${student.id}`}><a class="btn btn-sm bg-success-light me-2"> <i class="feather-eye"></i></a></NavLink>          
                                 
                                 </div>
                                 </td>
                             </tr>
                             )):(
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3>Loading courses in Progress ..</h3>
                                    </div>
                                </div>
                            )}
                         </tbody>
                     </table>
                     </div>
                    
                    
                    
                </div>
                </>
            )}
                <div class="row">
                    <div class="col-md-12">
                        <div class="pagination-tab  d-flex justify-content-center">
                            <ul class="pagination mb-0">
                                <li class="page-item disabled">
                                    <a class="page-link" href="#" tabindex="-1"><i class="feather-chevron-left mr-2"></i>Previous</a>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                <li class="page-item active">
                                    <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item"><a class="page-link" href="#">4</a></li>
                                <li class="page-item">
                                    <a class="page-link" href="#">Next<i class="feather-chevron-right ml-2"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="modal fade contentmodal" id="deleteModal" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content doctor-profile">
                            <div class="modal-header pb-0 border-bottom-0 justify-content-end">
                                <button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close"><i class="feather-x-circle"></i></button>
                            </div>
                            <div class="modal-body">
                                <div class="delete-wrap text-center">
                                    <div class="del-icon"><i class="feather-x-circle"></i></div>
                                    <h2>Sure you want to delete</h2>
                                    <div class="submit-section">
                                        <a href="blog.html" class="btn btn-success me-2">Yes</a>
                                        <a href="#" class="btn btn-danger" data-bs-dismiss="modal">No</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    )}
    export default  StudentsList;