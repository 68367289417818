import React, { useState, useEffect } from "react"
import Axios from "axios";
import {  useParams, useNavigate } from 'react-router-dom';


import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
const CryptoJS = require('crypto-js');




function FacAdd() {

    const [FacultyName, setFacultyName] = useState('');
    const [directorEmail, setSetFacultyDirectorEmail] = useState('');
    const [FacultyCity, setFacultyCity] = useState('');
    const [FacultyAddress, setFacultyAddress] = useState('');
    const [FacultyLocation, setFacultyLocation] = useState('');
    const [FacultyCreationDate, setFacultyCreationDate] = useState('');
    const [FacultyWebSite, setFacultyWebSite ] = useState('')

    const [AddStatus, setAddStatus] = useState('');


    const [userInfo, setUserInfo] = useState([])
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const {schoolId}  = useParams()

    let navigate = useNavigate();

    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    const createTeacher = (e) => {
        e.preventDefault();

        


        const data = {
            name: FacultyName,
            address: FacultyAddress,
            city: FacultyCity,
            location:FacultyLocation,
            date: FacultyCreationDate ,
            email:directorEmail,
            website:FacultyWebSite,
        }
        
        Axios.post(`https://${domainName}/api/fac-add/${schoolId}`, data).then(() => {
            setFacultyName('');
            setFacultyAddress('');
            setFacultyCity('');
            setFacultyCreationDate('');
            setSetFacultyDirectorEmail('');


            setAddStatus(`The Faculty has been created!`)
            navigate(`/school-details/${schoolId}`)
        })
    }
    useEffect(()=>{
        try {
            const encryptedData = localStorage.getItem('userInfo');
            if (encryptedData) {
              const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
              if (decryptedData) {
                const userInfo = JSON.parse(decryptedData);
                console.log("decryptDate:", decryptedData);
                setUserInfo(userInfo);
                setIsLoggedIn(true);
                }
            }}catch(err){
                
            }
    });

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Add Faculty </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">Faculty Informations</h5>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Faculty's Name:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Faculty Name" value={FacultyName}
                                                onChange={(event) => setFacultyName(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>
                                        

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Faculty Campus Address</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Faculty Address" value={FacultyAddress}
                                                onChange={(event) => setFacultyAddress(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Faculty Campus CitY</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Faculty City" value={FacultyCity}
                                                onChange={(event) => setFacultyCity(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Faculty Campus Location Place</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Loaction Place" value={FacultyLocation}
                                                onChange={(event) => setFacultyLocation(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Faculty Official Website</label>
                                        <div class="col-lg-10">
                                            <div class="input-group">
                                                <span class="input-group-text">https://</span>
                                                <input type="text" class="form-control "onChange={(event) => setFacultyWebSite(event.target.value)}  value={FacultyWebSite}required/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Creation's Date</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="date"  class="form-control input-height" placeholder="" aria-describedby="The of First Day Class" value={FacultyCreationDate}
                                                onChange={(event) => setFacultyCreationDate(event.target.value)}  required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Dean's Email </label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="email" class="form-control input-height" placeholder="" aria-describedby="The Full Name of the Teacher" value={directorEmail}
                                                onChange={(event) => setSetFacultyDirectorEmail(event.target.value)} required />
                                            </div>
                                         </div>
                                    </div>
                                        


                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default FacAdd