import React, {useEffect, useState} from 'react'
import { useNavigate, NavLink, Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import ENCRYPTION_KEY from './../../src/key'
import NotificationsDropdown from './Notification';


function NavBar( {user}) {
    const navigateTo = useNavigate();
    const [userInf, setuserInf] = useState([])
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [displaySubMenu, setDisplaySubMenu1] = useState(0);
    const [op , setop] = useState(false)



   const toogleScreen = (e)=>{
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            }
    }

        function getuserInfos(){
             try {
                    const encryptedData = sessionStorage.getItem('userInfo');
                    if (encryptedData) {
                      const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
                      if (decryptedData) {
                        const userInfo = JSON.parse(decryptedData);
                        setuserInf(userInfo);
                        setIsLoggedIn(true);
                        }
                    }else{
                        navigateTo('/login');
                    }}catch(err){
                        
                    }
        }

    let loaded = false;


  const setDisplaySubMenu = (nb)=>{
    if (op === true  ){
      if (setDisplaySubMenu !== nb) {
        setDisplaySubMenu1(0);
        setop(false);
      }else{
        setDisplaySubMenu1(nb);
      }
    }else{
      setDisplaySubMenu1(nb);
      setop(true);
    }
  }

    useEffect(()=>{
          if(loaded) return;

        getuserInfos()
        loaded = true;

           }, []);
    const handleLogout = () => {
        sessionStorage.removeItem('userInfo');
        setIsLoggedIn(false);
        navigateTo('/login');

      };

    
    return(
        

        <div className="header">

            <div className="header-left">
                <a href="/" className="logo">
                    <img src="/assets/img/logo.png" alt="Logo" />
                </a>
                <a href="/" className="logo logo-small">
                    <img src="/assets/img/logo-small.png" alt="Logo" width="30" height="30" />
                </a>
            </div>

            <div className="menu-toggle">
                <a href="javascript:void(0);" id="toggle_btn">
                    <i className="fas fa-bars"></i>
                </a>
            </div>

            <div className="top-nav-search">
                <form>
                    <input type="text" className="form-control" placeholder="Search here" />
                    <button className="btn" type="submit"><i className="fas fa-search"></i></button>
                </form>
            </div>


            <a className="mobile_btn" id="mobile_btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-bars"></i>
            </a>
            <div class="dropdown-menu" >
            <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="menu-title">
                <span>Main Menu</span>
              </li>

              <li>
                <Link to={`${userInf?userInf.type? userInf.type === "student"? "/StudendDashboard": userInf.type === "teacher" ? "/DashboadTeacher" : "/" :"#":"#"}`} ><i className="fas fa-home "></i> <span>Home</span></Link>
              </li>
              <li className={`submenu ${displaySubMenu === 1 ? 'active' : ''}`} onClick = {()=>setDisplaySubMenu(1)}>
                <a href="#">
                  <i className="fas fa-graduation-cap"></i> 
                  <span> {userInf?userInf.type === "student" ? "My Class Mates": userInf.type === "teacher" ? "My Students" : "Students":"Loading .."}</span> 
                  <span className="menu-arrow"></span>
                </a>
                <ul style={{display: displaySubMenu === 1 ? 'block' : 'none'}}>
                  <li><Link to={`/students-list/${userInf?userInf.type? userInf.type === "student"? userInf.user.matricule: userInf.type === "teacher" ? userInf.user.code : "all" :"#":"#"}`}>Student List</Link></li>
                  <li  ><a href="#">Student View</a></li>
                </ul>
              </li>
                <div class="dropdown-divider"></div>
              <li className={`submenu ${displaySubMenu === 7 ? 'active' : ''}`} onClick = {()=>setDisplaySubMenu(7)}>
                <a href="#">
                  <i className="fas fa-chalkboard-teacher"></i> 
                  <span> {userInf?userInf.type === "student" ? "My Teachers": userInf.type === "teacher" ? "My Colleagues" : "Teachers ":"Loading .."}</span> 
                  <span className="menu-arrow"></span>
                </a>
                <ul style={{display: displaySubMenu === 7 ? 'block' : 'none'}}>
                  <li><Link to={`${userInf?userInf.type === "student" ? "/teacher-list/" + userInf.code : userInf.type === "teacher" ? "/teacher-list"  : "/teacher-list":"Loading .."}`}> List</Link></li>
                  {userInf && userInf.type === "admin" && (<><li><Link to="/profile"> My profile</Link></li></>)} 
                 {userInf && userInf.type === "admin" && (
                   <><li>
                      <NavLink zz to='/teacher-add'> Teacher Add </NavLink>
                  </li>
                  <li><a href="#">Add a Teacher</a></li>
                  </> 
                  )}
                </ul>
              </li>
              <div class="dropdown-divider"></div>
              <li className={`submenu ${displaySubMenu === 2 ? 'active' : ''}`} onClick = {()=>setDisplaySubMenu(2)}>
                <a href="#">
                  <i className="fas fa-building"></i> 
                  <span> Faculties</span> 
                  <span className="menu-arrow"></span>
                </a>
                <ul style={{display: displaySubMenu === 2 ? 'block' : 'none'}}>
                  <li><Link to="/school-details/1"> List Faculties</Link></li>
                 {userInf && userInf.type === "admin" && (<><li><Link to="/fac-add/1"> Add Faculty</Link></li></>)} 
                </ul>
              </li>
              {userInf && userInf !== "admin" && (<>
                <div class="dropdown-divider"></div>
              <li className={`submenu ${displaySubMenu === 8 ? 'active' : ''}`} onClick = {()=>setDisplaySubMenu(8)}>
                <a href="#">
                  <i className="fas fa-book-reader"></i> 
                  <span> Subjects</span> 
                  <span className="menu-arrow"></span>
                </a>
                <ul style={{display: displaySubMenu === 8 ? 'block' : 'none'}}>
                  <li><Link  to="/MatiereTeacher" >Subject List</Link></li>
                </ul>
              </li>
              </>)}
              <div class="dropdown-divider"></div>
              <li className={`submenu ${displaySubMenu === 9 ? 'active' : ''}`} onClick = {()=>setDisplaySubMenu(9)}>
                <a href="#">
                  <i className="fas fa-book-reader"></i> 
                  <span> Schools</span> 
                  <span className="menu-arrow"></span>
                </a>
                <ul style={{display: displaySubMenu === 9 ? 'block' : 'none'}}>
                  <li><Link  to="/school-list" > List schools</Link></li>
                  {userInf && userInf.type === "admin" && (<>
                      <li><Link  to="/school-add" > Add school</Link></li>
                      <li><Link  to="/school-details/1" disabled={false } >View first School List</Link></li>
                  </>)}
                </ul>
              </li>
                <div class="dropdown-divider"></div>
              <li className="menu-title">
                <span>Others</span>
              </li>
              <li>
                <a href="sports.html"><i className="fas fa-baseball-ball"></i> <span>Sports</span></a>
              </li>
              <li>
                <a href="Javascript:void();"><i className="fas fa-hotel"></i> <span>Hostel</span></a>
              </li>
              <li>
                <a href="transport.html"><i className="fas fa-bus"></i> <span>Transport</span></a>
              </li>
            </ul>
            </div>
            <a class="dropdown-item" href="#">Separated link</a>
            </div>


            <ul className="nav user-menu">
                <li className="nav-item dropdown noti-dropdown language-drop me-2">
                    <a href="#" className="dropdown-toggle nav-link header-nav-list" data-bs-toggle="dropdown">
                        <img src="/assets/img/icons/header-icon-01.svg" alt />
                    </a>
                    <div className="dropdown-menu ">
                        <div className="noti-content">
                            <div>
                                <a className="dropdown-item" href="javascript:;"><i className="flag flag-lr me-2"></i>English</a>
                                <a className="dropdown-item" href="javascript:;"><i className="flag flag-bl me-2"></i>Francais</a>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="nav-item zoom-screen me-2">
                <a onClick={toogleScreen} href="#" className="nav-link header-nav-list win-maximize">
                <img src="/assets/img/icons/header-icon-04.svg" alt/>
                </a>
                </li>
                <NotificationsDropdown userCode={userInf && userInf.type && userInf.type !== "admin" ? userInf.user.userId: null} />


                <li className="nav-item dropdown has-arrow new-user-menus">
                    <a href="#" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                        <span className="user-img">
                                <img src={userInf ? (userInf.type !== "admin" ?userInf.user && userInf.user.profilePicture !== null ? "/uploaded/" + userInf.user.profilePicture : "/uploaded/default-user.jpg" :  "/uploaded/default-user.jpg"): "/uploaded/default-user.jpg"} alt="User avatar" className="avatar-img rounded-circle" />
                            <div className="user-text">
                                <h6>{userInf.name}</h6>
                                <p className="text-muted mb-0">{userInf?userInf.type:"Administrator"}</p>
                            </div>
                        </span>
                    </a>

                    <div className="dropdown-menu">
                        <div className="user-header">
                            <div className="avatar avatar-sm">
                                <img src={userInf ? (userInf.type !== "admin" ?userInf.user && userInf.user.profilePicture !== null ? "/uploaded/" + userInf.user.profilePicture : "/uploaded/default-user.jpg" :  "/uploaded/default-user.jpg"): "/uploaded/default-user.jpg"} alt="User avatar" className="avatar-img rounded-circle" />
                            </div>
                            <div className="user-text">
                                <h6>{userInf.name}</h6>
                                <p className="text-muted mb-0">{userInf?userInf.type:"Administrator"}</p>
                            </div>
                        </div>
                        <Link className="dropdown-item" to="/profile">My Profile</Link>
                        <a className="dropdown-item" href="#" onClick={handleLogout}>Logout</a>
                    </div>
                </li>

            </ul>

        </div>

    )
    
}

export default NavBar