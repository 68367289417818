import React, { swal, useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import Axios from 'axios';
import ENCRYPTION_KEY from '../../key';
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import jsPDF from 'jspdf';

function ExamDetail(){

    const navigateTo = useNavigate();
    const [exam, setExam] = useState([]);
    const [examResults, setExamResults] = useState([]);
    const [hoveredIndex1, setHoveredIndex1] = useState(null);
    const [hoveredIndex2, setHoveredIndex2] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [shown, setShown] = useState(-1);
    const [search, setSearch] = useState('');
    const [userCode, setUserCode] = useState('');
    const [msg, setMsg] = useState('');
    const [resId, setResId] = useState(-1);
    const [userInf, setUserInf] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [ch, setCh] = useState(false);

    const [selectedWord, setSelectedWord] = useState('');
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [comments, setComments] = useState({}); // Stocker les commentaires


  const {examId} = useParams();
  if(userCode === ''){
    try {
        const encryptedData = sessionStorage.getItem('userInfo');
        if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            const userInfo = JSON.parse(decryptedData);
            if (decryptedData && userInfo.type === "teacher" ) {
            setUserCode(userInfo.code);
            setUserInf(userInfo);
            }
        }else{
            alert("Page only permit to teachers and students");
            navigateTo('/login');
        }}catch(err){
            
        }
    }


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

const downloadAsPdf = () => {
  const doc = new jsPDF();
  const title = 'Exam in OHKEROH: results ';

  // Générer le contenu du tableau
  const tableData = [];
  const tableHeaders = ['ID', 'Matricule', 'First Name', 'Last Name', 'Score'];

  // Ajouter les données du tableau
  examResults.map((result, index) => {
    const rowData = [
      index+1,
      result.matricule,
      result.firstname,
      result.lastname,
      result.score || result.note
    ];
    tableData.push(rowData);
  });

  // Ajouter le titre au PDF avec soulignement
  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  doc.text(title, 14, 20, { underline: true });

  // Souligner le titre
  const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const topMargin = 28; // Augmenter la valeur de la marge pour éviter le chevauchement
  doc.setLineWidth(0.5);
  doc.line(10, topMargin-4 , 10 + titleWidth, topMargin-4);

  // Ajouter le tableau au PDF avec des marges ajustées
  doc.autoTable({
    head: [tableHeaders],
    body: tableData,
    margin: { top: topMargin + 10 } // Augmenter la valeur de la marge top pour éviter le chevauchement
  });

  // Enregistrer le fichier PDF
  doc.save('exam-results.pdf');
};


    function publish_result(){
        Axios.get(`https://${domainName}/api/exam-publish/${examId}/${userInf.user.userId}`)
          .then((response) => {
            alert("The results of your exam have been published !");
            
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
    }

    function generate_result() {
        Axios.get(`https://${domainName}/api/exam-results/${examId}`)
          .then((response) => {
            setExamResults(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
    }
    function getExam(){
        Axios.get(`https://${domainName}/api/exam-details/${examId}`)
          .then((response) => {
            setExam(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
    }

       useEffect(() => {

        if (exam.length === 0 || exam === undefined)
        {
            getExam();
        }

      },[]);

      
      const renderQuestionForm = (question, index) => {
        if (question && question.type) {
          switch (question.type) {
            case 'text':
                /*function highlightText(text, searchText) {
                  // Utiliser une expression régulière pour trouver et remplacer le texte recherché
                  if (searchText && text) {
                    const regex = new RegExp(searchText, "gi");
                    return text.replace(regex, (match) => ` <span class="highlight">${match}</span> `);
                  }
                  return text;
                }*/
                function highlightText(text, searchText) {
                  if (!searchText) return text;

                  // Divise la chaîne de recherche à chaque virgule
                  const searchTerms = searchText.split(',');

                  // Crée une expression régulière pour rechercher le texte avec chaque terme séparément
                  const regex = new RegExp(`(${searchTerms.join('|')})`, 'gi');

                  // Met en surbrillance le texte correspondant à la recherche
                  return text.replace(regex, '<span class="highlight">$1</span>');
                }


              return (
                <div className="form-group card-body container-fluid" >
                  <label className="col-form-label col-md-2 ">{question.questionText}
                    <input
                      type="text"
                      className="highlight-input" placeholder="search"
                      onChange={(e)=>{
                        setSearch(e.target.value);

                      }}
                    /> </label>
                  <p className="label-multiline" dangerouslySetInnerHTML={{ __html: highlightText(questions[index].responseText, search) }}>
                </p>
                <textarea className = "form-control" type="text"  placeholder="Enter comment"  
                onBlur={(e)=>{
                setComments({...comments, [index]:{content: e.target.value, 
                                    responseId:question.responseId,
                                }});
                    }}></textarea>
                 <input type="number" placeholder="score for this response" className="form-control-sm mt-2" 
                    onBlur={(e)=>{ if(comments[index]){
                        comments[index].score = e.target.value; 
                    }else{
                            comments[index] = {score: e.target.value
                            };
                        }}}  />
                </div>
              );
            case 'multipleChoice':
              return (
                <div className="form-group row">
                  <label className="col-form-label col-md-2 border-none">{questions[index].questionText}</label>
                  {questions[index].options && questions[index].options.length > 0 ? (
                    <div>
                      {questions[index].options.map((opt, opInde) => (
                        <div className="form-check" key={opInde}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={opt.is_true}
                            disabled={true}
                          />
                          <label className="form-check-label">{opt.option}</label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span style={{ cursor: 'danger', color: 'red' }}>
                      Something went wrong
                    </span>
                  )}
                </div>
              );
            case 'trueFalse':
              return (
                <div className="form-group row">
                <label className="col-form-label col-md-2">{question.questionText}</label>
                <div className="form-check">
                  <input
                    type="radio"
                    style={{ marginRight: '7px', cursor: 'not-allowed' }}
                    name="trueFalse"
                    checked={parseInt(question.is_true, 10) === 1}
                    disabled={true}
                  />
                  <label className="form-check-label">True</label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    style={{ marginRight: '7px', cursor: 'not-allowed' }}
                    name="trueFalse"
                    checked={parseInt(question.is_true, 10) === 0}
                    disabled={true}
                  />
                  <label className="form-check-label">False</label>
                </div>
              </div>
              
              );
            default:
              return null;
          }
        }
      };
      

    return (

    <div className="main-wrapper">
        <NavBar />
        <SideBar />
        <div class="page-wrapper">
            <div class="content container-fluid">
            
                <div class="row">
                    <div class="col-md-9">
                        <ul class="list-links mb-4">
                            <li class="active"><h4 >The exam details</h4></li>
                              
                        </ul>
                    </div>
                    {userInf && userInf.type && userInf.type === 'teacher' && (
                    <div class="col-auto text-end float-end ms-auto download-grp" >
                       
                        {examResults && examResults.length> 0 && (<span onClick={(e)=>{ publish_result()}}><a class="btn btn-primary " style={{"margin-right":"10px"}}><i class="fas fas fa-cogs"></i>   Publish Results  </a></span>)}
                        <span onClick={(e)=>{ generate_result()}}><a class="btn btn-primary " style={{"margin-right":"10px"}}><i class="fas fas fa-cogs"></i>   Auto correct  </a></span>
                        <NavLink to={`/exam-show/${examId}`}><a class="btn btn-primary" title = "Give us correct  answers for this exam it must help us to supply the exam automatiquely"><i class="fas fa-duotone fa-check-double"></i>  Give us anwsers</a></NavLink>
                        <button onClick={downloadAsPdf} className="btn btn-primary" style={{"margin-left":"10px"}} ><i class="fas fa-download"></i> Download</button>
                    </div>)}
                    
                </div>

               

                {examResults && examResults.length > 0 && (
                <>
                <div className="row card">
                    <div className="card-header">
                    <h3>Students who have already taken the exam</h3>
                    </div>
                    <div className="table-responsive card-body">
                    <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                        <thead className="student-thread">
                        <tr>
                            <th>ID</th>
                            <th>Matricule</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Score</th>
                            <th className="text-end">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {examResults.map((result, index) => (
                        <>
                            <tr
                                key={index+10000000}
                                onClick={() => {
                                    setQuestions([]);
                                    setShown(index);
                                    setResId(-1);
                                    navigateTo('#collapse-' + index);
                                        Axios.get(`https://${domainName}/api/exam-responses/${examId}/${result.matricule}`)
                                        .then((res)=>{
                                            setQuestions(res.data.questions);
                                        })
                                        .catch((err)=>{
                                            console.log(err.message);
                                        });
                                }}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: hoveredIndex1 === index ? 'lightgray' : 'inherit',
                                }}
                                onMouseEnter={() => setHoveredIndex1(index)}
                                onMouseLeave={() => setHoveredIndex1(null)}
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse-${index}`}
                                aria-expanded="false"
                                aria-controls={`collapse-${index}`}
                            >
                                <td>{index + 1}</td>
                                <td>{result.matricule}</td>
                                <td>
                                    <h2 className="table-avatar">
                                    <a>{result.firstname}</a>
                                    </h2>
                                </td>
                                <td>{result.lastname}</td>
                                <td>{result.score|| result.note}  {result.pt ? `/ ${result.pt}` : result.totalNote ? result.totalNote : "pt(s)"}</td>
                                <td className="text-end">
                                    <div className="actions">
                                    <Link to="#" className="btn btn-sm bg-success-light me-2">
                                        <i className="feather-eye"></i>
                                    </Link>
                                    </div>
                                </td>
                            </tr>
                                <div
                                    key={index}
                                    className={`collapse ${index === shown ? 'show' : ''}`}
                                    id={`collapse-${index}`}
                                    aria-labelledby={`heading-${index}`}
                                    data-bs-parent="#custom-accordion-one"
                                    >
                                    <div className="card row" style={{backgroundColor:'gray'}}>
                                        <div className="card-header border-0" id={`heading-${index}`}>
                                            <h5 className="accordion-faq m-0 position-relative">
                                                <a
                                                className={`custom-accordion-title text-reset d-block ${index === shown ? '' : 'collapsed'}`}
                                                data-bs-toggle="collapse"
                                                href={`#collapse-${index}`}
                                                aria-expanded={index === hoveredIndex1}
                                                aria-controls={`collapse-${index}`}
                                                >
                                                {`Response of ${result.firstname}`}
                                                <i className="mdi mdi-chevron-down accordion-arrow"></i>
                                                </a>
                                            </h5>
                                        </div>
                                        <div className={`collapse ${index === shown ? 'show' : ''}`} id={`collapse-${index}`}>
                                            <div className="card-body row">
                                                {questions && questions.map ? (
                                                            <>
                                                                {questions.map((question, ind) => (
                                                                    question !== undefined && (
                                                                    <div key={ind + 3000} className="card card-body" disabled={true}>
                                                                        <div style={{display:'flex', flex:'wrap'}}> 
                                                                         { question.isCorrect !== false ? (
                                                                            <span><i style = {{cursor:'not-allowed', color:'green'}}  class="fa fa-check-square" aria-hidden="true"> </i> <i  style = {{cursor:'not-allowed', color:'green', marginRight:'10px'}} class="fa fa-check-square" aria-hidden="true"></i></span>
                                                                            ):(
                                                                            <span><i style = {{cursor:'not-allowed', color:'red'}}  class="fa fa-times" aria-hidden="true"> </i> <i  style = {{cursor:'not-allowed', color:'red', marginRight:'10px'}} class="fa fa-times" aria-hidden="true"></i></span>                                                                                
                                                                            )}
                                                                            <h4 disabled={true} >Question {ind + 1}  </h4> {msg &&  resId === ind && (<><h6 style={{color:msg === 'done' ? 'green' : 'tomato' }}>{msg}</h6></>)}  
                                                                            
                                                                            {question.type === 'text' ? ( <> 
                                                                            <span className='btn btn-outline-info' style={{color:'green', 'padding-right':"10px", 'marginLeft':'15px'}} onClick={async ()=>{
                                                                                try {
                                                                                    setResId(ind);
                                                                                    setMsg('');
                                                                                     await Axios.post(`https://${domainName}/api/exam-response-set`, {responseId:question.responseId, isTrue:true})
                                                                                     .then((res)=>{
                                                                                        setMsg(res.data.success ?'done': 'error');
                                                                                            const id = setTimeout(() => {
                                                                                                setMsg('')
                                                                                            }, 1000);
                                                                                            
                                                                                            // Définir une fonction de rappel pour annuler la temporisation
                                                                                            const cancel = () => {
                                                                                                clearTimeout(id);
                                                                                            };
                                                                                            
                                                                                            // Annuler la temporisation après 5 secondes
                                                                                            setTimeout(cancel, 5000);
                                                                                        
                                                                                        
                                                                                     })
                                                                                     .catch((err)=>{
                                                                                        setMsg('error');
                                                                                        console.log(err);
                                                                                        const id = setTimeout(() => {
                                                                                                setMsg('')
                                                                                            }, 1000);
                                                                                            
                                                                                            // Définir une fonction de rappel pour annuler la temporisation
                                                                                            const cancel = () => {
                                                                                                clearTimeout(id);
                                                                                            };
                                                                                            
                                                                                            // Annuler la temporisation après 5 secondes
                                                                                            setTimeout(cancel, 5000);
                                                                                     })
                                                                                    
                                                                                } catch (err) {
                                                                                    console.log(err);
                                                                                }
                                                                            }}> <i  style = {{cursor:'pointer'}} class="fa fa-check" aria-hidden="true" ></i> Validate</span> 
                                                                            <span className='btn btn-outline-warning' style={{color:'red', 'padding-right':"10px", 'paddingLeft':'15px'}} onClick={async ()=>{
                                                                                try {
                                                                                    setMsg('');
                                                                                    setResId(ind);
                                                                                    const data = {responseId:question.responseId, isTrue:false}
                                                                                    console.log(data);
                                                                                     await Axios.post(`https://${domainName}/api/exam-response-set`, data )
                                                                                     .then((res)=>{
                                                                                        console.log(res.data);
                                                                                        setMsg("success")
                                                                                        const id = setTimeout(() => {
                                                                                                setMsg('')
                                                                                            }, 1000);
                                                                                            
                                                                                            // Définir une fonction de rappel pour annuler la temporisation
                                                                                            const cancel = () => {
                                                                                                clearTimeout(id);
                                                                                            };
                                                                                            
                                                                                            // Annuler la temporisation après 5 secondes
                                                                                            setTimeout(cancel, 5000);
                                                                                     })
                                                                                     .catch((err)=>{
                                                                                        setMsg("An error occurred!");
                                                                                        console.log(err);
                                                                                        const id = setTimeout(() => {
                                                                                                setMsg('')
                                                                                            }, 1000);
                                                                                            
                                                                                            // Définir une fonction de rappel pour annuler la temporisation
                                                                                            const cancel = () => {
                                                                                                clearTimeout(id);
                                                                                            };
                                                                                            
                                                                                            // Annuler la temporisation après 5 secondes
                                                                                            setTimeout(cancel, 5000);

                                                                                     })
                                                                                    
                                                                                } catch (err) {
                                                                                    console.log(err);
                                                                                }
                                                                            }}> <i  style = {{cursor:'pointer'}} class="fa fa-times" aria-hidden="true"  ></i> Invalidate</span> 
                                                                            </>):""}
                                                                        </div>
                                                                        {renderQuestionForm(question, ind)}
                                                                    </div>
                                                                    )
                                                                ))}
                                                                { !ch && ( <>
                                                                <div className="card card-body">
                                                                {msg.length> 0  &&   (<><h4 style={{color: 'green'  }}>{msg}</h4></>)}
                                                                    <h5 className="btn btn-info" type="button" onClick={(e)=>{
                                                                        if (!result.matricule) {
                                                                            console.log(result);
                                                                            setMsg("And error occured !");
                                                                            const id = setTimeout(() => {
                                                                                setMsg('')
                                                                            }, 1000);
                                                                            
                                                                            // Définir une fonction de rappel pour annuler la temporisation
                                                                            const cancel = () => {
                                                                                clearTimeout(id);
                                                                            };
                                                                            
                                                                            // Annuler la temporisation après 5 secondes
                                                                            setTimeout(cancel, 5000);

                                                                            return
                                                                        }
                                                                        const data = {
                                                                            comments:comments,
                                                                            matricule:result.matricule,

                                                                        }
                                                                        console.log(data)
                                                                        Axios.post(`https://${domainName}/api/exam-comments-save`, data )
                                                                        .then((res)=>{
                                                                            setMsg(res.data.message);
                                                                            console.log(res);
                                                                            const id = setTimeout(() => {
                                                                                    setMsg('')
                                                                                }, 1000);
                                                                                
                                                                                // Définir une fonction de rappel pour annuler la temporisation
                                                                                const cancel = () => {
                                                                                    clearTimeout(id);
                                                                                };
                                                                                
                                                                                // Annuler la temporisation après 5 secondes
                                                                                setTimeout(cancel, 5000);
                                                                        })
                                                                        .catch((error)=>{
                                                                            setMsg(error.response.data.message);
                                                                            console.log(error);
                                                                            const id = setTimeout(() => {
                                                                                setMsg('')
                                                                            }, 1000);
                                                                            
                                                                            // Définir une fonction de rappel pour annuler la temporisation
                                                                            const cancel = () => {
                                                                                clearTimeout(id);
                                                                            };
                                                                            
                                                                            // Annuler la temporisation après 5 secondes
                                                                            setTimeout(cancel, 5000);
                                                                        })
                                                                    }}>
                                                                    Send 
                                                                    </h5>
                                                                </div>
                                                                </>)}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <h2>No questions available for you</h2>
                                                            </>
                                                        )
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>

                
                </>
            )}
                <div class="row card ">
                        <div class="card-header">
                            <h3>Students who have already taken the exam</h3>
                        </div>
                    <div class="table-responsive card-body " >
                    <table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                        <thead class="student-thread">
                            <tr>
                                <th>Matricule</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {exam.taken && exam.taken.map((student, index)=>(
                            <tr
                            key = {student.matricule}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: hoveredIndex2 === index ? 'lightgray' : 'inherit',
                            }}
                            onMouseEnter={() => setHoveredIndex2(index)}
                            onMouseLeave={() => setHoveredIndex2(null)}

                            >
                                <td>{student.matricule}</td>
                                <td >
                                    <h2 class="table-avatar">
                                    <a>{student.firstname}</a>
                                </h2>
                                </td>
                                <td>{student.lastname}</td>
                                <td class="text-end">
                                    <div class="actions">
                                    <NavLink to="#" class="btn btn-sm bg-success-light me-2"> <i class="feather-eye"></i></NavLink>          
                                
                                </div>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                <div class="row card ">
                        <div class="card-header">
                            <h3>The others  </h3>
                        </div>
                <div class="table-responsive card-body " >
                <table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                        <thead class="student-thread">
                            <tr>
                                <th>Matricule</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {exam.others && exam.others.map((student, index)=>(
                            <tr
                            key = {student.matricule}
                            onClick={() => navigateTo(`/profile-details/${student.matricule}`)}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: hoveredIndex === index ? 'lightgray' : 'inherit',
                            }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}

                            >
                                <td>{student.matricule}</td>
                                <td >
                                    <h2 class="table-avatar">
                                    <a>{student.firstname}</a>
                                </h2>
                                </td>
                                <td>{student.lastname}</td>
                                <td class="text-end">
                                    <div class="actions">
                                    <NavLink to="#" class="btn btn-sm bg-success-light me-2"> <i class="feather-eye"></i></NavLink>          
                                
                                </div>
                                </td>   
                            </tr>
                            ))}
                        </tbody>
                    </table>                   
                   </div> 
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="pagination-tab  d-flex justify-content-center">
                            <ul class="pagination mb-0">
                                <li class="page-item disabled">
                                    <a class="page-link" href="#" tabindex="-1"><i class="feather-chevron-left mr-2"></i>Previous</a>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                <li class="page-item active">
                                    <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item"><a class="page-link" href="#">4</a></li>
                                <li class="page-item">
                                    <a class="page-link" href="#">Next<i class="feather-chevron-right ml-2"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    )}
    export default  ExamDetail;