import React, { useState } from "react"
import Axios from "axios";
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import CryptoJS from 'crypto-js';


function TeacherUpdate() {

    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');
    
    const [teacherCode, setTeacherCode] = useState('');
    const [teacherNom, setTeacherNom] = useState('');
    const [teacherSexe, setTeacherSexe] = useState('');
    const [teacherDateNaissance, setTeacherDateNaissance] = useState('');
    const [teacherTelephone, setTeacherTelephone] = useState('');
    const [teacherDatePriseService, setTeacherDatePriseService] = useState('');
    const [teacherDiplome, setTeacherDiplome] = useState('');
    const [teacherSpecialite, setTeacherSpecialite] = useState('');
    const [teacherEmail, setTeacherEmail] = useState('');
    const [teacherPassword, setTeacherPassword] = useState('');
    const [teacherVille, setTeacherVille] = useState('');
    const [teacherRegion, setTeacherRegion] = useState('');
    const [teacherPays, setTeacherPays] = useState('');
    const [teacherPhoto, setTeacherPhoto] = useState('');
    const [AddStatus, setAddStatus] = useState('');


    const createTeacher = (e) => {
        e.preventDefault()
        Axios.post(`https://${domainName}/api/teacher-update`, {
            TeacherCode: teacherCode,
            TeacherNom: teacherNom,
            TeacherSexe: teacherSexe,
            TeacherDateNaissance: teacherDateNaissance,
            TeacherTelephone: teacherTelephone,
            TeacherDatePriseService: teacherDatePriseService,
            TeacherDiplome: teacherDiplome,
            TeacherSpecialite: teacherSpecialite,
            TeacherEmail: teacherEmail,
            TeacherPassword: CryptoJS.AES.encrypt(JSON.stringify(teacherPassword), ENCRYPTION_KEY.ENCRYPTION_KEY).toString(),
            TeacherVille: teacherVille,
            TeacherRegion: teacherRegion,
            TeacherPays: teacherPays,
            TeacherPhoto: teacherPhoto

        }).then(() => {
            setTeacherCode('');
            setTeacherNom('');
            setTeacherSexe('');
            setTeacherDateNaissance('');
            setTeacherTelephone('');
            setTeacherDatePriseService('');
            setTeacherDiplome('');
            setTeacherSpecialite('');
            setTeacherEmail('');
            setTeacherPassword('');
            setTeacherVille('');
            setTeacherRegion('');
            setTeacherPays('');
            setTeacherPhoto('');

            setAddStatus(`Credentials Don't Exist!`)
        })
    }


  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Add Teachers {AddStatus}</h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                        <div className="col-12">
                                            <h5 className="form-title"><span>Basic Details</span></h5>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Teacher ID <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Teacher ID" 
                                                    onChange={(event) => setTeacherCode(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Name <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Enter Name" 
                                                    onChange={(event) => setTeacherNom(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Gender <span className="login-danger">*</span></label>
                                                <select className="form-control select"
                                                    onChange={(event) => setTeacherSexe(event.target.value)}>
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                    <option>Others</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms ">
                                                <label>Date Of Birth <span className="login-danger">*</span></label>
                                                <input className="form-control " type="date" placeholder="DD-MM-YYYY" 
                                                    onChange={(event) => setTeacherDateNaissance(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Mobile <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Enter Phone" 
                                                    onChange={(event) => setTeacherTelephone(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms ">
                                                <label>Joining Date <span className="login-danger">*</span></label>
                                                <input className="form-control" type="date" placeholder="DD-MM-YYYY" 
                                                    onChange={(event) => setTeacherDatePriseService(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4 local-forms">
                                            <div className="form-group">
                                                <label>Diplome <span className="login-danger">*</span></label>
                                                <input className="form-control" type="text" placeholder="Enter Joining Date" 
                                                    onChange={(event) => setTeacherDiplome(event.target.value)} /> 
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Specialite <span className="login-danger">*</span></label>
                                                <input className="form-control" type="text" placeholder="Enter Experience"
                                                    onChange={(event) => setTeacherSpecialite(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <h5 className="form-title"><span>Login Details</span></h5>
                                        </div>


                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Email ID <span className="login-danger">*</span></label>
                                                <input type="email" className="form-control" placeholder="Enter Mail Id"
                                                    onChange={(event) => setTeacherEmail(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Password <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Enter Password"
                                                    onChange={(event) => setTeacherPassword( event.target.value )} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Profile Image  <span className="login-danger">*</span></label>
                                                <input type="pass" className="form-control" placeholder="photo"
                                                    onChange={(event) => setTeacherPhoto(event.target.value)} />
                                            </div>
                                        </div>



                                        <div className="col-12">
                                            <h5 className="form-title"><span>Address</span></h5>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>City <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Enter City"
                                                    onChange={(event) => setTeacherVille(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>State <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Enter State"
                                                    onChange={(event) => setTeacherRegion(event.target.value)} />
                                            </div>
                                        </div>

                                        
                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Country <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Enter Country"
                                                    onChange={(event) => setTeacherPays(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="btn btn-primary" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default TeacherUpdate