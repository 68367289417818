import React, { useState, useEffect } from 'react';

import NavBar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import Axios from 'axios';

function ExamsAdd() {
  const [questions, setQuestions] = useState([]);
  const [status, setStatus] = useState('') 
  const [userCode, setUserCode] = useState('');
  const [userInf, setUserInf] = useState([]);
  const [duration, setDuration] = useState('');
  const [deadline, setDeadline] = useState('');
  const [idres, setIdres] = useState();
  const navigateTo = useNavigate();
  const {ueId} = useParams();

  if(userCode === ''){
    try {
        const encryptedData = sessionStorage.getItem('userInfo');
        if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            if (decryptedData) {
            const userInfo = JSON.parse(decryptedData);
            setUserCode(userInfo.code);
            setUserInf(userInfo);
            }
        }else{
            alert("Page only permit to teachers and students");
            navigateTo('/login');
        }}catch(err){
            
        }
    }

  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');
  const addQuestion = (questionType) => {
    setQuestions((prevQuestions) => [...prevQuestions, { type: questionType }]);
  };

  const removeQuestion = (index) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions.splice(index, 1);
      return newQuestions;
    });
  };


  const renderQuestionForm = (question, index) => {
    if (question && question.type ) {
      switch (question.type) {
        case 'text':
          return (
            <div className="form-group row">
              <input type="text" class="form-control col-form-label col-md-2 border-none"  placeholder="Content of the question, click to edit."
                onBlur={(e) => {
                  
                    const updated = { ...questions[index] };
                  updated.questionText = e.target.value;
                  questions[index] =  updated;
                }}
              /> 
              <input type="number" class=" col-form-label  "  style={{maxWidth:"55px", padding:'0px', margin:"0px", 'margin-right': "1px", position:'relative', height:'35px'}} placeholder='1pt' 
                onBlur={(e) => {
                  
                    const updated = { ...questions[index] };
                  updated.point = e.target.value;
                  questions[index] =  updated;
                }}
              /> 
              <input className = "form-control" type="text" disabled={true} />
            </div>
          );
          case 'multipleChoice':
            return (
              <div className="form-group row">
                <input
                  type="text"
                  className="form-control col-form-label col-md-2 border-none"
                  placeholder="Content of the question, click to edit."
                  onBlur={(e) => {
                    const updated = { ...questions[index] };
                    updated.questionText = e.target.value;
                    setQuestions((prevQuestions) => {
                      const newQuestions = [...prevQuestions];
                      newQuestions[index] = updated;
                      return newQuestions;
                    });
                  }}
                />
                <input type="number" class=" col-form-label  "  style={{maxWidth:"55px", padding:'0px', margin:"10px", 'margin-right': "1px", position:'relative', height:'35px'}} placeholder='1pt' 
                onBlur={(e) => {
                  
                    const updated = { ...questions[index] };
                  updated.point = e.target.value;
                  questions[index] =  updated;
                }}
              /> 
                {questions[index].options && questions[index].options.length > 0 ? (
                  <div>
                    {questions[index].options.map((opt, opInde) => (
                      <div className="form-check" key={opInde}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          disabled={true}
                        />
                        <input
                          type="text"
                          className="form-check-label border-none"
                          placeholder="Option 1"
                          onBlur={(e) => {
                            const updated = { ...questions[index] };
                            updated.options[opInde] = e.target.value;
                            setQuestions((prevQuestions) => {
                              const newQuestions = [...prevQuestions];
                              newQuestions[index] = updated;
                              return newQuestions;
                            });
                          }}
                        />
                      </div>
                    ))}
                    <span
                      style={{ cursor: 'pointer', color: 'blue' }}
                      onClick={() => {
                        setQuestions((prevQuestions) => {
                          const newQuestions = [...prevQuestions];
                          const updatedQuestion = { ...newQuestions[index] };
                        updatedQuestion.options = [ ...updatedQuestion.options,  'Option text'];
                        newQuestions[index] = updatedQuestion;
                          return newQuestions;
                        });
                      }}
                    >
                      Add an option
                    </span>
                  </div>
                ) : (
                  <span
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={() => {
                      setQuestions((prevQuestions) => {
                        const newQuestions = [...prevQuestions];
                        const updatedQuestion = { ...newQuestions[index] };
                        updatedQuestion.options = ['Option text'];
                        newQuestions[index] = updatedQuestion;
                        return newQuestions;
                      });
                    }}
                  >
                    Add an option
                  </span>
                )}
              </div>
            );
          
        case 'trueFalse':

          return (
            <div className="form-group row">
              <input type="text" class="form-control col-form-label col-md-2 border-none"   placeholder="Content of the question, click to edit."
                onBlur={(e) => {
                  const updated = { ...questions[index] };
                  updated.questionText = e.target.value;
                  console.log("question[index] before: ", questions[index]);
                      questions[index] =  updated;
                  console.log("question[index] after: ", questions[index]);
                }}
              />
              <input type="number" class=" col-form-label  "  style={{maxWidth:"55px", padding:'0px', margin:"0px", 'margin-right': "1px", position:'relative', height:'35px'}} placeholder='1pt' 
                onBlur={(e) => {
                  
                    const updated = { ...questions[index] };
                  updated.point = e.target.value;
                  questions[index] =  updated;
                }}
              /> 
              <div className="form-check">
                <input type="radio" class="form-check-input" name="trueFalse" disabled={true}/>
                <label class="form-check-label">True</label>
              </div>
              <div className="form-check">
                <input type="radio" class="form-check-input" name="trueFalse" disabled={true} />
                <label class="form-check-label">False</label>
              </div>
            </div>
          );
        default:
          return null;
      }
    }
  };

  

  return (
    <div className="main-wrapper">
      <NavBar user="Teacher" />
      <SideBar />
      <div class="page-wrapper">
        <div class="content container-fluid">
          <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">Ajout d'un Examen</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item active">Ajouter un Examen</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row">
            <div className="card">
            <div class="card-title">
              <h3>{status}</h3>
              <div className="mb-3">
                <button className="btn btn-primary " type="button" onClick={() => addQuestion('text')}>
                  Add Text Question
                </button>
                <button
                  className="btn btn-primary" type="button" style={{"margin-left":"12px", "margin-top":"5px","margin-bottom":"5px",  "margin-right":"13px"}}
                  onClick={() => addQuestion('multipleChoice')}
                >
                  Add Multiple Choice Question
                </button>
                <button
                  className="btn btn-primary" type="button"
                  onClick={() => addQuestion('trueFalse')}
                >
                  Add True/False Question
                </button>
              </div>
            </div>
            <div className=" card-title">
              <div className="form-group">
                <label className="col-form-label col-md-2"> Deadline date Of Examen</label>
                <input type="datetime-local" className="form-control" value={deadline} onChange={(e)=>{setDeadline(e.target.value)}}/>
              </div>
              <div className="form-group">
                <label className="col-form-label col-md-2"> Duration Of Examen <h6>(min)</h6></label>
                <input type="number" className="form-control" value={duration} onChange={(e)=>{setDuration(e.target.value)}}/>
              </div>
            </div>
            </div>
              <form action="#">
                {questions && questions.map && questions.map((question, index) => ( question !== undefined && (
                  <>
                  <div key={index} class="card card-body">
                    <h4>Question {index + 1 }</h4>
                    {renderQuestionForm(question, index)}
                   
                          <h5
                            className=" btn btn-danger"
                            onClick={() => {
                              const u_questions = []
                              for (let i = 0; i < questions.length; i++) {
                                if (i !== index) {
                                  u_questions[i] = questions[i];
                                }
                              }
                              setQuestions(u_questions);
                            }}
                          >
                            remove
                          </h5>

                          <div class="card-header card-title">
                          <div className="mb-3">
                            <button className="btn btn-primary  " onClick={() => addQuestion('text')} type="button" >
                              Add Text Question
                            </button>
                            <button type="button"
                              className="btn btn-primary" style={{"margin-left":"12px", "margin-top":"5px","margin-bottom":"5px",  "margin-right":"13px"}}
                              onClick={() => addQuestion('multipleChoice')}
                            >
                              Add Multiple Choice Question
                            </button>
                            <button type="button"
                              className="btn btn-primary"
                              onClick={() => addQuestion('trueFalse')}
                            >
                              Add True/False Question
                            </button>
                          </div>
                          </div>
                  </div>
                  </>
                )))}
                <div className="card card-body">
                <h5
                            className=" btn btn-info" type="button"
                            onClick={() => {
                              console.clear();
                              console.log(questions);
                              const u_questions = []
                              for (let i = 0; i < questions.length; i++) {
                                if (questions[i] !== undefined) {
                                  u_questions[i] = questions[i];
                                }
                              }
                              if (u_questions.length !== 0 || deadline !== '' || duration !== 0) {
                                Axios.post(`https://${domainName}/api/exam-add/${ueId}`, {questions:u_questions, exam:{deadline:deadline, duration:duration, teacherCode:userInf.user.code}})
                                .then((res)=>{
                                  console.log(res.data);
                                  setStatus(res.data.message)
                                  setIdres(res.data.id)
                                })
                                .catch((err)=>{
                                  console.log(err);
                                  setStatus(`${err.name}: ${err.message}`);
                                  if (err.code === "ERR_BAD_RESPONSE") {
                                    alert("Make sure you fill all question's text and option's Texr");
                                  }
                                })
                              } else {
                                alert("Fill  duration and deadline date please")
                              }
                            }}
                          >
                            Save
                          </h5>
                          {status ===  "Examen ajouté avec succès." && (
                          <h5
                            className=" btn btn-success" type="button"
                            onClick={() => {
                              console.clear();
                              navigateTo(`/exam-show/${idres}`)
                            }}
                          >
                            View the Exam
                          </h5>)}
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamsAdd;
