import React, { swal, useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
import {  useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import Axios from 'axios';

function DevoirsList(){

    const [devoirs, setDevoirs] = useState([]);
    const [userInf, setUserInf] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [userCode, setUserCode] = useState('');
    const [userId , setUserId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');
    const [description, setSetDescription] = useState('');
    const [content, setContent] = useState('');
    const [deadline, setDeadline] = useState('');

    const navigateTo = useNavigate();

    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');
    const numberOfstudent = localStorage.getItem("countStudent")
    let  decryptNumberOfStudents = null;
    if (numberOfstudent) {
        
     decryptNumberOfStudents = CryptoJS.AES.decrypt(numberOfstudent, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
}


    if(userId === null){
        try {
            const encryptedData = sessionStorage.getItem('userInfo');
            if (encryptedData) {
                const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
                if (decryptedData) {
                const userInfo = JSON.parse(decryptedData);                           
                    setUserCode(userInfo.code);
                    setUserInf(userInfo);    
                    setUserId(userInfo.user.userId);
            }}else{
                alert("Your're not allow to get this page !");
                navigateTo('/login');
            }}
        catch(err){
            console.log(err)
            }
     
        }

        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          };


   function fetchedHomeworks() {
    Axios.get(`https://${domainName}/api/devoirs-get/${userId}`)
          .then((response) => {
            setDevoirs(response.data);
          })
          .catch((error) => {
            console.error(error);
            setMessage(error.message);
            const id = setTimeout(() => {
                setMessage('');
              }, 4000);
      
              const cancel = () => {
                clearTimeout(id);
              };
      
              setTimeout(cancel, 5000);
          });
   }

   const saveDevoir = async () => {
    // Vérifiez si un fichier a été sélectionné
    if (content.trim() !== '' && description.trim()!==0 && deadline.trim() !== '') {
      try {
  
        // Envoyez le fichier vers le backend
        await Axios.post(`https://${domainName}/api/devoir-save`, {description:content , created_by: userInf.user.userId, deadline:deadline, classId:userInf.user.classId})
        .then((res)=>{

            setShowModal(false);
            setMessage(res.data.message);
            fetchedHomeworks();
            const id = setTimeout(() => {
                setMessage('');
                setContent('');
                setDeadline('');
              }, 4000);
      
              const cancel = () => {
                clearTimeout(id);
              };
      
              setTimeout(cancel, 5000);
        })
        .catch((err)=>{
            console.log(err);
            setMessage(err.message);
            const id = setTimeout(() => {
                setMessage('');
              }, 4000);
      
              const cancel = () => {
                clearTimeout(id);
              };
      
              setTimeout(cancel, 5000);
        })
  
      } catch (error) {
        // Gérez les erreurs
        console.error('Error uploading file:', error);
      }
    }
  };
  

       useEffect(() => {
        fetchedHomeworks();

      },[]);
      
    return (

        <div className="main-wrapper">

            <NavBar user="Student"/>
            
            <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
            <div class="col">
            <h3 class="page-title">Homeworks List for your class {devoirs && devoirs.length && (<span style={{color:"mediumvioletred"}}> : {devoirs.length}</span>)}</h3>
            <ul class="breadcrumb">
        
            </ul>
            </div>
            </div>
            </div>

            <div class="row">
            <div class="col-sm-12">
            <div class="card card-table">
            <div class="card-body">

            <div class="page-header">
            <div class="row align-items-center">
            
            { userInf && userInf.type && (userInf.type === "teacher" ) && 
                <div class="col-auto text-end float-end ms-auto download-grp" title="start a new discussion thematic.">                      
                    <a href="#" data-bs-toggle ='modal' data-bs-target='#create' class="btn btn-primary" onClick={()=>{setShowModal(true)}}>New Homework</a>
                </div>
            }
            </div>
            </div>
                {message && <h3 style={{color:"mediumvioletred"}}>{message}  </h3>}
            <div class="table-responsive">
            <table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                <thead class="student-thread">
                    <tr>
                        <th>ID</th>
                        <th>Author</th>
                        <th>Deadline</th>
                        <th>Responses</th>
                        <th>Content</th>
                        <th class="text-end">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {devoirs.map((mat, index)=>(
               <tr
                    key = {index}
                    onClick={() => navigateTo(`/homeworks/${mat.id}`)}
                    style={{
                        cursor: 'pointer',
                        backgroundColor: hoveredIndex === index ? 'lightgray' : 'inherit',
                      }}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}

                    >
                        <td>{ mat.id }</td>
                        <td>{ mat.author    }</td>
                        <td>{new Date(mat.deadline).toLocaleDateString("en-FR", options) }</td>
                        <td>{mat.responses }</td>
                        <td>{mat.description.substring(0, 30) }</td>
                        <td class="text-end">
                            <div class="actions">
                            <NavLink to={`/course-list/${mat.id}`} class="btn btn-sm bg-success-light me-2"> <i class="feather-eye"></i></NavLink>          
                        
                        </div>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>
            </div>
            </div>
            </div>
            </div>
            </div>
                    
            </div>



            <div id="create" class="modal fade" tabindex="-1" style={{"display": "none"}} aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                        <div class="modal-body">
                                            <div class="text-center mt-2 mb-4">
                                            <div class="auth-logo">
                                                <a href="#" class="logo logo-dark">
                                                <span class="logo-lg">
                                                    <img src="assets/img/logo.png" alt="" height="42"/>
                                                </span>
                                                </a>
                                            </div>
                                            </div>
                                            <div class="card">
                                                <div class="card-header">
                                                <h5 class="card-title">Homework Informations</h5>
                                                </div>
                                                <div class="card-body">

                                                    <div className=" card-title">
                                                        <div className="form-group">
                                                            <label className="col-form-label col-md-8"> Deadline date Of Examen</label>
                                                            <input type="datetime-local" className="form-control" value={deadline} onChange={(e)=>{setDeadline(e.target.value)}}/>
                                                        </div>
                                                    </div>
                                                    <div className=" card-title">
                                                    <div className="form-group">
                                                        <label className="col-form-label col-md-8"> Content/Description</label>
                                                        <textarea  onKeyDown={(e)=>{if(e.key === "Enter" && !e.ctrlKey && !e.shiftKey && !e.altKey){saveDevoir()}}}className="form-control" value={content} onChange={(e)=>{setContent(e.target.value)}}></textarea>

                                                    </div>
                                                </div>
                                                </div>
                                                {message && <h3 style={{color:"mediumvioletred"}}>{message}  </h3>}
                                            <div className="modal-footer">
                                                <span className="btn btn-info "  style={{marginRight:'70%'  }} onClick={saveDevoir}> 
                                                    Save 
                                                </span>
                                            </div>
                                                </div>
                                                </div>
                                        
                                        
                                        </div>
                                        </div>
                                    </div>
                                    </div>



            
        </div>

    )}
    export default  DevoirsList;