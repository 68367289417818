
import React, { useState, useEffect } from 'react';
import NavBar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import Axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';

function StudyUnitDetails() {
  const [teacherToAdd, setTeacherToAdd] = useState('');
  const [role, setRole] = useState('');
  const [teachers, setTeachers] = useState([]);
  const [UE, setUE] = useState([]);
  const {id}  = useParams()

  const navigate = useNavigate();


  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');

  const fetchTeachers = (e)=>
  {
  if(teachers.length === 0 && UE.length !==0 )
      {
        Axios.get(`https://${domainName}/api/fac-teachers/${UE.studyUnit.id}`)
        .then((response) => {
          setTeachers(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
      }
  }



  const addTeacher = (e) =>
  {
    e.preventDefault();
    const data = {
      teacherCode: teacherToAdd?teacherToAdd:"erro",
      ueId:UE.studyUnit.id,
      role: role,
    }
    console.log("data: ", data)
    Axios.post(`https://${domainName}/api/instructor-add/`, data)
      .then((response) => {
        document.querySelector("#top-justified-tab2").click();
        navigate('#top-justified-tab2')
      })
      .catch((error) => {
        console.error(error);
      });

  }

  useEffect(() => {
    Axios.get(`https://${domainName}/api/study-unit-details/${id}`)
      .then((response) => {
        setUE(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

      

  },[id]);
  

  return (
    <div className="main-wrapper">
      <NavBar />
      <SideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="invoice-info">
                <strong className="customer-text-one">{UE.studyUnit ? UE.studyUnit.name : "EU"}</strong>
                <h6 className="invoice-name">Professor Name:</h6>
                <h6 className="invoice-name">Credit:</h6>
                <p className="invoice-details invoice-details-two">
                  Lab Number: <br /> <br />
                  Material(s) Required: <br /> <br />
                  Description:
                </p>

              </div>
            </div>
            <div className="col-md-6">
              <div className="invoice-info invoice-info2">
                <strong className="customer-text-one">{UE.studyUnit ? UE.studyUnit.code : "Code"}</strong>
                <p className="invoice-details">
                  {UE.teachers ? UE.teachers[0] ? UE.teachers[0].name : "No Professor Assigned" : "Une Erreur s'est produite"} <br />
                  {UE.studyUnit ? UE.studyUnit.credit : "EU"} <br />
                  {UE.studyUnit ? UE.studyUnit.hasLab : "EU"}
                </p>
                <div className="invoice-item-box">
                  <p> {UE.studyUnit ? UE.studyUnit.hasLab : "EU"}</p><br />
                  <p className="mb-0"> {UE.studyUnit ? UE.studyUnit.description : "EU"}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="card invoices-tabs-card border-0">
              <div class="card-body card-body pt-0 pb-0">
                <div class="invoices-main-tabs">
                  <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">

                    </div>
                    <div class="col-lg-4 col-md-4">
                      <div class="invoices-settings-btn">
                        <a href="#" class="invoices-settings-icon">
                          <i class="feather feather-settings"></i>
                        </a>
                        <Link to="#signup-modal" class="btn" data-bs-toggle="modal" data-bs-target="#signup-modal">
                          <i class="feather feather-plus-circle"></i> New Teacher
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <ul class="nav nav-tabs nav-tabs-top nav-justified">
              <li class="nav-item"><a class="nav-link active" href="#top-justified-tab1" data-bs-toggle="tab">course Content</a></li>
              <li class="nav-item"><a class="nav-link" href="#top-justified-tab2" onClick={(e)=>{fetchTeachers(e)}}data-bs-toggle="tab">Teacher List</a></li>
              <li class="nav-item"><a class="nav-link" href="#top-justified-tab3" data-bs-toggle="tab">Students List</a></li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane show active" id="top-justified-tab1">
                Tab content 1
              </div>
              <div class="tab-pane" id="top-justified-tab2">


                <div class="col-xl-12 d-flex">

                  <div class="card flex-fill student-space comman-shadow">
                    <div class="card-header d-flex align-items-center">
                      <h5 class="card-title">Teacher of this UE</h5>

                    </div>
                    <div id="signup-modal" class="modal fade" tabindex="-1" style={{"display": "none"}} aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-body">
                            <div class="text-center mt-2 mb-4">
                              <div class="auth-logo">
                                <a href="#" class="logo logo-dark">
                                  <span class="logo-lg">
                                    <img src="/assets/img/logo.png" alt="" height="42"/>
                                  </span>
                                </a>
                              </div>
                            </div>
                            <form class="px-3" action="#">
                              <div class="card-body">
                                <div class="card-header d-flex align-items-center">
                                  <h5 class="card-title">Choose One</h5>
                                </div>
                                <div class="table-responsive form-group">
                                  <table class="table star-student table-hover table-center table-borderless table-striped">
                                    <thead class="thead-light">
                                      <tr>
                                        <th>Code</th>
                                        <th>Name</th>
                                        <th class="text-center">Specialite</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {teachers ? teachers.map((teacher) => (
                                        <tr key={teacher.code}> {/* Move input inside tr and add key prop to tr */}
                                          <td className="text-nowrap">
                                            <div>
                                              <input id={`customCheck_${teacher.code}`} onChange={(e)=>{setTeacherToAdd(teacher.code)}} type="radio" name="radio" value={teacher.code} className="form-check-input" />
                                              <label htmlFor={`customCheck_${teacher.code}`} className="form-check-label">{teacher.code}</label>
                                            </div>
                                          </td>
                                          <td className="text-nowrap">
                                            <a href="profile.html">
                                              <img className="rounded-circle" src="/uploaded/default-user.jpg" width="25" alt="Star Students" />
                                              {teacher.fullname}
                                            </a>
                                          </td>
                                          <td className="text-center">{teacher.specialization}</td>
                                        </tr>
                                      )) : (
                                        <tr>
                                          <td colSpan="3" className="text-center">
                                            This Course has no Teacher yet,{' '}
                                            <Link to={UE.unitStudy ? `/study-unit-add/${UE.unitStudy}` : '#'} data-bs-target="#signup-modal" data-bs-toggle="modal">
                                              Add one
                                            </Link>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <div class="mb-3">
                                <label for="role" class="form-label">Role</label>
                                <input class="form-control" value={role} onChange = {(e)=>{setRole(e.target.value)}} type="text" id="role" required="" placeholder="" />
                              </div>
                              <div class="mb-3 text-center">
                                <button  class="btn btn-primary" type="submit" onClick={(e)=>{addTeacher(e)}}>Add </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table star-student table-hover table-center table-borderless table-striped">
                          <thead class="thead-light">
                            <tr>
                              <th>Code</th>
                              <th>Name</th>
                              <th class="text-center">Role</th>
                            </tr>
                          </thead>
                          <tbody>
                            {UE.teachers ? UE.teachers.map((teacher) => (
                              <tr>
                                <td class="text-nowrap">
                                  <div>{teacher[0].code}</div>
                                </td>
                                <td class="text-nowrap">
                                  <a href="#">
                                    <img class="rounded-circle" src="/uploaded/default-user.jpg" width="25" alt="Star Students" />
                                    {teacher[0].fullname}
                                  </a>
                                </td>
                                <td class="text-center">{teacher.role}</td>
                              </tr>
                            )) : <>
                              This Course has no Teacher yet, <Link to={UE.unitStudy ? `/study-unit-add/${UE.unitStudy}` : '#'} data-bs-target="#signup-modal" data-bs-toggle="modal">Add one</Link>
                            </>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <div class="tab-pane" id="top-justified-tab3">
                Tab content 3
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default StudyUnitDetails;