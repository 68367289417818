import React, { useState, useEffect } from "react"
import Axios from "axios";
import {  useParams, useNavigate } from 'react-router-dom';


import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
const CryptoJS = require('crypto-js');



function StudyUnitAdd() {

    const [StudyUnitName, setStudyUnitName] = useState('');
    const [StudyUnitmaterialsRequired, setStudyUnitmaterialsRequired] = useState('');
    const [StudyUnitCode, setStudyUnitCode] = useState('');
    const [StudyUnitCredit, setStudyUnitCredit] = useState();
    const [StudyUnitHasLab, setStudyUnitHasLab] = useState(false);
    const [StudyUnitHoursPerWeek, setStudyUnitHoursPerWeek] = useState('');
    const [StudyUnitDescription, setStudyUnitDescripton] = useState('');

    const [AddStatus, setAddStatus] = useState('');


    const [userInfo, setUserInfo] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const {niveauId, optionId}  = useParams();
    let navigate = useNavigate();

    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    const createTeacher = (e) => {
        e.preventDefault();

        


        const data = {
            name: StudyUnitName,
            code: StudyUnitCode,
            credit: StudyUnitCredit,
            hasLab:StudyUnitHasLab,
            materialsRequired: StudyUnitmaterialsRequired,
            hoursPerWeek: StudyUnitHoursPerWeek,
            description:StudyUnitDescription,
        }
        console.log(data)
        Axios.post(`https://${domainName}/api/study-unit-add/${niveauId}?optionId=${optionId?optionId:''}`, data).then(() => {
            setStudyUnitName('');
            setStudyUnitDescripton('');
            setStudyUnitCode("");
            setStudyUnitCredit(0)
            setStudyUnitHoursPerWeek(0)
            setStudyUnitmaterialsRequired('')


            setAddStatus(`The StudyUnit has been created!`)
            if (optionId === null){
            navigate(`/option-details/${optionId}`)
            }else{
            navigate(`/niveau-details/${niveauId}`)
            }
        })
    }
    useEffect(()=>{
        try {
            const encryptedData = localStorage.getItem('userInfo');
            if (encryptedData) {
              const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
              if (decryptedData) {
                const userInfo = JSON.parse(decryptedData);
                console.log("decryptDate:", decryptedData);
                setUserInfo(userInfo);
                setIsLoggedIn(true);
                }
            }}catch(err){
                
            }
    });

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Add Study Unit </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">Study Unit Informations</h5>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Study Unit's Name:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="StudyUnit Name" value={StudyUnitName}
                                                onChange={(event) => setStudyUnitName(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Study Unit's Code:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="INF1024" aria-describedby="StudyUnit Name" value={StudyUnitCode}
                                                onChange={(event) => setStudyUnitCode(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Study Unit Credit:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="number" class="form-control input-height" placeholder="" aria-describedby="StudyUnit Name" value={StudyUnitCredit}
                                                onChange={(event) => setStudyUnitCredit(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">This Study Unit has Labs ?</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <input type="checkbox" class="form-control input-height" placeholder="" aria-describedby="StudyUnit Name" value={StudyUnitHasLab}
                                                onChange={(event) => setStudyUnitHasLab(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Hours Per Week:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="number" class="form-control input-height" placeholder="" aria-describedby="StudyUnit Name" value={StudyUnitHoursPerWeek}
                                                onChange={(event) => setStudyUnitHoursPerWeek(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>
                                        

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">StudyUnit Description</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="StudyUnit Address" value={StudyUnitDescription}
                                                onChange={(event) => setStudyUnitDescripton(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Material Required: </label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">M</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="StudyUnit Address" value={StudyUnitmaterialsRequired}
                                                onChange={(event) => setStudyUnitmaterialsRequired(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    

                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default StudyUnitAdd