import React, { useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import Axios from 'axios'
import ENCRYPTION_KEY from '../../key';
import CryptoJS from 'crypto-js'
import {  useNavigate, } from 'react-router-dom';
import CardComponent from './parent-card-info';

function ParentInfo() {


  const [userInf, setUserInf] = useState([]);
  const [userCode, setUserCode] = useState('');

    const navigateTo = useNavigate();


     function getUserInf(){
         if(userCode === ''){
        try {
            const encryptedData = sessionStorage.getItem('userInfo');
            if (encryptedData) {
              const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
              if (decryptedData) {
                const userInfo = JSON.parse(decryptedData);                           
                    setUserCode(userInfo.code);
                    setUserInf(userInfo);
                    console.log(userInfo);
            }}else{
                alert("Your're not allow to get this page !");
                navigateTo('/login');
            }}
            catch(err){
                console.log(err)
            }
        }
    }


useEffect(()=>{
  if (userCode==='') {
    getUserInf()
  }
},[userInf])
;

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">

            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Your Dashboard</h3>
                        </div>
                    </div>
                </div>

                <CardComponent userId = {userInf && userInf.user && userInf.user.userId ? userInf.user.userId:null}/>

                

            </div>

        </div>

    </div>
  )
}

export default ParentInfo;