
import React , {useEffect, useState} from "react";
import NavBar from "../../Components/NavBar";
import SideBar from "../../Components/SideBar";
import ENCRYPTION_KEY from '../../key';
import encryptTextWithKey from './../../utils';
import Themes from './../Student/discussion-themes';
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import Axios from 'axios'
function Hometeacher(){
    const [ue, setUe] = useState([]);
    const [userCode, setUserCode] = useState('');
    const [status, setStatus] = useState('');
    const [description, setSetDescription] = useState('');
    const [theme, setTheme] = useState('');
    const [themes, setThemes] = useState([]);
    const [teacher, setTeacher] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(false);
    const [matiers , setMatieres] = useState([]);
    const [userId , setUserId] = useState(-1);
    const [hovered , setHovered] = useState(-1);
    const [shownTheme , setShownedTheme] = useState(1);
    const navigateTo = useNavigate();
    const [students , setStudents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(0);


      const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const handleEditButtonClick = async (e) => {
    
    // Vérifiez si un fichier a été sélectionné
    if (theme.trim() !== '' && description.trim()!==0) {
      try {
  
        // Envoyez le fichier vers le backend
        await Axios.post(`https://${domainName}/api/theme-save`, {title:theme, description:description , created_by: teacher.user.userId})
        .then((res)=>{

            setShowModal(false);
            setStatus(res.data.message);
        })
        .catch((err)=>{
            console.log(err);
            setStatus(err.message)
        })
  
      } catch (error) {
        // Gérez les erreurs
        console.error('Error uploading file:', error);
      }
    }
  };
  

    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');
    const {ueId} = useParams();
    const florData = CryptoJS.AES.encrypt(JSON.stringify(students.length), ENCRYPTION_KEY.ENCRYPTION_KEY).toString();


     function getUserInf(){
         if(userCode === ''){
        try {
                    const encryptedData = sessionStorage.getItem('userInfo');
                    if (encryptedData) {
                      const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
                      if (decryptedData) {
                        const userInfo = JSON.parse(decryptedData);                           
                            setUserCode(userInfo.code);
                            setTeacher(userInfo);
                            setUserId(userInfo.user.userId)
                    }}else{
                        alert("Your're not allow to get this page !");
                        navigateTo('/login');
                    }}
            catch(err){
                    console.log(err)
                    }
        }
    }
       
        
            useEffect(() => {
                    getUserInf();
                if(students.length === 0 && loading < 25 || students === undefined ){
                   setLoading(loading +1);
                Axios.get(`https://${domainName}/api/students-list/${teacher.code}`)
                  .then((response) => {
                    setStudents(response.data.students);

                    
                  })
                  .catch((error) => {
                    console.error(error);
                  });

                  Axios.get(`https://${domainName}/api/ue-list/${userCode}`)
                    .then((res)=>{
                        setUe(res.data);
                        
                    })
                    .catch((err)=>{
                        console.log(err);
                    });

                    Axios.get(`https://${domainName}/api/themes-get/${userId}`)
                    .then((res)=>{
                        setThemes(res.data);
                        
                    })
                    .catch((err)=>{
                        console.log(err);
                    });


                }


                  
                   
              },[students, ue, themes]);
        
    
    return (

        <><div className="main-wrapper">

            <NavBar user="Teacher"/>
            
            <SideBar />

                <div className="page-wrapper">
                    <div className="content container-fluid">

                    <div className="page-header">
                    <div className="row">
                    <div className="col-sm-12">
                    <div className="page-sub-header">
                    <h3 className="page-title">Welcome {teacher?teacher.user?teacher.user.fullname:"Wait loading ..":"Jonathan"}!</h3>
                    <ul className="breadcrumb">
                    
                    </ul>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-3 col-sm-6 col-12 d-flex">
                    <div className="card bg-comman w-100">
                    <div className="card-body" onClick={() => navigateTo(`/MatiereTeacher/`)}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: hoveredIndex === true ? 'lightgray' : 'inherit',
                        }}
                        
                            onMouseEnter={() => setHoveredIndex(true)}
                            onMouseLeave={() => setHoveredIndex(false)}>
                    <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                    <h6>Total Unit</h6>
                    <h3>{ue.length}</h3>
                    </div>
                    <div className="db-icon">
                    <img src="/assets/img/icons/teacher-icon-01.svg" alt="Dashboard Icon"/>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12 d-flex">
                    <div className="card bg-comman w-100">
                    <div className="card-body">
                    <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                    <h6>Total Students</h6>
                    <h3>{students.classmates? students.classmates.length: students.length } </h3>
                    </div>
                    <div className="db-icon">
                    <img src="assets/img/icons/dash-icon-01.svg" alt="Dashboard Icon"/>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12 d-flex">
                    <div className="card bg-comman w-100">
                    <div className="card-body">
                    <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                    <h6>Total Lessons</h6>
                    <h3>{matiers.length}</h3>
                    </div>
                    <div className="db-icon">
                        <img src="/assets/img/icons/teacher-icon-02.svg" alt="Dashboard Icon"/>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                        <div className="card bg-comman w-100">
                        <div className="card-body">
                        <div className="db-widgets d-flex justify-content-between align-items-center">
                        <div className="db-info">
                        <h6>Total Hours</h6>
                        <h3>15/20</h3>
                    </div>
                    <div className="db-icon">
                        <img src="/assets/img/icons/teacher-icon-03.svg" alt="Dashboard Icon"/>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>


                    <div className="row">
                    <div className="col-12 col-lg-12 col-xl-8">
                    <div className="row">
                    <div className="col-12 col-lg-12 col-xl-12 d-flex">
                    <div className="card flex-fill comman-shadow">
                    <div className="card-header">
                    <div className="row align-items-center">
                    <div className="col-6">
                    <h5 className="card-title">Upcoming Lesson</h5>
                    </div>
                    <div className="col-6">
                    <span className="float-end view-link"><a href="#">View All Courses</a></span>
                    </div>
                    </div>
                    </div>
                    <div className="pt-3 pb-3">
                    <div className="table-responsive lesson">
                    <table className="table table-center">
                    <tbody>
                    <tr>
                    <td>
                    <div className="date">
                    <b>Lessons 30</b>
                    <p>3.1 Ipsuum dolor</p>
                    <ul className="teacher-date-list">
                    <li><i className="fas fa-calendar-alt me-2"></i>Sep 5, 2022</li>
                    <li>|</li>
                    <li><i className="fas fa-clock me-2"></i>09:00 - 10:00 am</li>
                    </ul>
                    </div>
                    </td>
                    <td>
                    <div className="lesson-confirm">
                            <a href="#">Confirmed</a>
                            </div>
                            <button type="submit" className="btn btn-info">Reschedule</button>
                            </td>
                            </tr>
                            <tr>
                            <td>
                            <div className="date">
                            <b>Lessons 30</b>
                            <p>3.1 Ipsuum dolor</p>
                            <ul className="teacher-date-list">
                            <li><i className="fas fa-calendar-alt me-2"></i>Sep 5, 2022</li>
                            <li>|</li>
                            <li><i className="fas fa-clock me-2"></i>09:00 - 10:00 am</li>
                            </ul>
                            </div>
                            </td>
                            <td>
                            <div className="lesson-confirm">
                            <a href="#">Confirmed</a>
                            </div>
                            <button type="submit" className="btn btn-info">Reschedule</button>
                            </td>
                            </tr>
                            </tbody>
                            </table>
                            </div>
                            </div>
                            </div>
                            </div>
                  
                    </div>
                    <Themes id={shownTheme}/>
                    <div className="row">
                    <div className="col-12 col-lg-12 col-xl-12 d-flex">
                    <div className="card flex-fill comman-shadow">
                    <div className="card-header">
                    <div className="row align-items-center">
                    <div className="col-6">
                    <h5 className="card-title">Teaching Activity</h5>
                    </div>
                    <div className="col-6">
                    <ul className="chart-list-out">
                    <li><span className="circle-blue"></span>Teacher</li>
                    <li><span className="circle-green"></span>Students</li>
                    <li className="star-menus"><a href="javascript:;"><i className="fas fa-ellipsis-v"></i></a></li>
                    </ul>
                    </div>
                    </div>
                    </div>
                    <div className="card-body">
                    <div id="school-area"></div>
                    </div>
                    </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12 d-flex">
                    <div className="card flex-fill comman-shadow">
                    <div className="card-header d-flex align-items-center">
                    <h5 className="card-title">Teaching History</h5>
                    <ul className="chart-list-out student-ellips">
                    <li className="star-menus"><a href="javascript:;"><i className="fas fa-ellipsis-v"></i></a></li>
                    </ul>
                    </div>
                    <div className="card-body">
                    <div className="teaching-card">
                    <ul className="steps-history">
                    <li>Sep22</li>
                    <li>Sep23</li>
                    <li>Sep24</li>
                    </ul>
                    <ul className="activity-feed">
                    <li className="feed-item d-flex align-items-center">
                    <div className="dolor-activity">
                    <span className="feed-text1"><a>Mathematics</a></span>
                    <ul className="teacher-date-list">
                    <li><i className="fas fa-calendar-alt me-2"></i>September 5, 2022</li>
                    <li>|</li>
                    <li><i className="fas fa-clock me-2"></i>09:00 am - 10:00 am (60 Minutes)</li>
                    </ul>
                    </div>
                    <div className="activity-btns ms-auto">
                    <button type="submit" className="btn btn-info">In Progress</button>
                    </div>
                    </li>
                    <li className="feed-item d-flex align-items-center">
                    <div className="dolor-activity">
                    <span className="feed-text1"><a>Geography </a></span>
                    <ul className="teacher-date-list">
                    <li><i className="fas fa-calendar-alt me-2"></i>September 5, 2022</li>
                    <li>|</li>
                    <li><i className="fas fa-clock me-2"></i>09:00 am - 10:00 am (60 Minutes)</li>
                    </ul>
                    </div>
                    <div className="activity-btns ms-auto">
                    <button type="submit" className="btn btn-info">Completed</button>
                    </div>
                    </li>
                    <li className="feed-item d-flex align-items-center">
                    <div className="dolor-activity">
                    <span className="feed-text1"><a>Botony</a></span>
                    <ul className="teacher-date-list">
                    <li><i className="fas fa-calendar-alt me-2"></i>September 5, 2022</li>
                    <li>|</li>
                    <li><i className="fas fa-clock me-2"></i>09:00 am - 10:00 am (60 Minutes)</li>
                    </ul>
                    </div>
                    <div className="activity-btns ms-auto">
                    <button type="submit" className="btn btn-info">In Progress</button>
                    </div>
                    </li>
                    </ul>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-4 d-flex">
                    
                    <div className="card flex-fill comman-shadow">
                    <div className="card-body">
                    <div className="crad mt-2">
                        <div className="card-header">
                            <h4 className="card-title card-header up-come-header "> 
                                Discussion Thems: {themes.length}
                                {teacher && teacher.type === "teacher" && (<span title="add a discussion " onClick={()=>{setShowModal(true)}}><a href="javascript:;"><i className="feather-plus"></i></a></span>)}

                                    <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                                        <div className="modal-dialog ">
                                        <div className="modal-content ">
                                            <div className="modal-header">
                                            <h5 className="modal-title">Add a new discussion thematic</h5>
                                            <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body text-center">
                                                     <div className="mb-4">
                                  <h5>Theme informations 

                                  </h5>
                                </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="fullname"
                                      placeholder="Title"
                                      value={theme}
                                      onChange={(e)=>{setTheme(e.target.value)}}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      name="theme"
                                      placeholder="description"
                                      value={description}
                                      onChange={(e)=>{setSetDescription(e.target.value)}}
                                      required></textarea>
                                    
                                  </div>
                                  
                                            </div>
                                            <div className="modal-footer">
                                                <span className="btn btn-info "  style={{marginRight:'70%'  }} onClick={handleEditButtonClick}> 
                                                    Save 
                                                </span>
                                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                            </h4>   
                        </div>
                        <div class="card flex-fill bg-white">
                            <ul class="list-group list-group-flush">
                            {themes.length>0 && themes.map((th, index)=>(
                                <li class="list-group-item" onClick={()=>{setShownedTheme(th.id)}} onMouseEnter={()=>{setHovered(index)}} onMouseLeave={()=>{setHovered(-1)}} style={{ cursor:"pointer", backgroundColor:hovered === index ? "#cfcfcf":"initial"}}><span style={{fontWeight:"bold"}}>{th.title}</span><br/><span style={{"fontSize":'smaller'}}>{th.description}</span></li>
                            ))}
                            </ul>
                            </div>
                    </div>
                    <div id="calendar-doctor" class="calendar-container row">   </div><br/>
                    <div className="calendar-info calendar-info1 row">
                    <div className="up-come-header">
                    <h2>Upcoming Events</h2>
                    <span><a href="javascript:;"><i className="feather-plus"></i></a></span>
                    </div>
                    <div className="upcome-event-date">
                    <h3>10 Jan</h3>
                    <span><i className="fas fa-ellipsis-h"></i></span>
                    </div>
                    <div className="calendar-details">
                    <p>08:00 am</p>
                    <div className="calendar-box normal-bg">
                    <div className="calandar-event-name">
                    <h4>Botony</h4>
                    <h5>Lorem ipsum sit amet</h5>
                    </div>
                    <span>08:00 - 09:00 am</span>
                    </div>
                    </div>
                    <div className="calendar-details">
                    <p>09:00 am</p>
                    <div className="calendar-box normal-bg">
                    <div className="calandar-event-name">
                    <h4>Botony</h4>
                    <h5>Lorem ipsum sit amet</h5>
                    </div>
                    <span>09:00 - 10:00 am</span>
                    </div>
                    </div>
                    <div className="calendar-details">
                    <p>10:00 am</p>
                    <div className="calendar-box normal-bg">
                    <div className="calandar-event-name">
                    <h4>Botony</h4>
                    <h5>Lorem ipsum sit amet</h5>
                    </div>
                    <span>10:00 - 11:00 am</span>
                    </div>
                    </div>
                    <div className="upcome-event-date">
                    <h3>10 Jan</h3>
                    <span><i className="fas fa-ellipsis-h"></i></span>
                    </div>
                    <div className="calendar-details">
                    <p>08:00 am</p>
                    <div className="calendar-box normal-bg">
                    <div className="calandar-event-name">
                    <h4>English</h4>
                    <h5>Lorem ipsum sit amet</h5>
                    </div>
                    <span>08:00 - 09:00 am</span>
                    </div>
                    </div>
                    <div className="calendar-details">
                    <p>09:00 am</p>
                    <div className="calendar-box normal-bg">
                    <div className="calandar-event-name">
                    <h4>Mathematics </h4>
                    <h5>Lorem ipsum sit amet</h5>
                    </div>
                    <span>09:00 - 10:00 am</span>
                    </div>
                    </div>
                    <div className="calendar-details">
                    <p>10:00 am</p>
                    <div className="calendar-box normal-bg">
                    <div className="calandar-event-name">
                    <h4>History</h4>
                    <h5>Lorem ipsum sit amet</h5>
                    </div>
                    <span>10:00 - 11:00 am</span>
                    </div>
                    </div>
                    <div className="calendar-details">
                    <p>11:00 am</p>
                    <div className="calendar-box break-bg">
                    <div className="calandar-event-name">
                    <h4>Break</h4>
                    <h5>Lorem ipsum sit amet</h5>
                    </div>
                    <span>11:00 - 12:00 am</span>
                    </div>
                    </div>
                    <div className="calendar-details">
                    <p>11:30 am</p>
                    <div className="calendar-box normal-bg">
                    <div className="calandar-event-name">
                    <h4>History</h4>
                    <h5>Lorem ipsum sit amet</h5>
                    </div>
                    <span>11:30 - 12:00 am</span>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>

                    </div>

                   

                    </div>
                    <script src="assets/js/jquery-3.6.0.min.js"></script>

                    <script src="public/assets/plugins/bootstrap/js/bootstrap.bundle.min.js"></script>

                    <script src="public/assets/js/feather.min.js"></script>

                    <script src="public/assets/plugins/slimscroll/jquery.slimscroll.min.js"></script>

                    <script src="public/assets/plugins/apexchart/apexcharts.min.js"></script>
                    <script src="public/assets/plugins/apexchart/chart-data.js"></script>

                    <script src="public/assets/plugins/simple-calendar/jquery.simple-calendar.js"></script>
                    <script src="public/assets/js/calander.js"></script>

                    <script src="public/assets/js/circle-progress.min.js"></script>

                    <script src="public/assets/js/script.js"></script>


            
        </div>
        </>

    )}
    export default  Hometeacher;