import React, { useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import Axios from 'axios';
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'




function CourseAdd(){

    const [courseFiles, setCourseFile] = useState(null);
    const [title, setTilte] = useState('');
    const [descrption, setDescription] = useState('');
    const [fileList, setFileList] = useState([]);
    const [userCode, setUserCode] = useState('');
    const [userInf, setUserInf] = useState([]);
    const [savedFormData, setSavedFormData] = useState([]);
    const navigateTo = useNavigate();






    const {ueId} = useParams();
    if(userCode === ''){
    try {
        const encryptedData = sessionStorage.getItem('userInfo');
        if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            if (decryptedData) {
            const userInfo = JSON.parse(decryptedData);
            setUserCode(userInfo.code);
            setUserInf(userInfo);
            }
        }else{
            alert("Page only permit to teachers and students");
            navigateTo('/login');
        }}catch(err){
            
        }
    }


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    const createCourse = (e) => {
      e.preventDefault();

      if (title.trim() === '' || fileList.length === 0 || descrption.trim() === '') {
        alert('Veuillez remplir tous les champs');
        return;
      }

      const formData = new FormData();
      const  filesArray = Object.values(fileList);
      filesArray.forEach((file) => {
        formData.append('files', file);
      });
      formData.append('teacherCode', userInf.user.code);
      formData.append('title', title);
      formData.append('description', descrption);

      Axios.post(`https://${domainName}/api/course-add/${ueId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          navigateTo(`couse-list/${ueId}`);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(() => {
    

    const savedData = sessionStorage.getItem('formData');
    if (savedData) {
        console.log(savedData);
        if(title === undefined || title === ''){
            setTilte(savedData.title);
            setFileList(savedData.fileList);
            setDescription(savedData.descrption);
        }else{
            console.log("title :"+title)
        }
    }
}, [fileList]);

    return (

<div className="main-wrapper">

        <NavBar />
            
        <SideBar />

        <div class="page-wrapper">
    <div class="content container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <div class="page-header">
                    <div class="row">
                        <div class="col-sm-12">
                            <h3 class="page-title">Add Post</h3>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <form>
                        <div class="bank-inner-details">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">

                                    <div class="form-group">
                                        <label>Title<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" value={title} onChange={(e)=>{setTilte(e.target.value)}}/>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                    <label htmlFor="file">Sélectionnez un fichier PDF à importer :</label>
                                        <div class="change-photo-btn">
                                            
                                            <input type="file" name="file" id="file" onChange={(e)=>{setFileList(e.target.files)}}  multiple />
                                        </div>
                                        <div class="row"></div>
                                    </div>
                                </div>
                            <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Description</label>
                                        
                                        <div id="editor"></div>
                                        <textarea class="form-control" id="description"name="description" rows="10" value={descrption} onChange={(e)=>{setDescription(e.target.value)}}>{descrption}</textarea>
                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="blog-categories-btn pt-0">
                            <div class="bank-details-btn">
                                <a href="blog.html" class="btn bank-cancel-btn me-2" onClick={createCourse}>Add Post</a>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        
<script src="assets/js/jquery-3.6.0.min.js"></script>

<script src="assets/plugins/bootstrap/js/bootstrap.bundle.min.js"></script>

<script src="assets/js/feather.min.js"></script>

<script src="assets/plugins/slimscroll/jquery.slimscroll.min.js"></script>

<script src="assets/plugins/select2/js/select2.min.js"></script>

<script src="assets/js/feather.min.js"></script>

<script src="assets/js/ckeditor.js"></script>

<script src="assets/plugins/bootstrap-tagsinput/js/bootstrap-tagsinput.js"></script>

<script src="assets/js/script.js"></script>                                    
            
</div>

    )}
    
export default  CourseAdd   ;
                                            
