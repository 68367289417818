import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import ENCRYPTION_KEY from './../../key'
import encryptTextWithKey from './../../utils'

function TeacherInscription() {
    const [form1Valid, setForm1Valid] = useState(false)
    const [form2Valid, setForm2Valid] = useState(false)
    const [form3Valid, setForm3Valid] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [imgUrl, setImgUrl] = useState('#');
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [gpa, setGpa] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [sexe, setSexe] = useState('');
    const [pays, setPays] = useState('');
    const [ville, setVille] = useState('')
    const [classi, setClass] = useState('')
    const [something, setSomething] = useState('')
    const [objectives, setObjectives] = useState('')
    const [statusMessage, setStatuMessage] = useState('');
    const [loginStatus, setLoginStatus] = useState('');
    const [step, setStep] = useState(1);
    const navigateTo = useNavigate();
    const [savedFormData, setSavedFormData] = useState([]);


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value)
    }

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value)
    }

    const handleSexeChange = (event) => {
        setSexe(event.target.value)
    }

    const handlePaysChange = (event) => {
        setPays(event.target.value)
    }


    const handleVilleChange = (event) => {
        setVille(event.target.value)
    }



      const handleChange = (event) => {
    event.preventDefault(); // empêche le comportement par défaut de rechargement de page
    
  };


    const handleNextStep = (e) => {
        if (step === 1 && firstName.trim() !== '' && lastName.trim() !== '' && dateOfBirth !== '' && gpa !== '' && sexe !== '') {
            setForm1Valid(true);
            setStep(step + 1);
        } else if (step === 2 && pays.trim() !== ''  && ville.trim() !== '' ) {
            setForm2Valid(true);
            setStep(step + 1);
        } else if (step === 3 && classi !== ''  ) {
            setForm3Valid(true);
            setStep(step + 1);
        }else if( step === 4  )
        {
            handleSubmit(e);
        }
    }

    const handlePreviousStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    }

    const handleSubmit = async (event) => {

        function copyToClipboard(text) {
            // Crée un élément textarea temporaire
            const textarea = document.createElement('textarea');
            // Assigne le texte à l'élément textarea
            textarea.value = text;
            // Ajoute l'élément textarea à la fin du corps du document
            document.body.appendChild(textarea);
            // Sélectionne tout le texte dans l'élément textarea
            textarea.select();
          
            try {
              // Utilise l'API Clipboard pour copier le texte dans le presse-papiers
              navigator.clipboard.writeText(textarea.value)
                .then(() => {
                  console.log('Texte copié dans le presse-papiers avec succès.');
                })
                .catch(err => {
                  console.error('Erreur lors de la copie dans le presse-papiers.', err);
                });
            } finally {
              // Supprime l'élément textarea temporaire
              document.body.removeChild(textarea);
            }
          }
          
        event.preventDefault()
        if (form1Valid && form2Valid && form3Valid) {
            const data  ={
                firstName: firstName,
                lastName: lastName,
                email: email,
                dateOfBirth: dateOfBirth,
                sexe: sexe,
                pays: pays,
                ville: ville,
                gpa:gpa,
                phoneNumber: phoneNumber,
                classi: classi,
                objectives:objectives,
                password: encryptTextWithKey(password,ENCRYPTION_KEY.ENCRYPTION_KEY )

            };

            try {
                const response = await Axios.post(`https://${domainName}/api/teacher-inscription`, data)
                setStatuMessage(response.data.msg)
                //alert(response.data.msg);
                copyToClipboard(response.data.code)
                setEmail(response.data.code)
                setFirstName('');
                setLastName('');
                setPassword('');
                setConfirmPassword('');
                setObjectives('')
                setClass('')
                setDateOfBirth('');
                setGpa('');
                setPhoneNumber('');
                setSexe('');
                setPays('');
                setVille('');
                sessionStorage.removeItem('formData');
                //navigateTo(`/login`);
            } catch (error) {
                console.log(error)
            }
        } else {
            alert("Fill all inputs correctly !");
        }

    }


    return (
        <>
            <div className="main-wrapper login-body">
                <div className="login-wrapper">
                    <div className="container">
                        <div className="loginbox">
                            <div className="login-left">
                                <img className="img-fluid" src="assets/img/download.png" alt="Logo" />
                            </div>
                            <div className="login-right">
                                <div className="login-right-wrap">
                                    <a href="/" class="logo" style={{ marginBottom: '15px' }}>
                                      <img src="/assets/img/logo.png" alt="Logo" />
                                    </a>
                                    <br />
                                    <h1>Teacher Sign Up</h1>
                                    <p className="account-subtitle">Enter your informations to create your account</p>

                                    <form onSubmit={handleSubmit}>
                                        {step === 1 && (
                                            <>
                                                <div className="form-group">
                                                    <label>First Name <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={firstName} onChange={handleFirstNameChange} />
                                                    <span className="profile-views"><i className="fas fa-user-circle"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Last Name <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={lastName} onChange={handleLastNameChange} />
                                                    <span className="profile-views"><i className="fas fa-user-circle"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Sexe <span className="login-danger">*</span></label>
                                                    <select className="form-control" value={sexe} onChange={handleSexeChange} disabled={false}>
                                                        <option value="">--------</option>
                                                        <option value="Male">Male<i className="fas fa-venus"></i></option>
                                                        <option value="Female">Female <i className="fas fa-mars"></i></option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    <span className="profile-views"><i className="fas fa-venus-mars"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Date Of Birth <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="date" value={dateOfBirth} onChange={(e)=>{setDateOfBirth(e.target.value)}} />
                                                    <span className="profile-views"><i className="fas fa-date"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Last diploma's name <span className="login-danger">*</span></label>
                                                    <select className="form-control" value={gpa} onChange={(e)=>{setGpa(e.target.value)}} disabled={false}>
                                                        <option value="">--------</option>
                                                        <option value="GCE Advance Level">GCE Advance Level<i className="fas fa-venus"></i></option>
                                                        <option value="Bachelor Degre">Bachelor Degre<i className="fas fa-venus"></i></option>
                                                        <option value="Master I">Master I <i className="fas fa-mars"></i></option>
                                                        <option value="Master II">Master II <i className="fas fa-mars"></i></option>
                                                        <option value="PhD">PhD <i className="fas fa-mars"></i></option>
                                                        <option value="Professor">Professor <i className="fas fa-mars"></i></option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    <span className="profile-views"><i className="fas fa-solid fa-user-md" ></i></span>
                                                </div>
                                            </>
                                        )}

                                        {step === 2 && (
                                            <>
                                                <div className="form-group">
                                                    <label>Country<span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={pays} name="country" onChange={handlePaysChange} />
                                                </div>
                                                <div className="form-group">
                                                    <label>City <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={ville} name="city" onChange={handleVilleChange} />
                                                </div>
                                            </>
                                        )}

                                        {step === 3 && (
                                            <>
                                                <>
                                                <div className="form-group">
                                                    <label>Class Subject<span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={classi} onChange={(e)=>{setClass(e.target.value)}} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Objectives </label>
                                                    <input className="form-control" type="text" value={objectives} onChange={(e)=>{setObjectives(e.target.value)}} />
                                                </div>
                                            </>
                                            </>
                                        )}

                                        {step === 4 && (
                                            <>

                                                <div className="form-group">
                                                    <label>Email <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={email} onChange={handleEmailChange} />
                                                    <span className="profile-views"><i className="fas fa-envelope"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Phone <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="number" value={phoneNumber} onChange={handlePhoneNumberChange} />
                                                    <span className="profile-views"><i className="fas fa-solid fa-phone"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Password <span className="login-danger">*</span></label>
                                                    <input className="form-control pass-input" type="password" value={password} onChange={handlePasswordChange} />
                                                    <span className="profile-views feather-eye toggle-password"></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm password <span className="login-danger">*</span></label>
                                                    <input className="form-control pass-confirm" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                                                    <span className="profile-views feather-eye reg-toggle-password"></span>
                                                </div>
                                            </>
                                        )}

                                        <div className=" dont-have">
                                            Already Have a student Code ? <Link to="../login">Login</Link>
                                        </div>
                                        <div className="form-group mb-0" />

                                        <div className="text-center form-group mb-0   ">
                                            <button className="btn btn-primary  " style= {{'marginRight':'10px'}} type="button" onClick={handlePreviousStep} disabled={step <= 1}>
                                                Previous
                                            </button>
                                            <Link to="#signup-modal" {...(step === 4 ? { 'data-bs-toggle': 'modal', 'data-bs-target': '#signup-modal' } : {})} className="btn btn-primary" type="button" style= {{'marginLeft':'10px'}} onClick={(e)=>handleNextStep(e)} disabled={(step === 1 && !(firstName.trim() !== '' && lastName.trim() !== '' && dateOfBirth.trim() != '' && gpa.trim() !== '')) || (step === 2 && !(pays.trim() !== '' &&  ville.trim() !== '' )) || (step === 3 && classi.trim() === '') || (step === 4 &&!(imgUrl.trim() !== '' && password.length !== 0 && confirmPassword === password && email.trim() !=='' && phoneNumber !== 0))}>
                                                {step === 4 ? 'Register' : 'Next'}
                                            </Link>
                                        </div>
                                    </form>
                                </div>

                                <div id="signup-modal" class="modal fade" tabindex="-1" style={{"display": "none"}} aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                        <div class="modal-body">
                                            <div class="text-center mt-2 mb-4">
                                            <div class="auth-logo">
                                                <a href="#" class="logo logo-dark">
                                                <span class="logo-lg">
                                                    <img src="assets/img/logo.png" alt="" height="42"/>
                                                </span>
                                                </a>
                                            </div>
                                            </div>
                                            <div class="card">
                                                <div class="card-header">
                                                <h5 class="card-title">Response Message</h5>
                                                </div>
                                                <div class="card-body">
                                                <div class="clipboard copy-txt">
                                                <p class="otp-pass"> {statusMessage} <span style={{display:"none"}} id="paragraph-copy1"> {email} </span>.</p>
                                                <p class="mb-4">Please do not share it to anyone</p>
                                                <a class="mb-1 btn clip-btn btn-primary" href="javascript:;" data-clipboard-action="copy" data-clipboard-target="#paragraph-copy1"><i class="far fa-copy"></i> Copy to the clipboard</a>
                                                </div>
                                                </div>
                                                </div>
                                        
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TeacherInscription;
