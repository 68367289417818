import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import ENCRYPTION_KEY from './../../key'
import encryptTextWithKey from './../../utils'

function StudentPreInscription() {
    const [form1Valid, setForm1Valid] = useState(false)
    const [form2Valid, setForm2Valid] = useState(false)
    const [form3Valid, setForm3Valid] = useState(false)
    const [form4Valid, setForm4Valid] = useState(false)
    const [load, setLoad] = useState(false)
    const [schoolList, setSchoolList] = useState([]);
    const [facultyList, setFacultyList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [filiereList, setFiliereList] = useState([]);
    const [niveauList, setNiveauList] = useState([]);
    const [optionList, setOptionList] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [imgUrl, setImgUrl] = useState('#');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')
    const [invite, setInvite] = useState(false);
    const [code, setCode] = useState('');
    const [school, setSchool] = useState('');
    const [faculty, setFaculty] = useState('');
    const [department, setDepartment] = useState('');
    const [filiere, setFiliere] = useState('');
    const [niveau, setNiveau] = useState('');
    const [option, setOption] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [sexe, setSexe] = useState('');
    const [pays, setPays] = useState('');
    const [region, setRegion] = useState('');
    const [ville, setVille] = useState('');
    const [district, setDistrict] = useState('');
    const [gpa, setGpa] = useState('');
    const [loginStatus, setLoginStatus] = useState('');
    const [statusMessage, setStatuMessage] = useState('');
    const [step, setStep] = useState(1);

    const [parentName, setParentName] = useState('');
    const [parentEmail, setParentEmail] = useState('');
    const [parentTel, setParentTel] = useState(0);

    const navigateTo = useNavigate();
    const [savedFormData, setSavedFormData] = useState([]);


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value)
    }

    const handleDateOfBirthChange = (event) => {
        setDateOfBirth(event.target.value)
    }

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value)
    }

    const handleSexeChange = (event) => {
        setSexe(event.target.value)
    }

    const handlePaysChange = (event) => {
        setPays(event.target.value)
    }

    const handleRegionChange = (event) => {
        setRegion(event.target.value)
    }

    const handleVilleChange = (event) => {
        setVille(event.target.value)
    }

    const handleSchoolChange = async (event) => {
        setSchool(event.target.value)
         await Axios.get(`https://${domainName}/api/school-details/${event.target.value}`)
            .then((res) => {
                setFacultyList(res.data.facs);
            }).catch((error) => {
                console.log(error)
            })
    }

    const handleFacultyChange = async (event) => {
        setFaculty(event.target.value);
         await Axios.get(`https://${domainName}/api/faculty-details/${event.target.value}`)
            .then((res) => {
                setDepartmentList(res.data.departments);
            }).catch((error) => {   
                console.log(error)
            })

    }

    const handleDepartmentChange = async (event) => {
        setDepartment(event.target.value);
         await Axios.get(`https://${domainName}/api/department-details/${event.target.value}`)
            .then((res) => {
                setFiliereList(res.data.filieres);
            }).catch((error) => {
                console.log(error)
            })
    }

    const handleFiliereChange = async (event) => {
        setFiliere(event.target.value);
        await Axios.get(`https://${domainName}/api/filiere-details/${event.target.value}`)
            .then((res) => {
                setNiveauList(res.data.niveaux);
            }).catch((error) => {
                console.log(error)
            })
    }

    const handleNiveauChange = async (event) => {
        setNiveau(event.target.value);
        await Axios.get(`https://${domainName}/api/niveau-details/${event.target.value}`)
            .then((res) => {
                console.log(res.data);
                setOptionList(res.data.options);
            }).catch((error) => {
                console.log(error)
            })
    }

   
    const handleProfilePictureChange = async (event) => {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("image", file);

      try {
        const data = {student:{
                firstName: firstName,
                lastName: lastName,
                email: email,
                dateOfBirth: dateOfBirth,
                sexe: sexe,
                pays: pays,
                region: region,
                ville: ville,
                district:district,
                imgUrl:response.data.url,
                placeOfBirth:placeOfBirth,
                phoneNumber: phoneNumber,
                niveauId: niveau,
                option:option,
                niveau:invite === true ? code : niveau,
                filiere:filiere,
                department:department,
                school:school,
                faculty:faculty,
                optionId: option},
                parent:{
                    fullname:parentName,
                    email:parentEmail,
                    phoneNumber:parentTel,
                },
                password: password,
                confirmPassword:confirmPassword,


            };

        sessionStorage.setItem('formData', JSON.stringify(data));
        

        const response = await Axios.post(`https://${domainName}/api/uploaded`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
        });
        setImgUrl(response.data.url);


      } catch (error) {
        console.error(error);
        // Gérer l'erreur ici
      }
    };

      const handleChange = (event) => {
    event.preventDefault(); // empêche le comportement par défaut de rechargement de page
    
  };


    const handleNextStep = (e) => {
        if (step === 1 && firstName.trim() !== '' && lastName.trim() !== '' && dateOfBirth !== '' && placeOfBirth !== '' && sexe !== '') {
            e.preventDefault()
            setForm1Valid(true);
            setStep(step + 1);
        } else if (step === 2 && pays.trim() !== ''  && ville.trim() !== '' ) {
        e.preventDefault()
        setForm2Valid(true);
            setStep(step + 1);
        } else if ((step === 3 && niveau !== '' ) || (step === 3 && invite ===true &&  code.trim() !== '')) {
        e.preventDefault()
        setForm3Valid(true);
            setStep(step + 1);
        
    } else if (step === 4 && parentTel !=='' &&  parentName.trim() !== '' && parentEmail.trim() !== '') {
        e.preventDefault()
        setForm4Valid(true);
            setStep(step + 1);
        }else if( step === 5 )
        {
            handleSubmit(e);
        }
    }

    const handlePreviousStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (form1Valid && form2Valid && form3Valid && form4Valid) {
            const data = { student:{
                            firstName: firstName,
                            lastName: lastName,
                            email: email,
                            dateOfBirth: dateOfBirth,
                            sexe: sexe,
                            pays: pays,
                            region: region,
                            ville: ville,
                            district:district,
                            placeOfBirth:placeOfBirth,
                            phoneNumber: phoneNumber,
                            profilePicture:imgUrl,
                            niveauId: invite === true ? code : niveau, 
                        optionId: option===''? null : option
                    },
                        parent:{
                            fullname:parentName,
                            email:parentEmail,
                            phoneNumber:parentTel,
                        },
                password: encryptTextWithKey(password,ENCRYPTION_KEY.ENCRYPTION_KEY )

            };

            try {
                function copyToClipboard(text) {
                    // Crée un élément textarea temporaire
                    const textarea = document.createElement('textarea');
                    // Assigne le texte à l'élément textarea
                    textarea.value = text;
                    // Ajoute l'élément textarea à la fin du corps du document
                    document.body.appendChild(textarea);
                    // Sélectionne tout le texte dans l'élément textarea
                    textarea.select();
                  
                    try {
                      // Utilise l'API Clipboard pour copier le texte dans le presse-papiers
                      navigator.clipboard.writeText(textarea.value)
                        .then(() => {
                          console.log('Texte copié dans le presse-papiers avec succès.');
                        })
                        .catch(err => {
                          console.error('Erreur lors de la copie dans le presse-papiers.', err);
                        });
                    } finally {
                      // Supprime l'élément textarea temporaire
                      document.body.removeChild(textarea);
                    }
                  }

                const response = await Axios.post(`https://${domainName}/api/preinscription`, data)
                console.log(response.data);
                setStatuMessage(response.data.msg)
                //alert(response.data.msg + " \n ce code sera copie dans votre presse passiers");
                copyToClipboard(response.data.code)
                setEmail(response.data.code);
                setFirstName('');
                setLastName('');
                setPassword('');
                setConfirmPassword('');
                setSchool('');
                setFaculty('');
                setDepartment('');
                setFiliere('');
                setNiveau('');
                setOption('');
                setDateOfBirth('');
                setDistrict('');
                setPlaceOfBirth('');
                setPhoneNumber('');
                setSexe('');
                setPays('');
                setRegion('');
                setVille('');
                setGpa('');
                setStep(-1);
                setParentTel(0);
                setParentName('');
                setParentEmail('');
                sessionStorage.removeItem('formData');
                //navigateTo(`/login`);
            } catch (error) {
                console.log(error)
            }
        } else {
            alert("Fill all inputs correctly !");
        }

    }

   useEffect(() => {
    if (schoolList === undefined || schoolList.length === 0 && load === false) {
        setLoad(true);
        Axios.get(`https://${domainName}/api/school-list`)
            .then((res) => {
                setSchoolList(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const savedData = sessionStorage.getItem('formData');
    if (savedData) {
        console.log(savedData);
        setSavedFormData(JSON.parse(savedData));
        if(savedFormData.length !==0 && firstName === ''){
            console.log(savedFormData);
            setFirstName(savedFormData.student.firstName);
            setLastName(savedFormData.student.lastName);
            setEmail(savedFormData.student.email);
            setDateOfBirth(savedFormData.student.dateOfBirth);
            setSexe(savedFormData.student.sexe);
            setPays(savedFormData.student.pays);
            setRegion(savedFormData.student.region);
            setVille(savedFormData.student.ville);
            setDistrict(savedFormData.student.district);
            setPlaceOfBirth(savedFormData.student.placeOfBirth);
            setPhoneNumber(savedFormData.student.phoneNumber);
            setNiveau(savedFormData.student.niveauId);
            setCode(savedFormData.student.niveauId);
            setOption(savedFormData.student.optionId);
            setPassword(savedFormData.password);
            setConfirmPassword(savedFormData.confirmPassword);
            setImgUrl(savedFormData.student.imgUrl);
            setSchool(savedFormData.student.school);
            setFaculty(savedFormData.student.faculty);
            setDepartment(savedFormData.student.department);
            setFiliere(savedFormData.student.filiere);
            setNiveau(savedFormData.student.niveau);
            setParentTel(savedFormData.parent.phoneNumber);
            setParentEmail(savedFormData.parent.email);
            setParentName(savedFormData.parent.fullname);
        }
    }
}, [schoolList]);

    return (
        <>
            <div className="main-wrapper login-body">
                <div className="login-wrapper">
                    <div className="container">
                        <div className="loginbox">
                            <div className="login-left">
                                <img className="img-fluid" src="assets/img/download.png" alt="Logo" />
                            </div>
                            <div className="login-right">
                                <div className="login-right-wrap">
                                    <a href="/" class="logo" style={{ marginBottom: '15px' }}>
                                      <img src="/assets/img/logo.png" alt="Logo" />
                                    </a>
                                    <br />
                                    <h1>Sign Up Form</h1><br/>

                                    <form onSubmit={handleSubmit}>
                                        {step === 1 && (
                                            <>
                                            <div className="mb-4">
                                              <h5>Personnal informations</h5>
                                            </div>
                                                <div className="form-group">
                                                    <label>First Name <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={firstName} onChange={handleFirstNameChange} />
                                                    <span className="profile-views"><i className="fas fa-user-circle"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Last Name <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={lastName} onChange={handleLastNameChange} />
                                                    <span className="profile-views"><i className="fas fa-user-circle"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Sexe <span className="login-danger">*</span></label>
                                                    <select className="form-control" value={sexe} onChange={handleSexeChange} disabled={false}>
                                                        <option value="">--------</option>
                                                        <option value="Male">Male<i className="fas fa-venus"></i></option>
                                                        <option value="Female">Female <i className="fas fa-mars"></i></option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    <span className="profile-views"><i className="fas fa-venus-mars"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Date Of Birth <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="date" value={dateOfBirth} onChange={(e)=>{setDateOfBirth(e.target.value)}} />
                                                    <span className="profile-views"><i className="fas fa-date"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Place Of Birth <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={placeOfBirth} onChange={(e)=>{setPlaceOfBirth(e.target.value)}} />
                                                    <span className="profile-views"><i className="fas fa-solid fa-user-md" ></i></span>
                                                </div>
                                            </>
                                        )}
                                        {step ===4 && (
                                          <div className="tab-pane active" id="progress-seller-details">
                                            <div className="mb-4">
                                              <h5>Parent informations</h5>
                                            </div>
                                            <form onSubmit={handleSubmit}>
                                              <div className="form-group">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  name="fullname"
                                                  placeholder="Parent Name"
                                                  value={parentName}
                                                  onChange={(e)=>{setParentName(e.target.value)}}
                                                  required
                                                />
                                              </div>
                                              <div className="form-group">
                                                <input
                                                  type="email"
                                                  className="form-control"
                                                  name="email"
                                                  placeholder="Parent Email"
                                                  value={parentEmail}
                                                  onChange={(e)=>{setParentEmail(e.target.value)}}
                                                  required
                                                />
                                              </div>
                                              <div className="form-group">
                                                <input
                                                  type="tel"
                                                  className="form-control"
                                                  name="phoneNumber"
                                                  placeholder="Parent Phone Number"
                                                  value={parentTel}
                                                  onChange={(e)=>{setParentTel(e.target.value)}}
                                                  required
                                                />
                                              </div>
                                              
                                            </form>
                                          </div>
                                        )}

                                        {step === 2 && (
                                            <>
                                            <div className="mb-4">
                                              <h5>Location informations</h5>
                                            </div>
                                                <div className="form-group">
                                                    <label>Country<span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={pays} onChange={handlePaysChange} />
                                                </div>
                                                <div className="form-group">
                                                    <label>City <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={ville} onChange={handleVilleChange} />
                                                </div>
                                            </>
                                        )}

                                        {step === 3 && (
                                            <>
                                            <div className="mb-4">
                                              <h5>School informations</h5>
                                            </div>
                                                <div className="form-group">
                                                    <label>School Name <span className="login-danger">*</span></label>
                                                    <select className="form-control" value={school} onChange={handleSchoolChange}>
                                                        <option>Select School</option>
                                                        {schoolList?schoolList.length !== 0 ? schoolList.map((sh) => (
                                                            <option value={sh.id}>{sh.schoolName}</option>)) : "Loading..": "loading ..."}
                                                    </select>
                                                    <span className="profile-views"><i className="fas fa-user-circle"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Faculty <span className="login-danger">*</span></label>
                                                    <select className="form-control" value={faculty} onChange={handleFacultyChange} disabled={facultyList ? facultyList.length === 0:true}>
                                                        <option>Select Faculty</option>
                                                        {facultyList?facultyList.length !== 0 ? facultyList.map((fac) => (
                                                            <option value={fac.id}>{fac.facName}</option>)) : "Loading.." : "Loading..."}
                                                    </select>

                                                </div>
                                                <div className="form-group">
                                                    <label>Department <span className="login-danger">*</span></label>
                                                    <select className="form-control" value={department} onChange={handleDepartmentChange} disabled={departmentList.length === 0}>
                                                        <option>Select Department</option>
                                                        {departmentList?departmentList.length !== 0 ? departmentList.map((dept) => (
                                                            <option value={dept.id}>{dept.name}</option>)) : "Loading..": "loading..."}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Filiere <span className="login-danger">*</span></label>
                                                    <select className="form-control" value={filiere} onChange={handleFiliereChange} disabled={filiereList.length === 0}>
                                                        <option>Select Filiere</option>
                                                        {filiereList?filiereList.length !== 0 ? filiereList.map((fil) => (
                                                            <option value={fil.id}>{fil.name}</option>)) : "Loading..": "Loading..."}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label>Niveau <span className="login-danger">*</span></label>
                                                    <select className="form-control" value={niveau} onChange={handleNiveauChange} disabled={niveauList.length === 0}>
                                                        <option>Select Niveau</option>
                                                        {niveauList?niveauList.length !== 0 ? niveauList.map((niv) => (
                                                            <option value={niv.id}>{niv.name}</option>)) : "Loading..":"loading.."}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label>Option <span className="login-danger">*</span></label>
                                                    <select className="form-control" value={option} onChange={(e) => setOption(e.target.value)} disabled={optionList.length === 0}>
                                                        <option>Select Option</option>
                                                        {optionList.length !== 0 ? optionList.map((opt) => (
                                                            <option value={opt.id}>{opt.name}</option>)) : "Loading.."}
                                                    </select>
                                                </div>
                                                <div className=" dont-have">
                                                     Have  you an invite  Code ? Hint it  <Link  onClick = {(e)=>{setInvite(true)}} to="#">Here</Link>
                                                </div>
                                                {invite === true && (
                                                    <>
                                                        <div className="form-group">
                                                            <label>Enter the code here <span className="login-danger">*</span></label>
                                                            <input className="form-control" type="text" value={code} onChange={(e)=>{setCode(e.target.value)}} />
                                                        </div>
                                                    </>
                                                ) }
                                            </>
                                        )}

                                        {step === 5 && (
                                            <>
                                            <div className="mb-4">
                                              <h5>Authentification informations</h5>
                                            </div>

                                                <div className="form-group">
                                                    <label>Email <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={email} onChange={handleEmailChange} />
                                                    <span className="profile-views"><i className="fas fa-envelope"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Phone <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="number" value={phoneNumber} onChange={handlePhoneNumberChange} />
                                                    <span className="profile-views"><i className="fas fa-solid fa-phone"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Profile Picture <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="file" onChange={handleProfilePictureChange} />
                                                   <div id="profile-img-container">
                                                      <Link to={`/uploaded/${imgUrl}`} target="blank">
                                                        <img src={`/uploaded/${imgUrl}`} alt="profile" width="15%"  onChange={handleChange}/>
                                                      </Link>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Password <span className="login-danger">*</span></label>
                                                    <input className="form-control pass-input" type="password" value={password} onChange={handlePasswordChange} />
                                                    <span className="profile-views feather-eye toggle-password"></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm password <span className="login-danger">*</span></label>
                                                    <input className="form-control pass-confirm" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                                                    <span className="profile-views feather-eye reg-toggle-password"></span>
                                                </div>
                                            </>
                                        )}

                                        <div className=" dont-have">
                                            Already Have a student Code ? <Link to="../login">Login</Link>
                                        </div>
                                        <div className="form-group mb-0" />

                                        <div className="text-center form-group mb-0   ">
                                            <button className="btn btn-primary  " style= {{'marginRight':'10px'}} type="button" onClick={handlePreviousStep} disabled={step <= 1}>
                                                Previous
                                            </button>
                                            <Link to="#signup-modal" {...(step === 5 ? { 'data-bs-toggle': 'modal', 'data-bs-target': '#signup-modal' } : {})}  className="btn btn-primary" type="button" style= {{'marginLeft':'10px'}} onClick={(e)=>handleNextStep(e)} disabled={(step === 1 && !(firstName.trim() !== '' && lastName.trim() !== '' && dateOfBirth.trim() != '' && placeOfBirth.trim() !== ''))|| (step === 4 && parentTel !=='' &&  parentName.trim() !== '' && parentEmail.trim() !== '') || (step === 2 && !(pays.trim() !== '' &&  ville.trim() !== '' )) || (step === 3 && niveau === '') || (step === 5 &&!(imgUrl.trim() !== '' && password.length !== 0 && confirmPassword === password && email.trim() !=='' && phoneNumber !== 0))}>
                                                {step === 5 ? 'Register' : 'Next'}
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                                <div id="signup-modal" class="modal fade" tabindex="-1" style={{"display": "none"}} aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                        <div class="modal-body">
                                            <div class="text-center mt-2 mb-4">
                                            <div class="auth-logo">
                                                <a href="#" class="logo logo-dark">
                                                <span class="logo-lg">
                                                    <img src="assets/img/logo.png" alt="" height="42"/>
                                                </span>
                                                </a>
                                            </div>
                                            </div>
                                            <div class="card">
                                                <div class="card-header">
                                                <h5 class="card-title">Response Message</h5>
                                                </div>
                                                <div class="card-body">
                                                <div class="clipboard copy-txt">
                                                <p class="otp-pass" > {statusMessage} <span style={{display:"none"}} id="paragraph-copy1"> {email} </span>.</p>
                                                <p class="mb-4">Please do not share it to anyone</p>
                                                <a class="mb-1 btn clip-btn btn-primary" href="javascript:;" data-clipboard-action="copy" data-clipboard-target="#paragraph-copy1"><i class="far fa-copy"></i> Copy to the clipboard</a>
                                                </div>
                                                </div>
                                                </div>
                                        
                                        
                                        </div>
                                        </div>
                                    </div>
                                    </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default StudentPreInscription;


/*import React, { useState } from "react";
import Axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import ENCRYPTION_KEY from './../../key'
import encryptTextWithKey from './../../utils'

function StudentPreInscription (){
    const [form1Valid, setForm1Valid] = useState(false)
    const [loginStatus, setLoginStatus] = useState('');
    const navigateTo = useNavigate();
    const [schoolList, setSchooList] = useState([]);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value)
    }

    const handleSchoolChange = async (event)=>{
        const response = await Axios.get(`https://${domainName}/api/school-details/${event.target.value}`, data)
            .then((res)=>{
                console.log(response.data);
            
            
        }).catch ((error)=> {
            console.log(error)
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if(firstName.trim() !== '' && lastName.trim()!== '' && password.length !== 0  && confirmPassword === password){
            setForm1Valid(true);
        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: CryptoJS.AES.encrypt(JSON.stringify(password), ENCRYPTION_KEY.ENCRYPTION_KEY, { mode: CryptoJS.mode.ECB }).toString(),
            confirmPassword: CryptoJS.AES.encrypt(JSON.stringify(confirmPassword), ENCRYPTION_KEY.ENCRYPTION_KEY, { mode: CryptoJS.mode.ECB }).toString()
        }
    }else{
        alert("Fill all inputs correctly !");
    }
        try {
            const response = await Axios.post('https://${domainName}/api/preinscription', data)
            console.log(response.data);
            
            
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(()=>{
        if(schools.length===0){
        Axios.get('https://${domainName}/api/school-list')
        then((res)=>{
            console.log(res);
            setSchooList(res.data.schools)
        })}
    }, [schools]);

    return (
        <>
            <div class="main-wrapper login-body">
                <div class="login-wrapper">
                    <div class="container">
                        <div class="loginbox">
                            <div class="login-left">
                                <img class="img-fluid" src="assets/img/login.png" alt="Logo" />
                            </div>
                             {form1Valid?(
                            <div class="login-right">
                                <div class="login-right-wrap">
                                    <h1>Sign Up</h1>
                                    <p class="account-subtitle">Enter your informations  to create your account</p>

                                    <form onSubmit={handleSubmit}>
                                        <div class="form-group">
                                            <label>First Name <span class="login-danger">*</span></label>
                                            <input class="form-control" type="text" value={firstName} onChange={handleFirstNameChange} />
                                            <span class="profile-views"><i class="fas fa-user-circle"></i></span>
                                        </div>
                                        <div class="form-group">
                                            <label>Last Name <span class="login-danger">*</span></label>
                                            <input class="form-control" type="text" value={lastName} onChange={handleLastNameChange} />
                                            <span class="profile-views"><i class="fas fa-user-circle"></i></span>
                                        </div>
                                        <div class="form-group">
                                            <label>Email <span class="login-danger">*</span></label>
                                            <input class="form-control" type="text" value={email} onChange={handleEmailChange} />
                                            <span class="profile-views"><i class="fas fa-envelope"></i></span>
                                        </div>
                                        <div class="form-group">
                                            <label>Password <span class="login-danger">*</span></label>
                                            <input class="form-control pass-input" type="password" value={password} onChange={handlePasswordChange} />
                                            <span class="profile-views feather-eye toggle-password"></span>
                                        </div>
                                        <div class="form-group">
                                            <label>Confirm password <span class="login-danger">*</span></label>
                                            <input class="form-control pass-confirm" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                                            <span class="profile-views feather-eye reg-toggle-password"></span>
                                        </div>
                                        <div class=" dont-have">Already Have a student Code ? <Link to="../login">Login</Link></div>
                                        <div class="form-group mb-0" />
                                        <button class="btn btn-primary btn-block" type="submit">Next</button>
                                    </form>
                                </div>
                               ):(

                                <div class="login-right-wrap">
                                    <h1>Sign Up</h1>
                                    <p class="account-subtitle">Enter your informations  to create your account</p>

                                    <form onSubmit={handleSubmit}>
                                        <div class="form-group">
                                            <label>School Name <span class="login-danger">*</span></label>
                                            <select class="form-control" value={school} onChange={handleSchoolChange}>
                                                {schoolList.length!==0? schoolList.map((sh)=>(
                                                <option value={sh.id}>{sh.schoolName}</option>)):"Loading.."}
                                            </select>
                                            <span class="select2-container select2-container--default select2-container--open" style="position: absolute; top: 1067.08px; left: 454.75px;">
                                                <span class="select2-dropdown select2-dropdown--below" dir="ltr" style="width: 398.25px;">
                                                    <span class="select2-search select2-search--dropdown select2-search--hide">
                                                        <input class="select2-search__field" type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" aria-controls="select2-0rns-results" aria-activedescendant="select2-0rns-result-9rzk-Select" />
                                                    </span>
                                                    <span class="select2-results">
                                                        <ul class="select2-results__options" role="listbox" id="select2-0rns-results" aria-expanded="true" aria-hidden="false">
                                                            <li class="select2-results__option select2-results__option--highlighted" id="select2-0rns-result-9rzk-Select" role="option" aria-selected="true" data-select2-id="select2-0rns-result-9rzk-Select">
                                                                Select
                                                            </li>
                                                            <li class="select2-results__option" id="select2-0rns-result-hq4w-1" role="option" aria-selected="false" data-select2-id="select2-0rns-result-hq4w-1">
                                                            A+
                                                            </li>
                                                            <li class="select2-results__option" id="select2-0rns-result-qvbt-2" role="option" aria-selected="false" data-select2-id="select2-0rns-result-qvbt-2">
                                                                O+
                                                            </li>
                                                            <li class="select2-results__option" id="select2-0rns-result-8i3l-3" role="option" aria-selected="false" data-select2-id="select2-0rns-result-8i3l-3">
                                                                B+
                                                            </li>
                                                            <li class="select2-results__option" id="select2-0rns-result-lvby-4" role="option" aria-selected="false" data-select2-id="select2-0rns-result-lvby-4">
                                                                AB+
                                                            </li>
                                                        </ul>
                                                    </span>
                                                </span>
                                            </span>
                                            <span class="profile-views"><i class="fas fa-user-circle"></i></span>
                                        </div>
                                        
                                        <button class="btn btn-primary btn-block" type="submit">Regiter</button>
                                    </form>
                                </div>
                                )}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default StudentPreInscription*/