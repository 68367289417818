import React, { swal, useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import Axios from 'axios';
import ENCRYPTION_KEY from '../../key'
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'

function BlogDetail(){

    const navigateTo = useNavigate();
    const [courses, setCourses] = useState([]);
    const [exams, setExams] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [userCode, setUserCode] = useState('');
  const [userInf, setUserInf] = useState([]);
  const [UE, setUE] = useState([]);

  const {ueId} = useParams();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  if(userCode === ''){
    try {
        const encryptedData = sessionStorage.getItem('userInfo');
        if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            if (decryptedData) {
            const userInfo = JSON.parse(decryptedData);
            setUserCode(userInfo.code);
            setUserInf(userInfo);
            }
        }else{
            alert("Page only permit to teachers and students");
            navigateTo('/login');
        }}catch(err){
            
        }
    }


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    function getCourse( ) {
        Axios.get(`https://${domainName}/api/ue-course/${ueId}`)
            .then((response) => {
            setCourses(response.data.courses);
            setUE(response.data.ue)
            })
            .catch((error) => {
            console.error(error);
        });
    }

    function getExams(){
        Axios.get(`https://${domainName}/api/exam-ue/${ueId}`)
          .then((response) => {
            setExams(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
    }

    function deleteCourse (idCourse){
        Axios.delete(`https://${domainName}/api/delete-course/${idCourse}`)
          .then((response) => {
            getCourse();
          })
          .catch((error) => {
            console.error(error);
          });
    } 

    

       useEffect(() => {
        if (UE.length === 0 || UE === undefined) {  
            getCourse();
        }
        if (exams.length === 0 || exams === undefined)
        {
            getExams();
        }

      },[]);
    return (

    <div className="main-wrapper">
        <NavBar />
        <SideBar />
        <div class="page-wrapper">
            <div class="content container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <div className="invoice-info">
                        <strong className="customer-text-one">{UE.studyUnit ? UE.studyUnit.name : "EU"}</strong>
                        <h6 className="invoice-name">Credit:</h6>
                        <p className="invoice-details invoice-details-two">
                            Lab Number: <br /> <br />
                            Material(s) Required: <br /> <br />
                            Description:
                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="invoice-info invoice-info2">
                        <strong className="customer-text-one">{UE.studyUnit ? UE.studyUnit.code : UE.name}</strong>
                        <h6 className="invoice-name"> {UE ? UE.credit : "EU"}</h6>
                        <p className="invoice-details">
                            {UE ? UE.hasLab : "EU"} <br/><br/>
                            {UE ? UE.materialsRequired : 'EU'} <br/><br/>
                            {UE ? UE.description : "EU"}
                        </p>
                       
                    </div>
                </div>
            </div>
                <div class="row">
                    <div class="col-md-9">
                        <ul class="list-links mb-4">
                            <li class="active"><h4 >COURSE CONTENT </h4></li>
                              
                        </ul>
                    </div>
                    {userInf && userInf.type && userInf.type === 'teacher' && (
                    <div class="col-auto text-end float-end ms-auto download-grp" title="Add documents or course content">
                       
                    <NavLink to={`/exam-add/${ueId}`}><a class="btn btn-primary " style={{"margin-right":"10px"}}><i class="fas fa-plus"></i>Exam </a></NavLink>
                    <NavLink to={`/course-add/${ueId}`}><a class="btn btn-primary" style={{"margin-right":"10px"}}><i class="fas fa-plus"></i> Course </a></NavLink>
                    <NavLink to={`/emploi-de-temp`}><a class="btn btn-primary"style={{"margin-right":"10px"}}><i class="fas fa-plus"> Time table</i> </a></NavLink>
                    <NavLink to={`/list-emploi`}><a class="btn btn-primary">View Time table</a></NavLink>


                    </div>)}
                    
                </div>  
                <div class="row card ">
                        <div class="card-header">
                            <h3>Examination's list </h3>
                        </div>
                <div class="table-responsive card-body " >
                    <table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                        <thead class="student-thread">
                            <tr>
                                <th>ID</th>
                                <th>Teacher Name</th>
                                <th>deadline</th>
                                <th>Duration</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {exams.map((exam, index)=>(
                            <tr
                            key = {index}
                            onClick={() => navigateTo(userInf.type === "student"? `/exam-show/${exam.id}`: `/exam-details/${exam.id}`)}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: hoveredIndex === index ? 'lightgray' : 'inherit',
                            }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}

                            >
                                <td>{exam.id}</td>
                                <td >
                                    <h2 class="table-avatar">
                                    <a>{exam.teacherCode}</a>
                                </h2>
                                </td>
                                <td>{new Date(exam.deadline).toLocaleDateString("en-FR", options)}</td>
                                <td>{exam.duration}</td>
                                <td class="text-end">
                                    <div class="actions">
                                    <NavLink to={userInf.type === "student"? `/exam-show/${exam.id}`: `/exam-details/${exam.id}`} class="btn btn-sm bg-success-light me-2"> <i class="feather-eye"></i></NavLink>          
                                
                                </div>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                <div class="row card ">
                        <div class="card-header">
                            <h3>Course list </h3>
                        </div>
                <div class="table-responsive card-body " >
                    {courses?courses.map((course)=>(
                    <div class="col-md-6 col-xl-4 col-sm-12 d-flex">
                        <div class="blog grid-blog flex-fill">
                            <div class="blog-image">
                                <Link to={`../../course-details/${course.id}`}><img class="img-fluid" src="../assets/img/pdf-default.png" alt="Post Image"/></Link>
                                
                            </div>
                            <div class="blog-content">
                                <ul class="entry-meta meta-item">
                                    <li>
                                        <div class="post-author">
                                            <a href="profile.html">
                                                {/*<img src="../assets/img/profiles/avatar-01.jpg" alt="Post Author"/>*/}
                                                <span>
                                                    <span class="post-title">{course && (course.title)}</span>
                                                    <span class="post-date"><i class="far fa-clock"></i> {course.uploadedDate}</span>
                                                </span>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                                <h3 class="blog-title">
                                    {course.files && course.files.length !== 0 ? 
                                        course.files.slice(0,3).map((fil) => (<a href={`/uploaded/${fil.url}`} target="_blank" rel="noopener noreferrer"> <img src="../assets/img/pdf-default-mini.png"  height="30em" /></a>)) :
                                        "no files attached" 
                                    }
                                    {course.files.length>3? `+ ${course.files.length-3} file(s)`:''}
                                </h3>
                                <p>{course.description}</p>
                            </div>
                            
                         {userInf && userInf.type && userInf.type === 'teacher' && (

                            <div class="row">
                                <div class="edit-options">
                                    <div class="edit-delete-btn">
                                        <a href="" class="text-success"><i class="feather-edit-3 me-1"></i> Edit course </a>
                                        <a href="javascript:void(0)" class="text-danger" data-bs-toggle="modal" data-bs-target="#deleteModal"><i class="feather-trash-2 me-1"></i> Delete</a>
                                    </div>
                                    <div class="modal fade contentmodal" id="deleteModal" tabindex="-1" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content doctor-profile">
                                                <div class="modal-header pb-0 border-bottom-0 justify-content-end">
                                                    <button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close"><i class="feather-x-circle"></i></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="delete-wrap text-center">
                                                        <div class="del-icon"><i class="feather-x-circle"></i></div>
                                                        <h2>Sure you want to delete</h2>
                                                        <div class="submit-section">
                                                            <a href="javascript:void(0)" onClick = {()=>{deleteCourse(course.id)}} class="btn btn-success me-2">Yes</a>
                                                            <a href="#" class="btn btn-danger" data-bs-dismiss="modal">No</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                    )):(
                        <div class="row">
                            <div class="col-md-12">
                                <h3>Loading courses in Progress ..</h3>
                            </div>
                        </div>
                    )}
                    
                   </div> 
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="pagination-tab  d-flex justify-content-center">
                            <ul class="pagination mb-0">
                                <li class="page-item disabled">
                                    <a class="page-link" href="#" tabindex="-1"><i class="feather-chevron-left mr-2"></i>Previous</a>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                <li class="page-item active">
                                    <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item"><a class="page-link" href="#">4</a></li>
                                <li class="page-item">
                                    <a class="page-link" href="#">Next<i class="feather-chevron-right ml-2"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    )}
    export default  BlogDetail;