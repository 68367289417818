import React, { useState, useEffect } from 'react';
import NavBar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import Axios from 'axios';
import ENCRYPTION_KEY from '../../key';
import CryptoJS from 'crypto-js'
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';


function Themes(idTheme) {

  const {id }  = idTheme ;
  const [userInf, setUserInf] = useState([]);
  const [theme, setTheme] = useState({});
  const [userCode, setUserCode] = useState('');
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');

  const navigate = useNavigate();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  if(userCode === ''){
    try {
        const encryptedData = sessionStorage.getItem('userInfo');
        if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            const userInfo = JSON.parse(decryptedData);
            setUserInf(userInfo);
            setUserCode(userInfo.code)

        }
        else
        {
          navigate("/login");
        }
    }catch(err){
          console.log(err);  
        }
    }



  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');
  
    // Fonction pour récupérer le thème selon l'ID
  const fetchTheme = async () => {
        try {
          // Récupérer le thème par son ID depuis l'API
          const response = await Axios.get(`https://${domainName}/api/themes-with-comments/${id}/${userInf.user.userId}`);
          setTheme(response.data);
        } catch (error) {
          console.error('Error fetching theme:', error);
        }
      };
  const saveComment = ()=>{
    if(response.trim() !== ''){
      Axios.post(`https://${domainName}/api/theme-comments-save`, {content:response, user_id:userInf.user.userId, theme_id:id, response_to:null})
      .catch((err)=>{
        console.log(err)
      })
      .then((res)=>{
        fetchTheme();
        setResponse('');
      })
    }
  }

  const deleteTheme = ()=>{
    Axios.delete(`https://${domainName}/api/theme-delete/${id}`)
    .catch((err)=>{
      console.log(err)
      setMessage(err.response.data.message)
    })
    .then((res)=>{
      navigate("/discussions-list");
    })
  }

   useEffect(() => {
    fetchTheme();
  }, [id]);
  

  return (
    

          <div className="row">
            <div className="col-sm-12">
              <div className="card">

              <div class="card-header">
                <h4 class="card-title">Theme:    {theme.title} {userInf && userInf.type && userInf.type === 'teacher'  ||  userInf.type === 'student' && theme.created_by === userInf.user.userId   &&(
                    <div class="col-auto text-danger text-end float-end ms-auto download-grp" title="Delete this thematic">
                       
                       <a href="#" class="btn btn-danger" onClick={()=>{deleteTheme()}}>Delete</a>
                    
                    </div>)}</h4>
              </div>
                
                  <div className="card-body">
                    {message && <h3 style={{color:"tomato"}}>{message}</h3>}
                  <p>{theme.description} </p>
                    <ul className="timeline">
                      {theme && theme.comments && theme.comments.length > 0 && theme.comments.map((comment, index)=>(
                      <li key={index} /* style={{"background-color":'#cbf9d838'}} */ className={comment.user_id !== userInf.user.userId ? "timeline-inverted":""}style={{"background-color":comment.user_id !== userInf.user.userId ?'#e5f9ff61':'#cbf9d838'}}>
                        <div className="avatar avatar-sm timeline-badge"  style={comment.user_id !== userInf.user.userId? {marginRight:"10px",left:"2%"}:{}}  >
                          <img className="avatar-img rounded-circle" style={comment.user_id !== userInf.user.userId ? {left:"2%"}:{}} src={`/uploaded/${comment.profilePicture1? comment.profilePicture1 : comment.profilePicture}`}/>

                        </div>
                        <div className="timeline-panel">
                          <div className="timeline-heading">
                            <h4 className="timeline-title ml-2" style={comment.user_id !== userInf.user.userId ? {marginLeft:"10px",}: {marginRight:"10px",float:"right"}}>{comment.firstname ? `${comment.firstname} ${comment.lastname}`: comment.fullname}</h4><br/>
                          </div>
                          <div className="timeline-body">
                            <p>{comment.content}
                            <div className="modal-footer">
                              <p><span className="menu-title">{ new Date(comment.created_at).toLocaleDateString("en-FR", options)} </span></p>
                            </div></p>
                          </div>
                        </div>
                      </li>
                      ))}

                      {theme &&  (theme.description && theme.comments) ?  theme.comments.length === 0 && (userInf.type !== "teacher" || theme.created_by !== userInf.user.userId)  ? (<li><h3 style={{color:"tomato"}}> You need to respond to see responses of others persons</h3></li>): theme && theme.comments && theme.comments.length === 0 &&  (<li><h3 style={{color:"tomato"}}> No answerfor your post yet !</h3></li>):(<li><h3 style={{color:"GrayText"}}> You need to select a thematik in right  side</h3></li>)}

                     

                    </ul>
                    <div class="col-lg-12 col-md-12 mt-4">
                      <div class="form-group">
                        <textarea  onKeyDown={(e)=>{if(e.key === "Enter" && !e.ctrlKey && !e.shiftKey && !e.altKey){saveComment()}}}className="form-control" value={response} onChange={(e)=>{setResponse(e.target.value)}}></textarea>
                      </div>
                       <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={()=>{saveComment()}}>Send</button>
                      </div>
                    </div>
                  </div>

              </div>
            </div>
          </div>
  );
}

export default Themes;