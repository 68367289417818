import React, { useState, useEffect } from "react"
import Axios from "axios";
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
import { Link,  useParams, useNavigate } from 'react-router-dom';



const CryptoJS = require('crypto-js');


function TeacherAdd() {

    const [teacherCode, setTeacherCode] = useState('');
    const [teacherNom, setTeacherNom] = useState('');
    const [teacherTelephone, setTeacherTelephone] = useState('');
    const [teacherDatePriseService, setTeacherDatePriseService] = useState('');
    const [teacherPassword, setTeacherPassword] = useState('');
    const [AddStatus, setAddStatus] = useState('');
    const [userInfo, setUserInfo] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const {departmentId} = useParams();
    const navigate = useNavigate()


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    const createTeacher = (e) => {
        e.preventDefault();

        const  ciphed  = encryptTextWithKey(teacherPassword, ENCRYPTION_KEY.ENCRYPTION_KEY ) ;
        console.log("ciphed: " + ciphed);


        const data = {
            departmentId:departmentId?departmentId:'null',
            TeacherCode: teacherCode,
            TeacherNom: teacherNom,
            TeacherTelephone: teacherTelephone,
            TeacherDatePriseService: teacherDatePriseService,
            TeacherPassword: ciphed //cipher1.update(, 'utf8', 'hex') + cipher1.final('hex') //CryptoJS.AES.encrypt(JSON.stringify(teacherPassword), ENCRYPTION_KEY.ENCRYPTION_KEY,  { mode: CryptoJS.mode.ECB }).toString(),
        }
        console.log(data, 'key: ' +  ENCRYPTION_KEY.ENCRYPTION_KEY);
        Axios.post(`https://${domainName}/api/teacher-add`, data).then((response) => {
            setTeacherCode('');
            setTeacherNom('');
            setTeacherTelephone('');
            setTeacherDatePriseService('');
            setTeacherPassword('');

            alert(`The teacher has been created, now it ready to log in!`);
            navigate(`../teacher-list`)
            //navigate(`../department-details/${departmentId}`)
        }).catch((err)=>{
            console.error(err);
        })
    }
    useEffect(()=>{
        try {
            const encryptedData = localStorage.getItem('userInfo');
            if (encryptedData) {
              const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
              if (decryptedData) {
                const userInfo = JSON.parse(decryptedData);
                console.log("decryptDate:", decryptedData);
                setUserInfo(userInfo);
                setIsLoggedIn(true);
                }
            }}catch(err){
                
            }
    });

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Add Teachers </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">Teacher Basics Informations</h5>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Hit the username here or code :</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input name="username" type="text" class="form-control input-height" placeholder="tom-teacher" aria-describedby="Teacher Username"
                                                onChange={(event) => setTeacherCode(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>
                                        

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Hit the Full Name Of The teacher here:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="NDOGMO Thomas" aria-describedby="The Full Name of the Teacher"
                                                onChange={(event) => setTeacherNom(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Hit The Phone Number Of The teacher here:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="number" id="phoneExt" class="form-control input-height" placeholder="+237 ... .. ..." aria-describedby="The Full Name of the Teacher"
                                                onChange={(event) => setTeacherTelephone(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>


                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">First Service's Day</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="date"  class="form-control input-height" placeholder="" aria-describedby="The of First Day Class"
                                                onChange={(event) => setTeacherDatePriseService(event.target.value)}  required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Hit The password </label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="password" class="form-control input-height" placeholder="" aria-describedby="The Full Name of the Teacher"
                                                onChange={(event) => setTeacherPassword(event.target.value)} required />
                                            </div>
                                         </div>
                                    </div>


                                        

                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-outline-info btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                    <div class="alert alert-info alert-dismissible fade show" role="alert">
                                             <strong>Messages:</strong> {AddStatus}
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>

                                    </div>

                                    <div id="toast-container" class="toast-container toast-top-right"><div class="toast toast-info" aria-live="polite" Style={{"display": "block"}}><div class="toast-title">Sticky!</div><div class="toast-message">I do not think that word means what you think it means.</div></div></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default TeacherAdd




















/*import React, { useState } from "react"
import Axios from "axios";
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'

function TeacherAdd() {

    const [teacherCode, setTeacherCode] = useState('');
    const [teacherNom, setTeacherNom] = useState('');
    const [teacherSexe, setTeacherSexe] = useState('');
    const [teacherDateNaissance, setTeacherDateNaissance] = useState('');
    const [teacherTelephone, setTeacherTelephone] = useState('');
    const [teacherDatePriseService, setTeacherDatePriseService] = useState('');
    const [teacherDiplome, setTeacherDiplome] = useState('');
    const [teacherSpecialite, setTeacherSpecialite] = useState('');
    const [teacherEmail, setTeacherEmail] = useState('');
    const [teacherPassword, setTeacherPassword] = useState('');
    const [teacherVille, setTeacherVille] = useState('');
    const [teacherRegion, setTeacherRegion] = useState('');
    const [teacherPays, setTeacherPays] = useState('');
    const [teacherPhoto, setTeacherPhoto] = useState('');
    const [AddStatus, setAddStatus] = useState('');


    const createTeacher = (e) => {
        e.preventDefault()
        Axios.post('https://${domainName}/api/teacher-add', {
            TeacherCode: teacherCode,
            TeacherNom: teacherNom,
            TeacherSexe: teacherSexe,
            TeacherDateNaissance: teacherDateNaissance,
            TeacherTelephone: teacherTelephone,
            TeacherDatePriseService: teacherDatePriseService,
            TeacherDiplome: teacherDiplome,
            TeacherSpecialite: teacherSpecialite,
            TeacherEmail: teacherEmail,
            TeacherPassword: teacherPassword,
            TeacherVille: teacherVille,
            TeacherRegion: teacherRegion,
            TeacherPays: teacherPays,
            TeacherPhoto: teacherPhoto

        }).then(() => {
            setTeacherCode('');
            setTeacherNom('');
            setTeacherSexe('');
            setTeacherDateNaissance('');
            setTeacherTelephone('');
            setTeacherDatePriseService('');
            setTeacherDiplome('');
            setTeacherSpecialite('');
            setTeacherEmail('');
            setTeacherPassword('');
            setTeacherVille('');
            setTeacherRegion('');
            setTeacherPays('');
            setTeacherPhoto('');

            setAddStatus(`Enseignant non existant`)
        })
    }
    const [image, setImage]= useState (null)
        const handleImage = (e) =>{
            const file = e.target.files[0]
            setImage(URL.createObjectURL(file))
        }


  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Ajout des Enseignants</h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                        <div className="col-12">
                                            <h5 className="form-title"><span>Détails basiques</span></h5>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>ID Enseignants <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="ID Enseignants" 
                                                    onChange={(event) => setTeacherCode(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Nom et prénom <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Nom et prénom" 
                                                    onChange={(event) => setTeacherNom(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Genre <span className="login-danger">*</span></label>
                                                <select className="form-control select"
                                                    onChange={(event) => setTeacherSexe(event.target.value)}>
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                    <option>Others</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms calendar-icon">
                                                <label>Date de naissance<span className="login-danger">*</span></label>
                                                <input className="form-control datetimepicker" type="text" placeholder="DD-MM-YYYY" 
                                                    onChange={(event) => setTeacherDateNaissance(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Téléphone <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Téléphone" required
                                                    onChange={(event) => setTeacherTelephone(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms calendar-icon">
                                                <label>Date de début <span className="login-danger">*</span></label>
                                                <input className="form-control datetimepicker" type="text" placeholder="DD-MM-YYYY" 
                                                    onChange={(event) => setTeacherDatePriseService(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4 local-forms">
                                            <div className="form-group">
                                                <label>Diplôme <span className="login-danger">*</span></label>
                                                <input className="form-control" type="text" placeholder="votre diplôme" 
                                                    onChange={(event) => setTeacherDiplome(event.target.value)} /> 
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Specialité <span className="login-danger">*</span></label>
                                                <input className="form-control" type="text" placeholder="Votre spécialité"
                                                    onChange={(event) => setTeacherSpecialite(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <h5 className="form-title"><span>Détails de connexion</span></h5>
                                        </div>


                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>ID Email <span className="login-danger">*</span></label>
                                                <input type="email" className="form-control" placeholder="ID Email"
                                                    onChange={(event) => setTeacherEmail(event.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                                <div className="col-12 col-sm-4">
                                                    <div className="form-group local-forms">
                                                        <label>Photo <span className="login-danger">*</span></label>
                                                        <input type="file" className="form-control" onChange={handleImage} />
                                                    </div>
                                                </div>
                                                {image && (
                                                    <div>
                                                    <h3>Image sélectionnée :</h3>
                                                    <img src={image} alt="Selected" style={{ maxWidth: '200px' }} />
                                                    </div>
                                                )}
                                            </div>
                                           

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Password <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Enter Password"
                                                    onChange={(event) => setTeacherPhoto(event.target.value)} />
                                            </div>
                                        </div>



                                        <div className="col-12">
                                            <h5 className="form-title"><span>Address</span></h5>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Ville <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Ville"
                                                    onChange={(event) => setTeacherVille(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Région <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Région"
                                                    onChange={(event) => setTeacherRegion(event.target.value)} />
                                            </div>
                                        </div>

                                        
                                        <div className="col-12 col-sm-4">
                                            <div className="form-group local-forms">
                                                <label>Pays <span className="login-danger">*</span></label>
                                                <input type="text" className="form-control" placeholder="Pays"
                                                    onChange={(event) => setTeacherPays(event.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="btn btn-primary" onClick={createTeacher}>Valider</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default TeacherAdd*/