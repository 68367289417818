import React, { useState } from 'react';
import Axios from 'axios';
import CryptoJS from 'crypto-js';
import ENCRYPTION_KEY from './../../key'
import encryptTextWithKey from './../../utils'
import { useNavigate, Link } from 'react-router-dom';

const ParentForm = () => {
  const [userData, setUserData] = useState({
    fullname: '',
    email: '',
    phoneNumber: '',
    sexe: '',
    role: '',
    password:'',
    userId: null,
  });

  const [step, setStep] = useState(1);
  const [image, setImage] = useState(null);
  const [cpassword, setCpassword] = useState('');
  const [search, setSearch] = useState('');
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);

  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    if (e.target.value.trim() === '') {
      setStudents([]);
      return;
    }
    // Call the search API with Axios when the search term changes
    Axios.get(`https://${domainName}/api/search-student?searchTerm=${e.target.value}`)
      .then((response) => {
        // Update the students list with the search results
        console.log(response.data);
        setStudents(response.data);
      })
      .catch((error) => {
        console.error('Error fetching students:', error);
        setStudents([]); // Reset the list in case of error
      });
  };

  const handleStudentSelect = (student) => {
    const updatedSelectedStudents = [...selectedStudents, student];
    setSelectedStudents(updatedSelectedStudents);
    setSearch('');
    setStudents([]);
  };

  const handleRemoveStudent = (student) => {
    const updatedSelectedStudents = selectedStudents.filter((selectedStudent) => selectedStudent.id !== student.id);
    setSelectedStudents(updatedSelectedStudents);
  };

 const handleSubmit = (e) => {
  e.preventDefault();
  if(step!==3){
    setStep(step+1);
    return;
  }
   if(cpassword!==userData['password'] || userData['password'].length<4){
    alert("The password must have least of 4 caracter or verified your confirm password !")
    return;
  }
  // Create a new FormData object to send the data as multipart/form-data
  const formData = new FormData();

  // Add the user data to the formData object
  for (let key in userData) {
    if(key !== 'password'){
    formData.append(key, userData[key]);
  }}

  // Add the image file to the formData object
  if (image) {
    formData.append('image', image);
  }

  const pass = encryptTextWithKey(cpassword,ENCRYPTION_KEY.ENCRYPTION_KEY )

    formData.append('password', pass);

    for (const student of selectedStudents) {
  formData.append('selectedStudents', student.matricule);
}

  console.log(formData);
  // Send the formData object to the backend
  Axios.post(`https://${domainName}/api/parent-register`, formData)
    .then((response) => {
      console.log('Registration success:', response.data);
      // Move to the next step
      setStep(-1);
    })
    .catch((error) => {
      console.error('Registration error:', error);
    });
};

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <div className="loginbox">
              <div className="login-left">
                <img className="img-fluid" src="/assets/img/download.png" alt="Logo" />
              </div>
              <div className="login-right">
                <div className="login-right-wrap">
                  <h1>
                    <a href="/" class="logo" style={{ marginBottom: '15px' }}>
                      <img src="/assets/img/logo.png" alt="Logo" />
                    </a>
                    <br />
                    Sign Up
                  </h1>
                  <p className="account-subtitle">Enter your information to create your parent account</p>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title mb-0">Fill Inputs</h4>
                        </div>
                        <div className="card-body">
                          <div className="tab-content twitter-bs-wizard-tab-content">
                            {/* Step 1 */}
                            {step === 1 && (
                              <div className="tab-pane active" id="progress-seller-details">
                                <div className="mb-4">
                                  <h5>Your information</h5>
                                </div>
                                <form onSubmit={handleSubmit}>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="fullname"
                                      placeholder="Full Name"
                                      value={userData.fullname}
                                      onChange={handleInputChange}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      placeholder="Email"
                                      value={userData.email}
                                      onChange={handleInputChange}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="tel"
                                      className="form-control"
                                      name="phoneNumber"
                                      placeholder="Phone Number"
                                      value={userData.phoneNumber}
                                      onChange={handleInputChange}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <select
                                      className="form-select form-control"
                                      name="sexe"
                                      placeholder="Sex"
                                      value={userData.sexe}
                                      onChange={handleInputChange}
                                    >
                                      <option>-- Sex --</option>
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                      <option value="Other">Other</option>
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label>Profile Picture</label>
                                    <input className="form-control" type="file" onChange={(e) => setImage(e.target.files[0])} />
                                  </div>
                                  <button type="submit" className="btn btn-primary" style={{marginRight:'40px'}}>
                                    Next
                                  </button>
                                </form>
                              </div>
                            )}

                            {/* Step 2 */}
                            {step === 2 && (
                              <div className="tab-pane active" id="progress-company-document">
                                <div className="mb-4">
                                  <h5>Role and Student(s)</h5>
                                </div>
                                <form onSubmit={handleSubmit}>
                                  <div className="form-group">
                                    <label className="login-danger">Your child Name</label>
                                    <input
                                      type="text"
                                      className="form-control "
                                      name="role"
                                      placeholder="name"
                                      value={search}
                                      onChange={handleSearchChange}
                                    />
                                    {students.length > 0 && (
                                      <div className="notification-list" style={{
                                            border: "1px solid #ddd",
                                            "box-shadow": "none",
                                            color: "#333",
                                            "font-size": "15px",
                                            maxHeight:" 200px",
                                            borderTop:'0',
                                            overflow:"hidden"

                                        }}>
                                        {students.map((student) => (
                                         <option className="notification-msg" key={student.id}   onClick={() => handleStudentSelect(student)}> 
                                         <div >
                                            {`${student.firstname } ${student.lastname}  of ${student.placeOfBirth }`}
                                          </div>
                                          </option>
                                        ))}
                                      </div>
                                    )}
                                    {selectedStudents.length > 0 && (
                                      <div className="selected-students">
                                        <label>Students Selected: </label>
                                        <ul className="mt-2">
                                          {selectedStudents.map((selectedStudent) => (
                                            <li key={selectedStudent.id} className="mt-2" >
                                              {`${selectedStudent.firstname } ${selectedStudent.lastname}`}
                                              <button style={{marginLeft:"10px"}} className="btn btn-warning" onClick={() => handleRemoveStudent(selectedStudent)} className="">Remove</button>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="role"
                                      placeholder="Role"
                                      value={userData.role}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  {/* Other fields here... */}
                                  
                                  <button onClick={()=>{setStep(1)}} className="btn btn-primary" style={{marginRight:'40px'}}>
                                    Previous
                                  </button>
                                  <button type="submit" className="btn btn-primary ml-2">
                                    Next
                                  </button>
                                </form>
                              </div>
                            )}

                            {/* Step 3 */}
                            {step === 3 && (
                              <div className="tab-pane active" id="progress-bank-detail">
                                <div className="mb-4">
                                  <h5>Create a password</h5>
                                </div>
                                <form onSubmit={handleSubmit}>
                                  {/* Validating information */}
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      name="password"
                                      placeholder=""
                                      value={userData.password}
                                      onChange={handleInputChange}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Confirm password</label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      name="cpassword"
                                      placeholder=""
                                      value={cpassword}
                                      onChange={(e)=>{setCpassword(e.target.value)}}
                                      required
                                    />
                                  </div>
                                 <button onClick={()=>{setStep(2)}} className="btn btn-primary " style={{marginRight:'40px'}}>
                                    Previous
                                  </button>
                                  <button type="submit" className="btn btn-primary">
                                    Save Changes
                                  </button>
                                </form>
                              </div>
                            )}
                            {step === -1 && (
                              <div className="tab-pane active" id="progress-bank-detail">
                                <div className="mb-4">
                                  <h5>informations:</h5>
                                </div>
                                <p className="text-success">Your informations have been registed successfully!</p>
                                 <div className=" dont-have">
                                           Now you can login and stay safe <Link to="../login">Login</Link>
                                        </div>
                              </div>
                            )}
                            <div className=" dont-have">
                                Already Have a student Code ? <Link to="../login">Login</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentForm;