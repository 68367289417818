import React, { useState, useEffect } from 'react';
import NavBar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import Axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';

function OptionDetails() {

  const [option, setoption] = useState({studyUnit: [], options: []});
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const {id}  = useParams()

  const navigate = useNavigate();


  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');
  

  useEffect(() => {
    Axios.get(`https://${domainName}/api/option-details/${id}`)
      .then((response) => {
        console.log(response.data);
        setoption(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

  },[id]);
  
  const displaystudyUnit = () => {
    if(!option.studyUnits) {
      return (
        <>
          This option has no studyUnits yet, <Link to={option.option? `/study-unit-add/${option.option.niveauId}/${option.option.id}`:'#'}>Add one</Link>
        </>
      );
    } else {
      return (
        option.studyUnits.map((studyUnit, index) => (
          <tr
            key={index}
            onClick={() => navigate(`/study-unit-details/${studyUnit.id}`)}
            style={{ cursor: 'pointer', backgroundColor: hoveredIndex === index ? 'lightgray' : 'inherit'}}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <td>{studyUnit.id}</td>
            <td>{studyUnit.code}</td>
            <td>{studyUnit.name}</td>
            <td>{studyUnit.credit}</td>
            <td>{studyUnit.description}</td>
            <td>{studyUnit.optional ? 'Yes' : 'No'}</td>
            <td>{studyUnit.HoursPerWeek}</td>
          </tr>
        ))
      );
    }
  }

  
  return (
    <div className="main-wrapper">
      <NavBar />
      <SideBar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Option: {option.option ? option.option.name : ''}</h3>
                <ul className="breadcrumb"></ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                
                <div className="card-body">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="row">
                      <div class="page-header">
                        <div class="row align-items-center">
                          <div class="col">
                            <h3 class="page-title">All studyUnits: <span className='text-blue'>{option.studyUnit ? option.studyUnit.length : 0}</span></h3>
                          </div>
                              <div class="col-auto text-end float-end ms-auto download-grp">
                                <a href="#"  class="btn btn-outline-primary me-2"><i class="fas fa-download"></i> Download</a>
                                 <Link to={option.option? `/study-unit-add/${option.option.niveauId}/${option.option.id}`:'#'} title="Add a new studyUnits for this option" class="btn btn-primary"><i class="fas fa-plus"></i></Link>
                                 <Link to={option.option? `/option-update/${option.option.id}/`:'#'} title="Upddate this option" class="btn btn-primary"><i class="fas fa-edit"></i></Link>
                              </div>
                            </div>
                          </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <label>
                              Show{' '}
                              <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>{' '}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6"></div>
                      </div>
                      <div className="row">
                          <div className="col-sm-12">
                            <table className="datatable table table-stripped dataTable no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                              <thead>
                                <tr role="row">
                                  <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '155.797px' }}>
                                    Id
                                  </th>
                                  <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '155.797px' }}>
                                    Code
                                  </th>

                                  <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '155.797px' }}>
                                    study Units Name
                                  </th>
                                  <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{ width: '48.3594px' }}>
                                    Credit
                                  </th>
                                  <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{ width: '48.3594px' }}>
                                    Description
                                  </th>
                                  <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{ width: '48.3594px' }}>
                                    Optional
                                  </th>
                                  <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{ width: '48.3594px' }}>
                                    Hours Per Week
                                  </th>

                                </tr>
                              </thead>
                              <tbody>
                                {displaystudyUnit()}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5"></div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="DataTables_Table_0_paginate"
                          >
                            <ul className="pagination">
                              <li
                                className="paginate_button page-item previous disabled"
                                id="DataTables_Table_0_previous"
                              >
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="0"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  Previous
                                </a>
                              </li>
                              <li className="paginate_button page-item active">
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="1"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  1
                                </a>
                              </li>
                              <li className="paginate_button page-item ">
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="2"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  2
                                </a>
                              </li>
                              <li className="paginate_button page-item next" id="DataTables_Table_0_next">
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="3"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  Next
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>



                       
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OptionDetails;