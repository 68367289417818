import React, { useState, useEffect } from "react"
import Axios from "axios";
import {  useParams, useNavigate } from 'react-router-dom';


import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'

function NiveauUpdate() {
    const [niveau, setNiveau] = useState([]);
    const [niveauName, setniveauName] = useState('');
    const [niveauDescription, setniveauDescripton] = useState('');
    const [AddStatus, setAddStatus] = useState('');


    const {id}  = useParams()

    let navigate = useNavigate();


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');
    
    const createTeacher = (e) => {
        e.preventDefault();

        


        const data = {
            id:niveau.id,
            name: niveauName,
            description:niveauDescription,
        }
        
        Axios.post(`https://${domainName}/api/niveau-add/${id}`, data).then(() => {
            setniveauName('');
            setniveauDescripton('');


            setAddStatus(`The niveau has been created!`)
            navigate(`/niveau-details/${id}`)
        })
    }
    useEffect(()=>{
        if(niveau.length===0) {
            Axios.get(`https://${domainName}/api/niveau-details/${id}`)
                .then((response) => {
                  console.log(response.data);
                  setNiveau(response.data.niveau);
                })
                .catch((error) => {
                  console.error(error);
                });
          }
      
          if (niveau.length !== 0 && niveauName.trim() === '') {
            const facultyDetails = niveau;
            setniveauName(facultyDetails.name);
            setniveauDescripton(facultyDetails.description ? facultyDetails.description : '');
          }

    });
  

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Update niveau </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">niveau Informations</h5>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The niveau's Name:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="niveau Name" value={niveauName}
                                                onChange={(event) => setniveauName(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>
                                        

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">niveau Description</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="niveau Address" value={niveauDescription}
                                                onChange={(event) => setniveauDescripton(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    

                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default NiveauUpdate