import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import ENCRYPTION_KEY from '../../key';
import CryptoJS from 'crypto-js';
import {  useNavigate } from 'react-router-dom';

const CardComponent = (data) => {
  const [cardList, setCardList] = useState([]);
  const [userInf, setUserInf] = useState([]);
  const [userCode, setUserCode] = useState('');

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };




  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');


  const navigateTo = useNavigate();

    
  const fetchData = async () => {
    try {
       await Axios.get(`https://${domainName}/api/parent-alerts/${data.userId}`)
      .then((response)=>{

        setCardList(response.data);
      console.log(response.data);
      })
      .catch((err)=>{
          console.log(err);
      })
    } catch (error) {
      console.log(error);
    }
  };
  function getUserInf(){
    if(userCode === ''){
   try {
       const encryptedData = sessionStorage.getItem('userInfo');
       if (encryptedData) {
         const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
         if (decryptedData) {
           const userInfo = JSON.parse(decryptedData);                           
               setUserCode(userInfo.code);
               setUserInf(userInfo);
               console.log(userInfo);
       }}else{
           alert("Your're not allow to get this page !");
           navigateTo('/login');
       }}
       catch(err){
           console.log(err)
       }
   }
}


useEffect(()=>{
if (userCode==='') {
getUserInf()
}
fetchData();
},[userInf])

  return (
    <div className="row">
      {cardList.length > 0 ? cardList.map((card, index) => (
        <div key={index} className="col-12 d-flex">
          <div className="card flex-fill bg-white">
            <div className="card-header">
              {card.niveauName + card.ueName || 'Default Header'}
            </div>
            <div className="card-body">
              <h5 className="card-title">Exam results:</h5>
              <p className="card-text">
                The teacher {card.teacherName} has proposed an examination. {card.message || 'Default Content'}.<br/> Then he/she has {card.note}
                
              </p>
            </div>
            <div className="card-footer text-muted">
              Available since { new Date(card.created_at).toLocaleDateString("en-FR", options) || 'Default Footer'}
            </div>
          </div>
        </div>
      )):(
        <h3>{cardList.message? cardList.message : "Nothing to show for you"}</h3>
      )}
    </div>
  );
};

export default CardComponent;
