import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import ENCRYPTION_KEY from './../../key'
import encryptTextWithKey from './../../utils'

function TeacherShortInscription() {
    const [form1Valid, setForm1Valid] = useState(false)
    const [form2Valid, setForm2Valid] = useState(false)
    const [form3Valid, setForm3Valid] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [imgUrl, setImgUrl] = useState('#');
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [sexe, setSexe] = useState('');
    const [pays, setPays] = useState('');
    const [region, setRegion] = useState('')
    const [ville, setVille] = useState('')
    const [district, setDistrict] = useState('')
    const [classi, setClass] = useState('')
    const [something, setSomething] = useState('')
    const [objectives, setObjectives] = useState('')
    const [gpa, setGpa] = useState('');
    const [statusMessage, setStatuMessage] = useState('');
    const [loginStatus, setLoginStatus] = useState('');
    const [step, setStep] = useState(4);
    const navigateTo = useNavigate();
    const [savedFormData, setSavedFormData] = useState([]);


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
    }


    const handleSexeChange = (event) => {
        setSexe(event.target.value)
    }

 

    const handleNextStep = (e) => {
        if (step === 1 && firstName.trim() !== '' && lastName.trim() !== '' && dateOfBirth !== '' && placeOfBirth !== '' && sexe !== '') {
            setForm1Valid(true);
            setStep(step + 1);
        } else if (step === 2 && pays.trim() !== '' && region.trim() !== '' && ville.trim() !== '' && district.trim() !=='') {
            setForm2Valid(true);
            setStep(step + 1);
        } else if (step === 3 && classi !== ''  ) {
            setForm3Valid(true);
            setStep(step + 1);
        }else if( step === 4  )
        {
            handleSubmit(e);
        }
    }

   

    const handleSubmit = async (event) => {

        function copyToClipboard(text) {
            // Crée un élément textarea temporaire
            const textarea = document.createElement('textarea');
            // Assigne le texte à l'élément textarea
            textarea.value = text;
            // Ajoute l'élément textarea à la fin du corps du document
            document.body.appendChild(textarea);
            // Sélectionne tout le texte dans l'élément textarea
            textarea.select();
            // Exécute la commande de copie
            document.execCommand('copy');
            // Supprime l'élément textarea temporaire
            document.body.removeChild(textarea);
          }
        event.preventDefault()
      
            const data  ={
                firstName: firstName,
                lastName: lastName,
                email: email,
                dateOfBirth: dateOfBirth,
                sexe: sexe,
                pays: pays,
                region: region,
                ville: ville,
                address:district,
                imgUrl:imgUrl,
                placeOfBirth:placeOfBirth,
                phoneNumber: phoneNumber,
                classi: classi,
                objectives:objectives,
                something:something,
                password: encryptTextWithKey(password,ENCRYPTION_KEY.ENCRYPTION_KEY )

            };

            try {
                const response = await Axios.post(`https://${domainName}/api/teacher-inscription`, data)
                console.log(response.data, "ce code sera copie dans votre presse passiers");
                setStatuMessage(response.data.msg)
                //alert(response.data.msg);
                copyToClipboard(response.data.code)
                setFirstName('');
                setLastName('');
                setEmail('');
                setPassword('');
                setConfirmPassword('');
                setObjectives('')
                setClass('')
                setSomething('')
                setDateOfBirth('');
                setDistrict('');
                setPlaceOfBirth('');
                setPhoneNumber('');
                setSexe('');
                setPays('');
                setRegion('');
                setVille('');
                setGpa('');
                sessionStorage.removeItem('formData');
               // navigateTo(`/login`);
            } catch (error) {
                console.log(error)
            }
       

    }

   useEffect(() => {

    const savedData = sessionStorage.getItem('formData');
    if (savedData) {
        console.log(savedData);
        setSavedFormData(JSON.parse(savedData));
        if(savedFormData.length !==0 && firstName === ''){
            setFirstName(savedFormData.firstName);
            setLastName(savedFormData.lastName);
            setEmail(savedFormData.email);
            setDateOfBirth(savedFormData.dateOfBirth);
            setSexe(savedFormData.sexe);
            setPays(savedFormData.pays);
            setRegion(savedFormData.region);
            setVille(savedFormData.ville);
            setObjectives(savedFormData.objectives);
            setSomething(savedFormData.something);
            setClass(savedFormData.classi);
            setDistrict(savedFormData.student.district);
            setPlaceOfBirth(savedFormData.placeOfBirth);
            setPhoneNumber(savedFormData.phoneNumber);
            setPassword(savedFormData.password);
            setConfirmPassword(savedFormData.confirmPassword);
            setImgUrl(savedFormData.imgUrl);
        }
    }
}, []);

    return (
        <>
            <div className="main-wrapper login-body">
                <div className="login-wrapper">
                    <div className="container">
                        <div className="loginbox">
                            <div className="login-left">
                                <img className="img-fluid" src="assets/img/download.png" alt="Logo" />
                            </div>
                            <div className="login-right">
                                <div className="login-right-wrap">
                                    <h1>Sign Up</h1>
                                    <p className="account-subtitle">Enter your informations to create your account</p>

                                    <form onSubmit={handleSubmit}>
                                        {step === 4 && (
                                            <>
                                                <div className="form-group">
                                                    <label>Full Name <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={firstName} onChange={handleFirstNameChange} />
                                                    <span className="profile-views"><i className="fas fa-user-circle"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Sexe <span className="login-danger">*</span></label>
                                                    <select className="form-control" value={sexe} onChange={handleSexeChange} disabled={false}>
                                                        <option value="">--------</option>
                                                        <option value="Male">Male<i className="fas fa-venus"></i></option>
                                                        <option value="Female">Female <i className="fas fa-mars"></i></option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    <span className="profile-views"><i className="fas fa-venus-mars"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Email<span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                                                    <span className="profile-views"><i className="fas fa-user-circle"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Password<span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={password} onChange={(e)=>{setPassword(e.target.value)}} />
                                                    <span className="profile-views"><i className="fas fa-user-circle"></i></span>
                                                </div>
                                                <div className="form-group">
                                                    <label>Your Class name <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text" value={classi} onChange={(e)=>{setClass(e.target.value)}} />
                                                    <span className="profile-views"><i className="fas fa-user-circle"></i></span>
                                                </div>
                                                
                                               
                                            </>
                                        )}

                                        <div className=" dont-have">
                                            Already Have a student Code ? <Link to="../login">Login</Link>
                                        </div>
                                        <div className="form-group mb-0" />

                                        <div className="text-center form-group mb-0   ">
                                            <Link to="#signup-modal" {...(step === 4 ? { 'data-bs-toggle': 'modal', 'data-bs-target': '#signup-modal' } : {})} className="btn btn-primary" type="button" style= {{'marginLeft':'10px'}} onClick={(e)=>handleNextStep(e)} disabled={ (step === 4 &&! (firstName.trim() !== ''  && email.trim() !=='' && classi.trim() !== 0))}>
                                                {step === 4 ? 'Register' : 'Next'}
                                            </Link>
                                        </div>
                                    </form>
                                </div>

                                <div id="signup-modal" class="modal fade" tabindex="-1" style={{"display": "none"}} aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                        <div class="modal-body">
                                            <div class="text-center mt-2 mb-4">
                                            <div class="auth-logo">
                                                <a href="#" class="logo logo-dark">
                                                <span class="logo-lg">
                                                    <img src="assets/img/logo.png" alt="" height="42"/>
                                                </span>
                                                </a>
                                            </div>
                                            </div>
                                        {statusMessage}
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TeacherShortInscription;
