import React, { useState, useEffect } from "react"
import Axios from "axios";
import {  useParams, useNavigate } from 'react-router-dom';


import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'


function FiliereUpdate() {
    const [filiere,  setFiliere] = useState([])
    const [FiliereName, setFiliereName] = useState('');
    const [FiliereDescription, setFiliereDescription] = useState('');
    const [directorEmail, setSetFiliereDirectorEmail] = useState('');
    const [FiliereCity, setFiliereCity] = useState('');
    const [FiliereAddress, setFiliereAddress] = useState('');
    const [FiliereLocation, setFiliereLocation] = useState('');
    const [FiliereCreationDate, setFiliereCreationDate] = useState('');
    const [FiliereWebSite, setFiliereWebSite ] = useState('')

    const [AddStatus, setAddStatus] = useState('');



    const {id}  = useParams()

    let navigate = useNavigate();


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');
    
    const createTeacher = (e) => {


        e.preventDefault();
        const dateObject = new Date(FiliereCreationDate);

        // Obtenez les composants de la date
        const year = dateObject.getUTCFullYear();
        const month = ("0" + (dateObject.getUTCMonth() + 1)).slice(-2);
        const day = ("0" + dateObject.getUTCDate()).slice(-2);

        // Obtenez les composants de l'heure
        const hours = ("0" + dateObject.getUTCHours()).slice(-2);
        const minutes = ("0" + dateObject.getUTCMinutes()).slice(-2);
        const seconds = ("0" + dateObject.getUTCSeconds()).slice(-2);

        // Format de date et heure pour MySQL (AAAA-MM-JJ HH:MM:SS)
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;


        const data = {
            id:filiere.id,
            name: FiliereName,
            address: FiliereAddress,
            city: FiliereCity,
            location:FiliereLocation,
            date: formattedDate ,
            website:FiliereWebSite,
            description:FiliereDescription,
        }
        
        Axios.post(`https://${domainName}/api/filiere-add/${id}`, data).then(() => {
            setFiliereName('');
            setFiliereAddress('');
            setFiliereCity('');
            setFiliereCreationDate('');
            setSetFiliereDirectorEmail('');
            setFiliereDescription('');


            setAddStatus(`The Filiere has been created!`)
            navigate(`/filiere-details/${id}`)
        })
    }
    useEffect(()=>{
        if(filiere.length===0) {
            Axios.get(`https://${domainName}/api/filiere-details/${id}`)
                .then((response) => {
                  console.log(response.data);
                  setFiliere(response.data.filiere);
                })
                .catch((error) => {
                  console.error(error);
                });
          }
      
          if (filiere.length !== 0 && FiliereName.trim() === '') {
            const facultyDetails = filiere;
            setFiliereName(facultyDetails.name);
            setFiliereCreationDate(facultyDetails.creationDate);
            setFiliereDescription(facultyDetails.description ? facultyDetails.description : '');
          }

    });

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Update Filiere </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">Filiere Informations</h5>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Filiere's Name:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Filiere Name" value={FiliereName}
                                                onChange={(event) => setFiliereName(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>


                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Filiere Description</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Filiere Address" value={FiliereDescription}
                                                onChange={(event) => setFiliereDescription(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Creation's Date</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1"># ({FiliereCreationDate})</span>
                                                <input type="date"  class="form-control input-height" placeholder="" aria-describedby="The of First Day Class" value={FiliereCreationDate}
                                                onChange={(event) => setFiliereCreationDate(event.target.value)}  required/>
                                            </div>
                                         </div>
                                    </div>

                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default FiliereUpdate;