import React, { useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import Axios from 'axios'

function TeacherList() {


    const [teacherList, setTeacherList] = useState([]);


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');


    const fetchData = async () => {
              try {
                const response = await Axios.get(`https://${domainName}/api/teacher-list`);
                setTeacherList(response.data);
                console.log(response.data);
                //alert("bien le b0njour");
              } catch (error) {
                console.log(error);
              }
            };
    const deleteTeacher = (code)=>{
        Axios.get(`https://${domainName}/api/teacher-delete/${code}`)
        .then((res)=>{
            alert("delete Sucessfully");
            console.log("Ok");
           fetchData()
        })
        .catch((err)=>{ 
            console.log("Une erreur s'est produite:", err);
        })

        
}
    useEffect(() => {
        
        fetchData();
    }, [])


  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">

            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Teachers</h3>
                        </div>
                    </div>
                </div>

                <div className="student-group-form">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Search by ID ..." />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Search by Name ..." />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="search-student-btn">
                                <button type="btn" className="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card card-table">

                            <div className="card-body">

                                <div className="page-header">
                                    <div className="row align-items-center">
                                    
                                        <div className="col-auto text-end float-end ms-auto download-grp">
                                            <a href="teachers.html" className="btn btn-outline-gray me-2 active"><i className="feather-list"></i></a>
                                            <a href="teachers-grid.html" className="btn btn-outline-gray me-2"><i className="feather-grid"></i></a>
                                            <a href="#" className="btn btn-outline-primary me-2"><i className="fas fa-download"></i> Download</a>
                                            <a href="add-teacher.html" className="btn btn-primary"><i className="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                </div>


                                <div className="table-responsive">
                                    <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                                        <thead className="student-thread">
                                            <tr>
                                                <th>
                                                    <div className="form-check check-tables">
                                                    <input className="form-check-input" type="checkbox" value="all" /> 
                                                    </div>
                                                </th>
                                                <th>Code</th>
                                                <th>Nom</th>
                                                <th>Diplome</th>
                                                <th>Specialite</th>
                                                <th>Email</th>
                                                <th>Telephone</th>
                                               
                                                <th className="text-end">Action</th>
                                            </tr>
                                        </thead>

                                                <tbody>
                                                {teacherList.map((val) => (

                                                    <tr key= {val.code}>
                                                        <td>
                                                            <div className="form-check check-tables">
                                                                <input className="form-check-input" type="checkbox" value="something"/>
                                                            </div>
                                                        </td>

                                                        <td>{val.code}</td>
                                                        <td>
                                                            <h2 className="table-avatar">
                                                                {val.teacherPhoto && (
                                                                <a href="teacher-details.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src={val.teacherPhoto} alt="User Image"/></a>
                                                                )}
                                                                <a href="teacher-details.html">{val.fullname}</a>
                                                            </h2>
                                                        </td>
                            
                                                        <td>{val.specialization}</td>
                                                        <td>{val.specialization}</td>
                                                        <td>{val.phoneNumber}</td>
                                                        <td>{val.phoneNumber}</td>
                                                        <td className="text-end">
                                                            <div className="actions">
                                                                <a id ={`btngroupverticaldrop_${val.code}`} href="#" className="btn btn-sm  me-2 dropdown-toggle"  data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fa fa-bars"></i>
                                                                </a>
                                                                
                                                                <div class="dropdown-menu" aria-labelledby={`btngroupverticaldrop_${val.code}`} >
                                                                    <span class="dropdown-item" href="#" style = {{"cursor":"pointer"}}>Assign a Course</span>
                                                                    <span class="dropdown-item " href="#" style = {{"cursor":"pointer", "color":"red"}} onClick = {()=>{deleteTeacher(val.code)}} >Remove <i className="fa fa-trash-o"></i>  </span>
                                                                </div>
                                                                <a href="#" className="btn btn-sm bg-danger-light " >
                                                                    <i className="feather-edit"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                     ))}
                                                </tbody>
                                        
                                    </table>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
  )
}

export default TeacherList;