import React, {useState, } from "react";
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'


import ENCRYPTION_KEY from '../key'
import encryptTextWithKey from './../utils'

function SideBar() {
    const navigateTo = useNavigate();
    const [displaySubMenu, setDisplaySubMenu1] = useState(0);
    const [userInf, setUserInf] = useState([]);
    const [op , setop] = useState(false)

  const {ueId} = useParams();

  const setDisplaySubMenu = (nb)=>{
    if (op === true  ){
      if (setDisplaySubMenu !== nb) {
        setDisplaySubMenu1(0);
        setop(false);
      }else{
        setDisplaySubMenu1(nb);
      }
    }else{
      setDisplaySubMenu1(nb);
      setop(true);
    }
  }
  if(userInf.length  === 0 || userInf === undefined ){
    try {
          const encryptedData = sessionStorage.getItem('userInfo');
          if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            if (decryptedData) {
              const userInfo = JSON.parse(decryptedData);
              setUserInf(userInfo);
              }
          }else{
              navigateTo('/login');
          }}catch(err){
            console.log(err)   
      }
    }
  
    return (
      <div className="sidebar" id="sidebar">
        <div
          className="slimScrollDiv"
          style={{
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            height: '708px',
          }}
        >
          <div
            className="sidebar-inner slimscroll"
            style={{
              overflow: 'hidden',
              width: '100%',
              height: '708px',
            }}
          >
          <div id="sidebar-menu" className="sidebar-menu" style={{"pointer-events":userInf&&userInf.type&& userInf.type === "parent"?"none":"initial"}}>
            <ul>
              <li className="menu-title">
                <span >Main Menu</span>
              </li>

              <li>
                <Link to={`${userInf?userInf.type? userInf.type === "student"? "/StudendDashboard": userInf.type === "teacher" ? "/DashboadTeacher" : "/" :"#":"#"}`} ><i className="fas fa-home "></i> <span>Home</span></Link>
              </li>
              <li className={`submenu ${displaySubMenu === 1 ? 'active' : ''}`} onClick = {()=>setDisplaySubMenu(1)}>
                <a href="#">
                  <i className="fas fa-graduation-cap"></i> 
                  <span> {userInf?userInf.type === "student" ? "My Class Mates": userInf.type === "teacher" ? "My Students" : "Students":"Loading .."}</span> 
                  <span className="menu-arrow"></span>
                </a>
                <ul style={{display: displaySubMenu === 1 ? 'block' : 'none'}}>
                  <li><Link to={`/students-list/${userInf?userInf.type? userInf.type === "student"? userInf.user.matricule: userInf.type === "teacher" ? userInf.user.code : "all" :"#":"#"}`}>Student List</Link></li>
                  <li  ><a href="#">Student View</a></li>
                </ul>
              </li>
              <li className={`submenu ${displaySubMenu === 7 ? 'active' : ''}`} onClick = {()=>setDisplaySubMenu(7)}>
                <a href="#">
                  <i className="fas fa-chalkboard-teacher"></i> 
                  <span> {userInf?userInf.type === "student" ? "My Teachers": userInf.type === "teacher" ? "My Colleagues" : "Teachers ":"Loading .."}</span> 
                  <span className="menu-arrow"></span>
                </a>
                <ul style={{display: displaySubMenu === 7 ? 'block' : 'none'}}>
                  <li><Link to={`${userInf?userInf.type === "student" ? "/teacher-list/" + userInf.code : userInf.type === "teacher" ? "/teacher-list"  : "/teacher-list":"Loading .."}`}> List</Link></li>
                  {userInf && userInf.type === "admin" && (<><li><Link to="/profile"> My profile</Link></li></>)} 
                 {userInf && userInf.type === "admin" && (
                   <><li>
                      <NavLink zz to='/teacher-add'> Teacher Add </NavLink>
                  </li>
                  <li><a href="#">Add a Teacher</a></li>
                  </> 
                  )}
                </ul>
              </li>
              <li className={`submenu ${displaySubMenu === 10 ? 'active' : ''}`} onClick = {()=>setDisplaySubMenu(10)}>
                <a href="#">
                  <i className="fas fa-graduation-cap"></i> 
                  <span> {userInf?userInf.type === "student" ? "Discussions": userInf.type === "teacher" ? "Discussions" : "Discussions":"Loading .."}</span> 
                  <span className="menu-arrow"></span>
                </a>
                <ul style={{display: displaySubMenu === 10 ? 'block' : 'none'}}>
                  <li><Link to='/discussions-list'>Teacher msg</Link></li>
                  <li  ><Link to="/discussions-list">Students msg</Link></li>
                </ul>
              </li>

              <li className={`submenu ${displaySubMenu === 11 ? 'active' : ''}`} onClick = {()=>setDisplaySubMenu(11)}>
                <a href="#">
                  <i className="fas fa-graduation-cap"></i> 
                  <span> {userInf?userInf.type === "student" ? "Homeworks": userInf.type === "teacher" ? "Homeworks" : "Homeworks":"Loading .."}</span> 
                  <span className="menu-arrow"></span>
                </a>
                <ul style={{display: displaySubMenu === 11 ? 'block' : 'none'}}>
                  <li><Link to='/homeworks-list'>HomeWorks list</Link></li>
                  {userInf && userInf.type === "teacher" && <li  ><Link to="/homeworks-list#create">  add a new</Link></li>}
                </ul>
              </li>

              <li className={`submenu ${displaySubMenu === 2 ? 'active' : ''}`} onClick = {()=>setDisplaySubMenu(2)}>
                <a href="#">
                  <i className="fas fa-building"></i> 
                  <span> Faculties</span> 
                  <span className="menu-arrow"></span>
                </a>
                <ul style={{display: displaySubMenu === 2 ? 'block' : 'none'}}>
                  <li><Link to="/school-details/1"> List Faculties</Link></li>
                 {userInf && userInf.type === "admin" && (<><li><Link to="/fac-add/1"> Add Faculty</Link></li></>)} 
                </ul>
              </li>
              {userInf && userInf !== "admin" && (<>
              <li className={`submenu ${displaySubMenu === 8 ? 'active' : ''}`} onClick = {()=>setDisplaySubMenu(8)}>
                <a href="#">
                  <i className="fas fa-book-reader"></i> 
                  <span> Subjects</span> 
                  <span className="menu-arrow"></span>
                </a>
                <ul style={{display: displaySubMenu === 8 ? 'block' : 'none'}}>
                  <li><Link  to="/MatiereTeacher" >Subject List</Link></li>
                </ul>
              </li>
              </>)}
              <li className={`submenu ${displaySubMenu === 9 ? 'active' : ''}`} onClick = {()=>setDisplaySubMenu(9)}>
                <a href="#">
                  <i className="fas fa-book-reader"></i> 
                  <span> Schools</span> 
                  <span className="menu-arrow"></span>
                </a>
                <ul style={{display: displaySubMenu === 9 ? 'block' : 'none'}}>
                  <li><Link  to="/school-list" > List schools</Link></li>
                  {userInf && userInf.type === "admin" && (<>
                      <li><Link  to="/school-add" > Add school</Link></li>
                      <li><Link  to="/school-details/1" disabled={false } >View first School List</Link></li>
                  </>)}
                </ul>
              </li>
              <li className="menu-title">
                <span>Others</span>
              </li>
              <li>
                <a href="Javascript:void();"><i className="fas fa-hotel"></i> <span>Hostel</span></a>
              </li>
              <li>
                <a href="#"><i className="fas fa-bus"></i> <span>Transport</span></a>
              </li>
            </ul>
          </div>
        </div>
        </div>
        <div
          className="slimScrollBar"
          style={{
            background: 'rgb(204, 204, 204)',
            width: '7px',
            position: 'absolute',
            top: '0px',
            opacity: 0.4,
            display: 'block',
            borderRadius: '7px',
            zIndex: 99,
            right: '1px',
            height: '493.607px'
          }}
        ></div>
        
            <div
              className="slimScrollRail"
              style={{
                width: '7px',
                height: '100%',
                position: 'absolute',
                top: '0px',
                display: 'none',
                borderRadius: '7px',
                background: 'rgb(51, 51, 51)',
                opacity: 0.2,
                zIndex: 90,
                right: '1px',
              }}
            ></div>
      </div>
    );
  }
  
  export default SideBar;