
import React, { useEffect, useRef, useState } from 'react';
import { pdfjs, Page, Document } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    setNumPages(null);
  }, [pdfUrl]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      {pdfUrl ? (
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          className="pdf-document"
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page 
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              className="pdf-page"
              renderMode="canvas" // Ajout de la prop renderMode avec la valeur "svg"
            />
          ))}
        </Document>
      ) : (
        <p>Loading PDF...</p>
      )}
     
    </div>
  );
};

export default PDFViewer;

// import React, { useRef, useEffect, useState } from 'react';
// import { Document, Page } from 'react-pdf';

// const PDFViewer = ({ pdfUrl }) => {
//   const [numPages, setNumPages] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const numPagesRef = useRef(null);

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//     setIsLoaded(true);
//   };

//   useEffect(() => {
//     numPagesRef.current = numPages;
//   }, [numPages]);

//   useEffect(() => {
//     setIsLoaded(false);
//   }, [pdfUrl]);

//   return (
//     <div>
//       {isLoaded &&
//         <Document
//           file={pdfUrl}
//           onLoadSuccess={onDocumentLoadSuccess}
//           className="pdf-document"
//         >
//           {Array.from(new Array(numPagesRef.current), (el, index) => (
//             <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//           ))}
//         </Document>
//       }
//     </div>
//   );
// };

// export default PDFViewer;
