import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Axios from 'axios';
import NavBar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import ENCRYPTION_KEY from '../../key';
import encryptTextWithKey from '../../utils';
import Themes from '../Student/discussion-themes';
import PDFViewer from './PDFViewer';
import Comment from './Comment';


function DevoirView() {
  const { responseId } = useParams();
  const [devoir, setDevoir] = useState([]);
  const [userInf, setUserInf] = useState([]);
  const [message, setMessage ]   = useState('');
  const [userCode, setUserCode] = useState('');
  const [userId, setUserId] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState('');
  const [note, setNote] = useState(0);

  const navigateTo = useNavigate();
  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');


  function fetchedThematik() {
    Axios.get(`https://${domainName}/api/devoir-view/${responseId}`)
      .then((response) => {
        setDevoir(response.data);

        const pdfData = new Uint8Array(response.data.pdfData.data);
        const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });

        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
      })
      .catch((error) => {
        console.error(error);
        setMessage(error.message);
        const id = setTimeout(() => {
          setMessage('');
        }, 4000);

        const cancel = () => {
          clearTimeout(id);
        };

        setTimeout(cancel, 5000);
      });
  }



  const fetchComments = async () => {
      try {
  
        // Envoyez le fichier vers le backend
        await Axios.get(`https://${domainName}/api/devoir-comment-get/${responseId}`)
        .then((res)=>{
            if(res.data.message){
            setMessage(res.data.message);
              const id = setTimeout(() => {
              setMessage('');
            }, 4000);
    
            const cancel = () => {
              clearTimeout(id);
            };
    
            setTimeout(cancel, 5000);
            }else{
              setComments(res.data);
            }
            
          
        })
        .catch((err)=>{
            console.log(err);
            setMessage(err.message);
            const id = setTimeout(() => {
            setMessage('');
          }, 4000);
  
          const cancel = () => {
            clearTimeout(id);
          };
  
          setTimeout(cancel, 5000);
            
        })
  
      } catch (error) {
        // Gérez les erreurs
        console.error('Error  fetching comments List of Homework:', error);
      }
  };

  const addComment = async (e) => {
      if (comment.trim() !== '' && userId !== null) {
        try {
    
          // Envoyez le fichier vers le backend
          await Axios.post(`https://${domainName}/api/devoir-comment-save`, {content:comment , user_id: userId, devoir_response_id:responseId, note:note})
          .then((res)=>{

              setMessage(res.data.message);
              fetchComments();
              const id = setTimeout(() => {
                setMessage('');
                setNote(0);
                setComment('');
              }, 4000);
      
              const cancel = () => {
                clearTimeout(id);
              };
      
              setTimeout(cancel, 5000);
            
          })
          .catch((err)=>{
              console.log(err);
              setMessage(err.message);
              const id = setTimeout(() => {
                setMessage('');
              }, 4000);
      
              const cancel = () => {
                clearTimeout(id);
              };
      
              setTimeout(cancel, 5000);
            
          })
    
        } catch (error) {
          // Gérez les erreurs
          console.error('Error uploading file:', error);
        }
      }
    };
  useEffect(() => {
    
    if(userId === null){
      try {
          const encryptedData = sessionStorage.getItem('userInfo');
          if (encryptedData) {
              const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
              if (decryptedData) {
              const userInfo = JSON.parse(decryptedData);                           
                  setUserCode(userInfo.code);
                  setUserInf(userInfo);    
                  setUserId(userInfo.user.userId);
          }}else{
              alert("Your're not allow to get this page !");
              navigateTo('/login');
          }}
      catch(err){
          console.log(err)

          }
      }


    fetchedThematik();
    fetchComments();
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [responseId, domainName]);


  

  return (
    <div className="main-wrapper">
      <NavBar user="Student" />
      <SideBar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Devoir View</h3>
                <ul className="breadcrumb"></ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="page-header">
                    <div className="row align-items-center">
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link style={{ color: 'cadetblue' }} to="/Homeworks-list">
                            back
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {message && <h3 style={{ color: 'mediumvioletred' }}>{message}</h3>}

                  {/* {pdfUrl && <PDFViewer pdfUrl={pdfUrl} style={{ position: 'absolute' }} />} */}
                  <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {pdfUrl && <iframe src={pdfUrl} width="100%" height="1200px" title="PDF Viewer" />}
                    {!pdfUrl && <h2> Loading PDF...</h2>}
                  </div>

                  {comments.length === 0 && 
                  <div>
                    <textarea className="form-control mt-4" onChange={(e)=>{setComment(e.target.value)}} rows={4} placeholder="Add comment for this post"></textarea>
                    <div class="form-group">
                        <label htmlFor='note' className='form-label' ></label>
                        <input className="form-control mt-2 mb-2" onChange={(e)=>{setNote(e.target.value)}} name="note" placeholder='note/100' type="number"/>
                      </div>
                      {message && <h3 style={{ color: 'mediumvioletred' }}>{message}</h3>}
                      <button className="btn btn-info mt-2" onClick={addComment}>
                        Envoyer
                      </button>
                    </div>}
                      <ul>
                        <h3>Comments:</h3><br/>
                        {comments.map((comment) => (
                          <li key={comment.id}>
                            <Comment comment={comment} />
                          </li>
                        ))}
                      </ul>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevoirView;

/*
    import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Axios from 'axios';
import ENCRYPTION_KEY from '../../key';
import encryptTextWithKey from '../../utils';

import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import Comment from './Comment'; // Composant pour afficher un commentaire
import PDFViewer from './PDFViewer'; // Composant pour afficher le PDF

function DevoirView() {
  const { responseId } = useParams();
  const navigateTo = useNavigate();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [comments, setComments] = useState([]);
  const [message, setMessage] = useState('');

  const commentInputRef = useRef(null);

  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');

  // Fonction pour charger le fichier PDF
  const fetchPDF = async () => {
    try {
      const response = await Axios.get(`https://${domainName}/api/devoir-view/${responseId}`);
      const pdfData = new Uint8Array(response.data.pdfData.data);
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl);
    } catch (error) {
      console.error(error);
      setMessage(error.message);
      setTimeout(() => {
        setMessage('');
      }, 4000);
    }
  };


  const saveCommentToDatabase = ()=>{

  }

  const getCommentsFromDatabase = ()=>{

  }
  // Fonction pour charger les commentaires depuis la base de données
  const fetchComments = async () => {
    try {
      
      setComments(comments);
    } catch (error) {
      console.error(error);
    }
  };

  // Fonction pour ajouter un commentaire
  const addComment = async () => {
    const commentText = commentInputRef.current.value;
    if (commentText.trim() !== '') {
      try {
        
        fetchComments();
        commentInputRef.current.value = '';
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchPDF();
    fetchComments();

    // Nettoie l'URL de l'objet une fois que le composant est démonté
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, []);

  return (
    <div className="main-wrapper">

            <NavBar user="Student"/>
            
            <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
            <div class="col">
            <h3 class="page-title">Devoir View</h3>
            <ul class="breadcrumb">
        
            </ul>
            </div>
            </div>
            </div>

            <div class="row">
            <div class="col-sm-12">
            <div class="card card-table">
            <div class="card-body">

            <div class="page-header">
            <div class="row align-items-center">

            <ul class="breadcrumb">
            <li class="breadcrumb-item "><Link style={{color:"cadetblue"}} to="/Homeworks-list">back</Link></li>
            </ul>
            
          
            </div>
            </div>
                {message && <h3 style={{color:"mediumvioletred"}}>{message}  </h3>}

                <PDFViewer pdfUrl={pdfUrl} style={{position:"absolute"}}/>
                   
                <div>
                  <textarea  className=" form-control" ref={commentInputRef} rows={4} placeholder="Ajouter un commentaire"></textarea>
                  <button className=" btn"onClick={addComment}>Envoyer</button>
               
                  <ul>
                          {comments.map(comment => (
                            <li key={comment.id}>
                              <Comment comment={comment} />
                            </li>
                          ))}
                        </ul>

            </div>
            </div>
            </div>
            </div>
                    
            </div>
            
        </div>
        </div>



    
      
    </div>
  );
}

export default DevoirView;*/