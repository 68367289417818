import React, { useState, useEffect } from "react"
import Axios from "axios";
import {  useParams, useNavigate } from 'react-router-dom';


import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'



function DepartmentUpdate() {
    const [department, setDepartment] = useState([]);
    const [DepartmentName, setDepartmentName] = useState('');
    const [DepartmentBudget, setDepartmentBudget ]= useState('');
    const [DepartmentDescription, setDepartmentDescription ]= useState('');
    const [directorEmail, setSetDepartmentDirectorEmail] = useState('');
    const [DepartmentCity, setDepartmentCity] = useState('');
    const [DepartmentAddress, setDepartmentAddress] = useState('');
    const [DepartmentLocation, setDepartmentLocation] = useState('');
    const [DepartmentCreationDate, setDepartmentCreationDate] = useState('');
    const [DepartmentWebSite, setDepartmentWebSite ] = useState('')

    const [AddStatus, setAddStatus] = useState('');


    const [userInfo, setUserInfo] = useState([])
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const {id}  = useParams()

    let navigate = useNavigate();

    const url = new URL(window.location.href);

    // Récupérer le nom de domaine
    const domainName = url.hostname.replace(/^www\./, '');

    
    const createTeacher = (e) => {

        e.preventDefault();

        const dateObject = new Date(DepartmentCreationDate);

        // Obtenez les composants de la date
        const year = dateObject.getUTCFullYear();
        const month = ("0" + (dateObject.getUTCMonth() + 1)).slice(-2);
        const day = ("0" + dateObject.getUTCDate()).slice(-2);

        // Obtenez les composants de l'heure
        const hours = ("0" + dateObject.getUTCHours()).slice(-2);
        const minutes = ("0" + dateObject.getUTCMinutes()).slice(-2);
        const seconds = ("0" + dateObject.getUTCSeconds()).slice(-2);

        // Format de date et heure pour MySQL (AAAA-MM-JJ HH:MM:SS)
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;



        const data = {
            id:department.id,
            name: DepartmentName,
            address: DepartmentAddress,
            city: DepartmentCity,
            location:DepartmentLocation,
            date: formattedDate ,
            email:directorEmail,
            website:DepartmentWebSite,
            description:DepartmentDescription,
            budget:DepartmentBudget,
        }
        
        Axios.post(`https://${domainName}/api/department-add/${id}`, data).then(() => {
            setDepartmentName('');
            setDepartmentAddress('');
            setDepartmentCity('');
            setDepartmentCreationDate('');
            setSetDepartmentDirectorEmail('');
            setDepartmentDescription('')

            setAddStatus(`The Department has been created!`)
            navigate(`/department-details/${id}`)
        })
    }
    useEffect(()=>{
        if(department.length===0) {
          Axios.get(`https://${domainName}/api/department-details/${id}`)
              .then((response) => {
                console.log(response.data);
                setDepartment(response.data.department);
              })
              .catch((error) => {
                console.error(error);
              });
        }
    
        if (department.length !== 0 && DepartmentName.trim() === '') {
          const facultyDetails = department;
          setSetDepartmentDirectorEmail(facultyDetails.directorEmail);
          setDepartmentName(facultyDetails.name);
          setDepartmentCity(facultyDetails.city);
          setDepartmentAddress(facultyDetails.address);
          setDepartmentLocation(facultyDetails.location);
          setDepartmentCreationDate(facultyDetails.creationDate);
          setDepartmentWebSite(facultyDetails.website);
          setDepartmentDescription(facultyDetails.description ? facultyDetails.description : '');
          setDepartmentBudget(facultyDetails.budget);
        }
    
    }, [department, DepartmentName, id]);
    

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Update Department </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">Department Informations</h5>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Department's Name:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Department Name" value={DepartmentName}
                                                onChange={(event) => setDepartmentName(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Budget:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="number" class="form-control input-height" placeholder="" aria-describedby="Department Name" value={DepartmentBudget}
                                                onChange={(event) => setDepartmentBudget(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Description:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Department Name" value={DepartmentDescription}
                                                onChange={(event) => setDepartmentDescription(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>
                                        

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Department Campus Address</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Department Address" value={DepartmentAddress}
                                                onChange={(event) => setDepartmentAddress(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Department Campus CitY</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Department City" value={DepartmentCity}
                                                onChange={(event) => setDepartmentCity(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Department Campus Location Place</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Loaction Place" value={DepartmentLocation}
                                                onChange={(event) => setDepartmentLocation(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Department Official Website</label>
                                        <div class="col-lg-10">
                                            <div class="input-group">
                                                <span class="input-group-text">https://</span>
                                                <input type="text" class="form-control "onChange={(event) => setDepartmentWebSite(event.target.value)}  value={DepartmentWebSite}required/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Creation's Date</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="date"  class="form-control input-height" placeholder="" aria-describedby="The of First Day Class" value={DepartmentCreationDate}
                                                onChange={(event) => setDepartmentCreationDate(event.target.value)}  required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Dean's Email </label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="email" class="form-control input-height" placeholder="" aria-describedby="The Full Name of the Teacher" value={directorEmail}
                                                onChange={(event) => setSetDepartmentDirectorEmail(event.target.value)} required />
                                            </div>
                                         </div>
                                    </div>
                                        


                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default DepartmentUpdate