import React, { swal, useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from '../../utils'
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import Axios from 'axios';
import Themes from '../Student/discussion-themes'
import Comment from './Comment'

function Devoir(){
    const {devoirId} = useParams();
    const [devoir, setDevoir] = useState([]);
    const [userInf, setUserInf] = useState([]);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdf, setPdf] = useState(null);
  const [comments, setComments] = useState([]);
  const [userCode, setUserCode] = useState('');
    const [userId , setUserId] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [message, setMessage] = useState('');

    const navigateTo = useNavigate();

    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');


    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

 


    if(userId === null){
   try {
    const encryptedData = sessionStorage.getItem('userInfo');
    if (encryptedData) {
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
        const userInfo = JSON.parse(decryptedData);                           
            setUserCode(userInfo.code);
            setUserInf(userInfo);    
            setUserId(userInfo.user.userId);
    }}else{
        alert("Your're not allow to get this page !");
        navigateTo('/login');
    }}
catch(err){
    console.log(err)
    }
   }



   const handleEditButtonClick = async (e) => {
    e.preventDefault();
    // Vérifiez si un fichier a été sélectionné
    if (pdf!== null ) {
      try {
        const formData = new FormData;
        formData.append("pdf", pdf);
        formData.append('devoir_id', devoirId);
        formData.append("user_id", userInf.user.userId);
        // Envoyez le fichier vers le backend
        await Axios.post(`https://${domainName}/api/devoir-response-save`, formData , {headers:{'Content-Type': 'multipart/form-data'}})
        .then((res)=>{

            setMessage(res.data.message);
            fetchedThematik();

            const id = setTimeout(() => {
            setMessage('');
          }, 4000);
          
            // Définir une fonction de rappel pour annuler la temporisation
            const cancel = () => {
              clearTimeout(id);
            };
          
            // Annuler la temporisation après 5 secondes
            setTimeout(cancel, 5000);
        })
        .catch((err)=>{
            console.log(err);
            setMessage(err.message);
            const id = setTimeout(() => {
              setMessage('');
            }, 4000);
            
              // Définir une fonction de rappel pour annuler la temporisation
              const cancel = () => {
                clearTimeout(id);
              };
            
              // Annuler la temporisation après 5 secondes
              setTimeout(cancel, 5000);
            
        })
  
      } catch (error) {
        // Gérez les erreurs
        console.error('Error uploading file:', error);
      }
    }
  };


  const fetchComments = async (responseId) => {
      try {
  
        // Envoyez le fichier vers le backend
        await Axios.get(`https://${domainName}/api/devoir-comment-get/${responseId}`)
        .then((res)=>{
            if(res.data.message){
            setMessage(res.data.message);
              const id = setTimeout(() => {
              setMessage('');
            }, 4000);
    
            const cancel = () => {
              clearTimeout(id);
            };
    
            setTimeout(cancel, 5000);
            }else{
              setComments(res.data)
            }
            
          
        })
        .catch((err)=>{
            console.log(err);
            setMessage(err.message);
            const id = setTimeout(() => {
            setMessage('');
          }, 4000);
  
          const cancel = () => {
            clearTimeout(id);
          };
  
          setTimeout(cancel, 5000);
            
        })
  
      } catch (error) {
        // Gérez les erreurs
        console.error('Error  fetching comments List of Homework:', error);
      }
  };


   function fetchedThematik() {
    Axios.get(`https://${domainName}/api/devoir-get/${devoirId}/${userId}`)
          .then((response) => {
            setDevoir(response.data);
            if(response.data.devoirRresponseWithComment){

              const pdfData = new Uint8Array(response.data.devoirRresponseWithComment.pdfData.data);
              const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
              const pdfUrl = URL.createObjectURL(pdfBlob);
              setPdfUrl(pdfUrl);
              fetchComments(response.data.devoirRresponseWithComment.id);
            }
          })
          .catch((error) => {
            console.error(error);
            setMessage(error.message)
          });
   }



       useEffect(() => {
        fetchedThematik();
        return () => {
          if (pdfUrl) {
            URL.revokeObjectURL(pdfUrl);
          }
        };

      },[]);
      
    return (

        <div className="main-wrapper">

            <NavBar user="Student"/>
            
            <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
            <div class="col">
            <h3 class="page-title">Homeworks Details</h3>
            <ul class="breadcrumb">
        
            </ul>
            </div>
            </div>
            </div>

            <div class="row">
            <div class="col-sm-12">
            <div class="card card-table">
            <div class="card-body">

            <div class="page-header">
            <div class="row align-items-center">

            <ul class="breadcrumb">
            <li class="breadcrumb-item "><Link style={{color:"cadetblue"}} to="/Homeworks-list">back</Link></li>
            </ul>
            
          
            </div>
            </div>
                {message && <h3 style={{color:"mediumvioletred"}}>{message}  </h3>}

                    <div className='card container mt-4'>
                        <div className='card-header'>
                            <h3> Posted by: {devoir && devoir.devoir && (devoir.devoir.author)}</h3>
                        </div>
                        <div className='card-body'>
                            <span style={{fontWeight:"bold"}}> Content: <br/></span>  {devoir && devoir.devoir && (devoir.devoir.description)}
                        </div>
                        <div className='modal-body'>
                          {devoir && devoir.lst ? (
                            <>
                                <div class="table-responsive">
                                  <table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                                      <thead class="student-thread">
                                          <tr>
                                              <th>ID</th>
                                              <th>Author</th>
                                              <th>Uploaded Date</th>
                                              <th class="text-end">State</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {devoir.lst.map((dev, index)=>(
                                    <tr
                                          key = {index}
                                          onClick={() => navigateTo(`/homeworks-response/${dev.id}/view`)}
                                          style={{
                                              cursor: 'pointer',
                                              backgroundColor: hoveredIndex === index ? 'lightgray' : dev.state ? '#adff2f80':"inherit",
                                            }}
                                            onMouseEnter={() => setHoveredIndex(index)}
                                            onMouseLeave={() => setHoveredIndex(null)}
                                             

                                          >
                                              <td>{ index + 1}</td>
                                              <td>{ dev.author    }</td>
                                              <td>{new Date(dev.created_at).toLocaleDateString("en-FR", options)} </td>
                                              <td class="text-end">
                                                  <div class="actions">
                                                    <span>{dev.state? "corrected":"no corrected"}</span>
                                              </div>
                                              </td>
                                          </tr>
                                          ))}
                                      </tbody>
                                  </table>
                                  </div>
                            </>
                          ):
                          (<>
                            {devoir && devoir.devoirRresponseWithComment ? (
                            <>
                               <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  {pdfUrl && <iframe src={pdfUrl} width="100%" height="1200px" title="PDF Viewer" />}
                                  {!pdfUrl && <h2> Loading PDF...</h2>}

                                </div>
                                <div className='card container'>
                                    <div className='card-body from-group'>
                                    <ul>
                                      <h3 style={{fontWeight:"bold", fontFamily:"monospace sans-serif"}}>Teacher comments:</h3><br/>
                                      {comments.map((com) => (
                                        <li key={com.id}>
                                          <Comment comment={com} />
                                        </li>
                                      ))}
                                    </ul>
                                    </div>
                                </div>
                            </>
                            ):(
                            <>
                              <input type="file" style={{ position: "absolute", left: '0' }} accept='.pdf' onChange={(e) => { setPdf(e.target.files[0]) }}  />
                              <button type='submit' style={{ position: "absolute", right: '0' }} className='btn btn-primary' onClick={handleEditButtonClick}> Submit </button>
                            </>
                            )}
                          </>)}
                        </div>
                    </div>


            </div>
            </div>
            </div>
            </div>
                    
            </div>
            
        </div>
        </div>

    )}
    export default  Devoir;