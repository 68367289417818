import { useState  ,useEffect} from "react"
import axios from "axios";
import SideBar from "../../Components/SideBar";
import NavBar from "../../Components/NavBar";
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
import { Link, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function  ListEmploiTemp () {
  const navigateTo = useNavigate();
  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');
  const [userCode, setUserCode] = useState('');
  const [userInf, setUserInf] = useState([]);
  const [data , setData] = useState([])
  const [UpdateEmploi , setUpdateEmploi] = useState(false)
  const[MessageDelete , setMessageDelete] = useState('')
  const allDates = [...new Set(data.map(item => item.date))];

  const [editEmploi, setEditEmploi] = useState(null);
  const [selectedDate, setSelectedDate] = useState(''); // Date sélectionnée pour le filtrage

  // Fonction pour mettre à jour la date sélectionnée
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  // Fonction pour filtrer les emplois du temps en fonction de la date sélectionnée
  const filteredSchedule = selectedDate === "" ? data : data.filter((item) => {
    return item.date === selectedDate;
  });
if(userCode === ''){
  try {
      const encryptedData = sessionStorage.getItem('userInfo');
      if (encryptedData) {
          const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
          if (decryptedData) {
          const userInfo = JSON.parse(decryptedData);
          setUserCode(userInfo.code);
          setUserInf(userInfo);
          console.log("user" + userInfo.code)
          }
      }else{
          alert("Page only permit to teachers and students");
          navigateTo('/login');
      }}catch(err){

      }
  }
   const count = () => {
     if(data.length === 0){
      return <h1 className="text-center" style={{marginTop: "50px", opacity: ".2"}}>Aucun emploi de temp</h1>
     }
   }
   const handleReset = () => {
    setUpdateEmploi(false)
   }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://${domainName}/api/get-emploi-temps/${userCode}`);
        const formattedData = response.data.map((emploi) => ({
          ...emploi,
          date: moment(emploi.date).format('dddd DD/MM/YYYY'), 
        }));
        setData(formattedData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [userCode]);
  const getFormUpdate  = (emploi , e) => {
    e.preventDefault()
    setEditEmploi(emploi)
    setUpdateEmploi(true)
  }
  const handleDelete = (id_emploi) => {
        
       axios.delete(`https://${domainName}/api/delete-emploi/${id_emploi}`)
       .then((response)=> {
          setMessageDelete( response.message)
          setTimeout(() =>{
            setMessageDelete('');
          }, 3000 )
       })
       .catch((error) =>{
        setMessageDelete("une erreur est survenu:" + error)
        setTimeout(() =>{
          setMessageDelete('');
        }, 3000 )
       })
       
       
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
        const updateEmploi = async (id, updatedEmploi) => {
            try {
              await axios.put(`https://${domainName}/api/update/${id}`, updatedEmploi);
          
              // Recherchez le vendeur mis à jour dans les données existantes
              const updatedData = data.map((emploi) => {
                if (emploi.id === id) {
                  return { ...emploi, ...updatedEmploi };
                }
                return emploi;
              });
              setData(updatedData);
              setEditEmploi(null);
              setUpdateEmploi(false)
            } catch (error) {
              console.error('Erreur lors de la mise à jour du vendeur :', error);
            }
           
          };
      await updateEmploi(editEmploi.id, editEmploi);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du vendeur :', error);
    }
  };
  const downloadAsPdf = () => {
    const doc = new jsPDF();
  
    // Générer le contenu du tableau
    const tableData = [];
    const tableHeaders = ['Date', 'Heure', 'Cours'];
  
    // Ajouter les données du tableau
    filteredSchedule.forEach((emploi) => {
      const rowData = [
        emploi.date,
        emploi.creneaux,
        emploi.occupation
      ];
      tableData.push(rowData);
    });
  
    // Ajouter le tableau au PDF
    doc.autoTable({
      head: [tableHeaders],
      body: tableData
    });
  
    // Enregistrer le fichier PDF
    doc.save('emploi-de-temp.pdf');
  }
    return(
      <div className="main-wrapper">
         <NavBar user="Teacher"/>
         <SideBar/>
      {UpdateEmploi ? (
           <div className="page-wrapper">
           <div className="content container-fluid">
             <div className="page-header">
               <div className="row align-items-center">
                 <div className="col">
                   <h3 className="page-title">Modifier l'emploi de temp</h3>
                 </div>
               </div>
              </div>
            
              <div className= 'card-title'>
             
             
                 <div  className="form-group">
                   <form onSubmit={handleSubmit}>
                   <label className="col-form-label col-md-2">Date</label>
                   <input
                     type="date"
                     className="form-control"
                     name="date"
                     value={editEmploi.date}
                     onChange={(e) => setEditEmploi({ ...editEmploi, date: e.target.value })}
                   />
   
                   <label className="col-form-label col-md-2">
                     Créneaux | <em>ex: 12h-13h</em>
                   </label>
                   <input
                     type="text"
                     name="creneaux"
                     className="form-control"
                     value={editEmploi.creneaux}
                     onChange={(e) => setEditEmploi({ ...editEmploi, creneaux: e.target.value })}
                    
                   />
   
                   <label className="col-form-label col-md-2">Occupation</label>
                   <input
                     type="text"
                     name="occupation"
                     className="form-control"
                     value={editEmploi.occupation}
                     onChange={(e) => setEditEmploi({ ...editEmploi, occupation: e.target.value })}
                    
                   />
                 <button className="btn btn-success" onClick={handleSubmit} type="button"   style={{ marginRight: '10px' , marginTop: "10px" }}>
                   Update
                 </button>
                 <button className="btn btn-primary" onClick={handleReset} type="button" style={{marginTop: "10px"}}>
                  Reset
                 </button>
                   <hr />
                    </form> 
                   
                 </div>


             </div>
             </div>
            </div>
      ) : (
        <div className="page-wrapper">
        <div className="content container-fluid">
        <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Mon emploi de temp</h3>
              </div>
            </div>
          </div>
          <select className="form-select" value={selectedDate} onChange={handleDateChange}>
            <option value="">Toutes les dates</option>
            {allDates.map((date, index) => (
              <option key={index} value={date}>{date}</option>
            ))}
          </select> <br></br>
          <div  className="col-auto text-end float-end ms-auto download-grp">
         
            <button onClick={downloadAsPdf} className="btn btn-primary"><i class="fas fa-download"></i> Download</button>
           </div>
        <table className="table">
          {MessageDelete && <p className="alert alert-success">{MessageDelete}</p>}
            <thead>
              <tr>
                <th>Date</th>
                <th>Heure</th>
                <th>cours</th>
                <th>Actions</th>

              </tr>
            </thead>
            <tbody>
            
              {filteredSchedule.map((emploi) => (
                <tr key={emploi.id}>
                 
                  <td>{emploi.date}</td>
                  <td>{emploi.creneaux}</td>
                  <td>{emploi.occupation}</td> 
                  <td> <a href="list-emploi"> <ion-icon name='trash-outline'onClick={()=>handleDelete(emploi.id)}></ion-icon>   < ion-icon name='create-outline' onClick={(e)=>getFormUpdate(emploi , e)}></ion-icon></a> </td>
                </tr>
              
              ))}
            </tbody>
     </table>
     {count()}

      </div>
    </div>
      )
           
      } 
   
      </div>
    
    )
}
export default ListEmploiTemp; 