import React, { useState, useEffect } from "react"
import Axios from "axios";
import {  useParams, useNavigate } from 'react-router-dom';


import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
const CryptoJS = require('crypto-js');



function NiveauAdd() {

    const [niveauName, setniveauName] = useState('');
    const [niveauDescription, setniveauDescripton] = useState('');
    const [AddStatus, setAddStatus] = useState('');


    const [userInfo, setUserInfo] = useState([])
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const {filiereId}  = useParams()

    let navigate = useNavigate();


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    
    const createTeacher = (e) => {
        e.preventDefault();

        


        const data = {
            name: niveauName,
            description:niveauDescription,
        }
        
        Axios.post(`https://${domainName}/api/niveau-add/${filiereId}`, data).then(() => {
            setniveauName('');
            setniveauDescripton('');


            setAddStatus(`The niveau has been created!`)
            navigate(`/filiere-details/${filiereId}`)
        })
    }
    useEffect(()=>{
        try {
            const encryptedData = localStorage.getItem('userInfo');
            if (encryptedData) {
              const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
              if (decryptedData) {
                const userInfo = JSON.parse(decryptedData);
                console.log("decryptDate:", decryptedData);
                setUserInfo(userInfo);
                setIsLoggedIn(true);
                }
            }}catch(err){
                
            }
    });

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Add niveau </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">niveau Informations</h5>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The niveau's Name:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="niveau Name" value={niveauName}
                                                onChange={(event) => setniveauName(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>
                                        

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">niveau Description</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="niveau Address" value={niveauDescription}
                                                onChange={(event) => setniveauDescripton(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    

                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default NiveauAdd