import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom'

function NotificationsDropdown(userCode) {
  const [notifications, setNotifications] = useState([]);
  const [unreadnotifications, setunreadNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');
    const navigateTo = useNavigate();

    const fetchNotifications = async () => {
          try {
            setLoading(true)
            await axios.get(`https://${domainName}/api/user-notifications/${userCode.userCode}`)
            .then((response)=>{
            const { unreadNotifications, readNotifications } = response.data;
            const allNotifications = [...unreadNotifications, ...readNotifications];
            setunreadNotifications(unreadNotifications);
            setNotifications(allNotifications);
            })
            .catch((error)=>{
              console.log(error);
            });
          } catch (error) {
            console.error(error);
          }
        };

  useEffect(() => {
    
    if(notifications.length === 0)
    {
      fetchNotifications();
    }
  }, [userCode]);

  return (
    <li className="nav-item dropdown noti-dropdown me-2">
        <a href="#" className="dropdown-toggle nav-link header-nav-list position-relative" data-bs-toggle="dropdown" aria-expanded="true">
          <img src="/assets/img/icons/header-icon-05.svg" alt="" />
          {unreadnotifications.length > 0 && (<span className="position-absolute  start-1 translate-middle badge rounded-pill bg-danger">{unreadnotifications.length}</span>)}
        </a>

      <div className="dropdown-menu notifications " data-popper-placement="bottom-end" style={{ position: 'absolute', inset: '0px 0px auto auto', margin: '0px', transform: 'translate(0px, 42px)' }}>
        <div className="topnav-dropdown-header">
          <span className="notification-title">Notifications</span>
          <a href="javascript:void(0)" className="clear-noti"> Clear All </a>
        </div>
        <div className="noti-content">
          <ul className="notification-list">
            {notifications.map(notification => (
              <li key={notification.id} onClick={()=>{navigateTo(`${notification.actionLink}`   )}} className="notification-message">
                <a href="#">
                  <div className="media d-flex">
                    <span className="avatar avatar-sm flex-shrink-0">
                      <img className="avatar-img rounded-circle" alt="User Image" src={`/uploaded/${notification.avatar ? notification.avatar: notification.avatar1 ? notification.avatar1 : notification.avatar2 ? notification.avatar2 : "default-user.jpg"}`} />
                    </span>
                    <div className="media-body flex-grow-1">
                      <p className="noti-details">
                        <span className="noti-title">{notification.sender}</span> {notification.message}
                      </p>
                      <p className="noti-time"><span className="notification-time">{notification.timestamp}</span></p>
                    </div>
                  </div>
                </a>
              </li>
            ))}
           { notifications.length === 0 && (<span style={{margin:'5px'}}>No notification for you</span>)}
          </ul>
        </div>
        <div className="topnav-dropdown-footer">
          <a href="#">View all Notifications</a>
        </div>
      </div>
    </li>
  );
}

export default NotificationsDropdown;