import React, { useState, useEffect } from "react"
import Axios from "axios";
import {  useParams, useNavigate } from 'react-router-dom';


import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
const CryptoJS = require('crypto-js');



function DepartmentAdd() {

    const [DepartmentName, setDepartmentName] = useState('');
    const [directorEmail, setSetDepartmentDirectorEmail] = useState('');
    const [DepartmentCity, setDepartmentCity] = useState('');
    const [DepartmentAddress, setDepartmentAddress] = useState('');
    const [DepartmentLocation, setDepartmentLocation] = useState('');
    const [DepartmentCreationDate, setDepartmentCreationDate] = useState('');
    const [DepartmentWebSite, setDepartmentWebSite ] = useState('')

    const [AddStatus, setAddStatus] = useState('');


    const [userInfo, setUserInfo] = useState([])
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const {facId}  = useParams()

    let navigate = useNavigate();
    const createTeacher = (e) => {
        e.preventDefault();

        if (DepartmentName.trim() === '' || DepartmentCreationDate.trim()=== "") {
            setAddStatus("Veuillez au moins remplir le nom et la date de creation")
        }else{
            const data = {
                name: DepartmentName,
                address: DepartmentAddress,
                city: DepartmentCity,
                location:DepartmentLocation,
                date: DepartmentCreationDate ,
                email:directorEmail,
                website:DepartmentWebSite,
            }


        
            const url = new URL(window.location.href);

            // Récupérer le nom de domaine
            const domainName = url.hostname.replace(/^www\./, '');
            
            Axios.post(`https://${domainName}/api/department-add/${facId}`, data).then(() => {
                setDepartmentName('');
                setDepartmentAddress('');
                setDepartmentCity('');
                setDepartmentCreationDate('');
                setSetDepartmentDirectorEmail('');


                setAddStatus(`The Department has been created!`)
                navigate(`/fac-details/${facId}`)
            })
        }
    }
    useEffect(()=>{
        try {
            const encryptedData = localStorage.getItem('userInfo');
            if (encryptedData) {
              const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
              if (decryptedData) {
                const userInfo = JSON.parse(decryptedData);
                console.log("decryptDate:", decryptedData);
                setUserInfo(userInfo);
                setIsLoggedIn(true);
                }
            }}catch(err){
                
            }
    });

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Add Department </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">Department Informations</h5>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Department's Name:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Department Name" value={DepartmentName}
                                                onChange={(event) => setDepartmentName(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>
                                        

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Department Campus Address</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Department Address" value={DepartmentAddress}
                                                onChange={(event) => setDepartmentAddress(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Department Campus CitY</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Department City" value={DepartmentCity}
                                                onChange={(event) => setDepartmentCity(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Department Campus Location Place</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Loaction Place" value={DepartmentLocation}
                                                onChange={(event) => setDepartmentLocation(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Department Official Website</label>
                                        <div class="col-lg-10">
                                            <div class="input-group">
                                                <span class="input-group-text">https://</span>
                                                <input type="text" class="form-control "onChange={(event) => setDepartmentWebSite(event.target.value)}  value={DepartmentWebSite}required/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Creation's Date</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="date"  class="form-control input-height" placeholder="" aria-describedby="The of First Day Class" value={DepartmentCreationDate}
                                                onChange={(event) => setDepartmentCreationDate(event.target.value)}  required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Dean's Email </label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="email" class="form-control input-height" placeholder="" aria-describedby="The Full Name of the Teacher" value={directorEmail}
                                                onChange={(event) => setSetDepartmentDirectorEmail(event.target.value)} required />
                                            </div>
                                         </div>
                                    </div>
                                        


                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default DepartmentAdd