import React, { useState, useEffect } from "react"
import Axios from "axios";
import {  useParams, useNavigate } from 'react-router-dom';


import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
const CryptoJS = require('crypto-js');



function FiliereAdd() {

    const [FiliereName, setFiliereName] = useState('');
    const [directorEmail, setSetFiliereDirectorEmail] = useState('');
    const [FiliereCity, setFiliereCity] = useState('');
    const [FiliereAddress, setFiliereAddress] = useState('');
    const [FiliereLocation, setFiliereLocation] = useState('');
    const [FiliereCreationDate, setFiliereCreationDate] = useState('');
    const [FiliereWebSite, setFiliereWebSite ] = useState('')

    const [AddStatus, setAddStatus] = useState('');


    const [userInfo, setUserInfo] = useState([])
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const {departmentId}  = useParams()

    let navigate = useNavigate();


    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');

    
    const createTeacher = (e) => {
        e.preventDefault();

        if (FiliereName.trim() === '' || FiliereCreationDate.trim()=== "") {
            setAddStatus("Veuillez au moins remplir le nom et la date de creation")
        }else{
            const data = {
                name: FiliereName,
                address: FiliereAddress,
                city: FiliereCity,
                location:FiliereLocation,
                date: FiliereCreationDate ,
                email:directorEmail,
                website:FiliereWebSite,
            }
            
            Axios.post(`https://${domainName}/api/filiere-add/${departmentId}`, data).then(() => {
                setFiliereName('');
                setFiliereAddress('');
                setFiliereCity('');
                setFiliereCreationDate('');
                setSetFiliereDirectorEmail('');


                setAddStatus(`The Filiere has been created!`)
                navigate(`/department-details/${departmentId}`)
            })
        }
    }
    useEffect(()=>{
        try {
            const encryptedData = localStorage.getItem('userInfo');
            if (encryptedData) {
              const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
              if (decryptedData) {
                const userInfo = JSON.parse(decryptedData);
                console.log("decryptDate:", decryptedData);
                setUserInfo(userInfo);
                setIsLoggedIn(true);
                }
            }}catch(err){
                
            }
    });

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Add Filiere </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">Filiere Informations</h5>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Filiere's Name:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Filiere Name" value={FiliereName}
                                                onChange={(event) => setFiliereName(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>
                                        

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Filiere Campus Address</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Filiere Address" value={FiliereAddress}
                                                onChange={(event) => setFiliereAddress(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Filiere Campus CitY</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Filiere City" value={FiliereCity}
                                                onChange={(event) => setFiliereCity(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Filiere Campus Location Place</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="Loaction Place" value={FiliereLocation}
                                                onChange={(event) => setFiliereLocation(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Filiere Official Website</label>
                                        <div class="col-lg-10">
                                            <div class="input-group">
                                                <span class="input-group-text">https://</span>
                                                <input type="text" class="form-control "onChange={(event) => setFiliereWebSite(event.target.value)}  value={FiliereWebSite}required/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Creation's Date</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="date"  class="form-control input-height" placeholder="" aria-describedby="The of First Day Class" value={FiliereCreationDate}
                                                onChange={(event) => setFiliereCreationDate(event.target.value)}  required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">Dean's Email </label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="email" class="form-control input-height" placeholder="" aria-describedby="The Full Name of the Teacher" value={directorEmail}
                                                onChange={(event) => setSetFiliereDirectorEmail(event.target.value)} required />
                                            </div>
                                         </div>
                                    </div>
                                        


                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default FiliereAdd