import React, { useState, useEffect } from 'react';
import NavBar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import Axios from 'axios';
import ENCRYPTION_KEY from '../../key';
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js';

function DepartmentDetails() {

  const [department, setdepartment] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const {id}  = useParams()
  const [userInf, setUserInf] = useState([]);
  const [userCode, setUserCode] = useState('');

  if(userCode === ''){
    try {
        const encryptedData = sessionStorage.getItem('userInfo');
        if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            const userInfo = JSON.parse(decryptedData);
            setUserInf(userInfo);
            setUserCode(userInfo.code)

        }
    }catch(err){
            
        }
    }
    

  const navigate = useNavigate();

  const url = new URL(window.location.href);

  // Récupérer le nom de domaine
  const domainName = url.hostname.replace(/^www\./, '');

   useEffect(() => {
    Axios.get(`https://${domainName}/api/department-details/${id}`)
      .then((response) => {
        console.log(response.data);
        setdepartment(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

  },[]);
  

  return (
    <div className="main-wrapper">
      <NavBar />
      <SideBar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">department: {department.department?(department.department.name):('')} </h3>
                <ul className="breadcrumb"></ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                
                <div className="card-body">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="row">
                      <div class="page-header">
                        <div class="row align-items-center">
                          <div class="col">
                            <h3 class="page-title">All  filieres: <span className='text-blue'>{department.filieres? (department.filieres.length):(0)}</span></h3>
                          </div>
                             {userInf && userInf.type === 'admin' && (<> 
                             <div class="col-auto text-end float-end ms-auto download-grp">
                                <Link to={department.department?(`/teacher-add/${department.department.id}`):('#')}  class="btn btn-outline-primary me-2"><i class="fas fa-plus"></i> Add a Teacher</Link>
                                 <Link to={department.department?(`/filiere-add/${department.department.id}`):('#')} title="Add a new filiere for this department" class="btn btn-primary"><i class="fas fa-plus"></i></Link>
                                 <Link to={department.department?(`/department-update/${department.department.id}`):('#')} title="Update Informations  this department" class="btn btn-primary"><i class="fas fa-edit"></i></Link>
                              </div>
                              </>)}
                            </div>
                          </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <label>
                              Show{' '}
                              <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>{' '}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6"></div>
                      </div>
                      <div className="row">
            <div className="col-sm-12">
              <table className="datatable table table-stripped dataTable no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                <thead>
                  <tr role="row">
                  <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '155.797px' }}>
                      Id
                    </th>
                    <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '155.797px' }}>
                      filiere's Name
                    </th>
                    <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Start date: activate to sort column ascending" style={{ width: '100.781px' }}>
                      Number Of Students
                    </th>
                    <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Salary: activate to sort column ascending" style={{ width: '86.0469px' }}>
                        Number Of Teachers
                    </th>
                    <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{ width: '48.3594px' }}>
                      Director's Mail
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {department.filieres?(department.filieres.length>0 ? (
                    department.filieres.map((filiere, index) => (
                      <tr
                        key={index}
                        onClick={() => navigate(`/filiere-details/${filiere.id}`)}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: hoveredIndex === index ? 'lightgray' : 'inherit',
                        }}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                      >
                        <td>{filiere.id}</td>
                        <td>{filiere.name}</td>
                        <td>{filiere.numberOfStudents}</td>
                        <td>{filiere.numberOfTeachers}</td>
                        <td>{filiere.directorEmail}</td>
                      </tr>
                    ))
                  ) : (
                    <>
                      This department has no filieres Yet, {userInf && userInf.type === 'admin' && (<Link to={`/filiere-add/${department.department.id}`}>Add One</Link>)}
                    </>
                  )):
                  (<> <h3> Quelque chose n'a pas parche lors de la recuperation de l'ecole dans le bakend</h3></>)}
                                  </tbody>
                                </table>
                              </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5"></div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="DataTables_Table_0_paginate"
                          >
                            <ul className="pagination">
                              <li
                                className="paginate_button page-item previous disabled"
                                id="DataTables_Table_0_previous"
                              >
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="0"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  Previous
                                </a>
                              </li>
                              <li className="paginate_button page-item active">
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="1"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  1
                                </a>
                              </li>
                              <li className="paginate_button page-item ">
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="2"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  2
                                </a>
                              </li>
                              <li className="paginate_button page-item next" id="DataTables_Table_0_next">
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="3"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  Next
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepartmentDetails;