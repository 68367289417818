import {BrowserRouter, Routes, Route } from 'react-router-dom'

//import logo from './logo.svg';
import './App.css';

import Home from './Pages/Home';
import Login from './Pages/Login';

import TeacherAdd from './Pages/Teacher/TeacherAdd';
import TeacherList from './Pages/Teacher/TeacherList';
import TeacherUpdate from './Pages/Teacher/TeacherUpdate';
import TeacherInscription from './Pages/Teacher/TeacherInscription';

//TeacherDashboard
import ParentInfo from './Pages/parents/parent-home';

import Hometeacher from  './Pages/TeacherDashboad/Home';
import TimeTable from  './Pages/TeacherDashboad/Teacher-Timetable';
import MatiereTable from './Pages/TeacherDashboad/Matiere';
import AddTime from   './Pages/TeacherDashboad/AddTable';
import CourseList from './Pages/TeacherDashboad/course-list';
import CourseDetails from './Pages/TeacherDashboad/CourseDetails';
import CourseAdd from './Pages/TeacherDashboad/course-add';
import ExamsTeacher from './Pages/TeacherDashboad/Exam-Teacher';
import ExamsAdd from './Pages/TeacherDashboad/AddExamen';
import ExamsUpdate from './Pages/TeacherDashboad/ExamUpdate';
import UpdateTime from './Pages/TeacherDashboad/UpdateTable';
import StudentsList from './Pages/TeacherDashboad/StudentsList';
import Profile from './Pages/Student/Profile';
import ExamDetail from './Pages/TeacherDashboad/examen-details';

import ParentForm from './Pages/parents/parentForm';


import SchoolAdd from './Pages/school/SchoolAdd';
import SchoolUpdate from './Pages/school/SchoolUpdate';
import SchoolList from './Pages/school/SchoolsList';
import SchoolDetails from './Pages/school/SchoolDetails';

import FacAdd from './Pages/Faculties/FacAdd';
import FacDetails from './Pages/Faculties/FacDetails';
import FacUpdate from './Pages/Faculties/FacUpdate';

import DepartmentAdd from './Pages/Departments/DepartmentAdd';
import DepartmentDetails from './Pages/Departments/DepartmentDetails';
import DepartmentUpdate from './Pages/Departments/DepartmentsUpdate';

import FiliereAdd from './Pages/Filieres/FiliereAdd'
import FiliereDetails from './Pages/Filieres/FiliereDetails'
import FiliereUpdate from './Pages/Filieres/FiliereUpadate';


import NiveauAdd from './Pages/Niveaux/NiveauAdd'
import NiveauDetails from './Pages/Niveaux/NiveauDetails'
import NiveauUpdate from './Pages/Niveaux/NiveauUpdate';


import OptionAdd from './Pages/Options/OptionAdd'
import OptionDetails from './Pages/Options/OptionDetails'
import OptionUpdate from './Pages/Options/OptionUpdate';

import StudyUnitAdd from './Pages/StudiesUnits/StudyUnitAdd'
import StudyUnitDetails from './Pages/StudiesUnits/StudyUnitDetails';

import StudentPreInscription   from './Pages/Student/StudentPreInscription'
import Themes   from './Pages/Student/discussion-themes'


import HomeStudent from  './Pages/StudentDashboard/Home';


import NoPage from './Pages/NoPage';
import TeacherShortInscription from './Pages/Teacher/shortinscription';
import EmploiDeTemp from './Pages/TeacherDashboad/EmploiDeTemp';
import ListEmploiTemp from './Pages/TeacherDashboad/list-emploi-temp';
import DiscussionList from './Pages/TeacherDashboad/DiscussionList';
import Discussion from './Pages/TeacherDashboad/discussions';
import DevoirsList from './Pages/TeacherDashboad/devoirs-list';
import Devoir from './Pages/TeacherDashboad/devoirs';
import DevoirView from './Pages/TeacherDashboad/devoirs view';
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
            <Route index element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/login' element={<Login />} />

            <Route path='/school-add' element={<SchoolAdd />} />
            <Route path='/school-update/:id' element={<SchoolUpdate />} />
            <Route path='/school-list' element={<SchoolList />} />
            <Route path='/school-details/:id' element={<SchoolDetails />} />

            <Route path='/fac-add/:schoolId' element={<FacAdd />} />
            <Route path='/fac-details/:id' element={<FacDetails />} />
            <Route path='/fac-update/:id' element={<FacUpdate />} />


            <Route path='/department-add/:facId' element={<DepartmentAdd />} />
            <Route path='/department-details/:id' element={<DepartmentDetails />} />
            <Route path='/department-update/:id' element={<DepartmentUpdate />} />


            <Route path='/filiere-add/:departmentId' element={<FiliereAdd />} />
            <Route path='/filiere-details/:id' element={<FiliereDetails />} />
            <Route path='/filiere-update/:id' element={<FiliereUpdate />} />


            <Route path='/niveau-add/:filiereId' element={<NiveauAdd />} />
            <Route path='/niveau-details/:id' element={<NiveauDetails />} />
            <Route path='/niveau-update/:id' element={<NiveauUpdate />} />


            <Route path='/option-add/:filiereId' element={<OptionAdd />} />
            <Route path='/option-details/:id' element={<OptionDetails />} />
            <Route path='/option-update/:id' element={<OptionUpdate />} />

            <Route path='/study-unit-add/:niveauId/:optionId' element={<StudyUnitAdd />} />
            <Route path='/study-unit-add/:niveauId/' element={<StudyUnitAdd />} />
            <Route path='/study-unit-details/:id/' element={<StudyUnitDetails />} />


            <Route path='/teacher-add/' element={<TeacherAdd />} />
            <Route path='/teacher-add/:departmentId' element={<TeacherAdd />} />
            <Route path='/teacher-list' element={<TeacherList />} />
            <Route path='/teacher-update/:code' element={<TeacherUpdate />} />
            <Route path='/teacher-inscription' element={<TeacherInscription />} />
            <Route path='short-inscription' element = {<TeacherShortInscription />} />
            <Route path='/DashboadTeacher' element={<Hometeacher/>} />
            <Route path='/DashboadParent' element={<ParentInfo/>} />
            <Route path='/TableTeacher' element={<TimeTable/>} />
            <Route path='/MatiereTeacher' element={<MatiereTable/>} />
            <Route path='/TimeTeacher' element={<AddTime/>} />
            <Route path='/TimeUpdate' element={<UpdateTime/>} />
            <Route path='/profile' element={<Profile/>} />

            <Route path='/course-list/:ueId' element={<CourseList/>} />
            <Route path='/course-details/:courseId' element={<CourseDetails/>} />
            <Route path='/exam-show/:examId' element={<ExamsTeacher/>} />
            <Route path='/exam-details/:examId' element={<ExamDetail/>} />
            <Route path='/exam-add/:ueId' element={<ExamsAdd/>} />
            <Route path='/examen-update' element={<ExamsUpdate/>} />
            <Route path='/course-add/:ueId' element={<CourseAdd/>} />
            <Route path='/students-list/:ueId' element={<StudentsList/>} />

            <Route path='/profile-view/:username' element={<Profile/>} />
            <Route path='/emploi-de-temp' element={<EmploiDeTemp />} />
            <Route path='/list-emploi' element={<ListEmploiTemp />} />
            //<Route path='/discussions' element={<Themes />} />
            
            <Route path='/parent-form' element={<ParentForm />} />
            <Route path='/discussions-list' element={<DiscussionList />} />
            <Route path='/homeworks-list' element={<DevoirsList />} />
            <Route path='/homeworks/:devoirId' element={<Devoir />} />
            <Route path='/homeworks-response/:responseId/view' element={<DevoirView />} />
            <Route path='/discussions/:themeId' element={<Discussion />} />
            

            <Route path='/StudendDashboard' element={<HomeStudent />} />

            <Route path='/student-preinscription' element={<StudentPreInscription />} />


            <Route path='*' element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
