import React, { swal, useState, useEffect } from 'react';
import NavBar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import Axios from 'axios';
import ENCRYPTION_KEY from '../../key';
import encryptTextWithKey from './../../utils';
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js' ; 

function CourseDetails(){
  const navigateTo = useNavigate();
  const [course, setCourse] = useState([]);
  const [comments, setComments] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [userCode, setUserCode] = useState('');
  const [userInf, setUserInf] = useState([]);
  const [UE, setUE] = useState([]);
  const [filesOfCourses, setFilesOfCourses] = useState([])
  const [teacher, setTeacher] = useState([]);
  const [msg, setMsg] = useState('');
  const [content, setContent] = useState('');
  const [idToRespond, setIdToRespond] = useState(0);
  const [parentCommentId, setParentCommentId] = useState(null);


  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');

  const {courseId} = useParams();
  if(userCode === ''){
    try {
                const encryptedData = sessionStorage.getItem('userInfo');
                if (encryptedData) {
                  const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
                  if (decryptedData) {
                    const userInfo = JSON.parse(decryptedData);
                    setUserCode(userInfo.code);
                    setUserInf(userInfo);
                    }
                }else{
                    alert("Page only permit to teachers and students");
                    navigateTo('/login');
                }}catch(err){
                    
        }
    }
 const commentsLength = ()=>{
    let numComments = comments.length;
    let numResponses = comments.reduce(
      (sum, comment) => sum + comment.responses.length,
      0
    );
    return numComments+ numResponses;
 };
    const createComment = async (e, id = null,userToRespond=null, UserToRespondName=null) => {
      e.preventDefault();
      try {
    
        // Créer l'objet de données à envoyer
        const data = {
          userId: userInf.user.userId,
          content: content,
          responseOfCommentId:id, 
          type:id===null?"comment":"response",
          userToRespond:userToRespond,
          UserToRespondName:UserToRespondName

        };
        console.log(data);
        // Envoyer la requête POST avec Axios
       await Axios.post(`https://${domainName}/api/comment-add/${courseId}`, data)
       .then((res)=>{
        console.log(res.data);
        setMsg(res.data)
        setContent('');
        Axios.get(`https://${domainName}/api/course-details/${courseId}`)
          .then((response) => {
            console.log(response.data);
            setCourse(response.data.course);
            setUE(response.data.ue);
            setComments(response.data.comments);
            setFilesOfCourses(response.data.files);
            setTeacher(response.data.teacher);
            setIdToRespond(0);  
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((err)=>{
        console.log(err);
      })
    
      } catch (error) {
        console.error(error);
      }
    };
    



       useEffect(() => {
        if(!course || course.length === 0){
        Axios.get(`https://${domainName}/api/course-details/${courseId}`)
          .then((response) => {
            console.log(response.data);
            setCourse(response.data.course);
            setUE(response.data.ue)
            setComments(response.data.comments)
            setFilesOfCourses(response.data.files)
            setTeacher(response.data.teacher)
          })
          .catch((error) => {
            console.error(error);
          });}

      },[]);
    return (

    <div className="main-wrapper">
        <NavBar user="Teacher"/>
        <SideBar />
                    <div class="page-wrapper">
            <div class="content container-fluid">
            <div class="row justify-content-center">
            <div class="col-lg-10 col-xl-9">

            <div class="blog-view">
            <div class="blog-single-post">
            <Link to={`../../course-list/${course.ueId}`} class="back-btn"><i class="feather-chevron-left"></i> Back</Link>
            <div class="blog-image">
            <a href="javascript:void(0);"><img alt src="../../assets/img/pdf-default.png" class="img-fluid"/></a>
            </div>
            <h3 class="blog-title">{course?course.title?course.title:"Loading in progess ..": "Something went wrong .."}</h3>
            <div class="blog-info">
            <div class="post-list">
            <ul>
            <li>
            <div class="post-author">
              <a href="profile.html">
                <img src={`${teacher ? (teacher.profilePicture ? teacher.profilePicture : '../../assets/img/profiles/avatar-14.jpg') : '#'}`} alt="Post Author" />
                <span>{teacher ? (teacher.fullname ? teacher.fullname : teacher.firstname ? teacher.firstname : 'Prof ..') : 'Loading ..'}</span>
              </a>
            </div>
            </li>
            <li><i class="feather-clock"></i> {course?course.uploadedDate?course.uploadedDate:"Loading in progess ..": "Something went wrong .."}</li>
            <li><i class="feather-message-square"></i> {comments?comments.length:"loading .."} Comments</li>
            <li><i class="feather-grid"></i> {UE?UE.code?`${UE.code}: ${UE.name}`:"loading ..":"Something went wrong"}</li>
            </ul>
            </div>
            </div>

            <div class="blog-info">
            <div class="post-list">
            <ul>
              <h4>Files Attached:</h4></ul>
            </div>
            <div class="post-list">
            <ul>
              {filesOfCourses?filesOfCourses.length >0? filesOfCourses.map((fil)=>(
            <li><Link to={"/uploaded/"+ fil.url} target="blank  "><img alt src="../../assets/img/pdf-default-mini.png" width="27em" class="img-fluid"/> File</Link> </li>)):"No files attached.":"loading .."}
            </ul>
            </div>
            </div>

            <div class="blog-content">
            <p><h4>Description: </h4>
              {course?course.description?course.description:"No description added":"loading.."}
              </p>
            </div>
            </div>

            <div class="card new-comment clearfix">
            <div class="card-header">
            <h4 class="card-title">Leave Comment</h4>
            </div>
            <div class="card-body">
            <form>
            {msg&&msg.length>0?(<div class="alert alert-success alert-dismissible fade show" role="alert">
        <strong>{msg}</strong>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>):''}
            <div class="form-group mb-6">
            <textarea rows="4" class="form-control bg-grey" placeholder="Comments"value={content} onChange = {(e)=>{setContent(e.target.value)}}></textarea>
            </div>
            <div class="submit-section">
          <button class="submit-btn btn-primary btn-blog" type="submit" onClick = {(e)=>{createComment(e)}} >Submit</button>
            </div>
            </form>
            </div>
            </div>


            <div class="card blog-comments">
              <div class="card-header">
                <h4 class="card-title">Comments ({comments && comments.length ? commentsLength() : ''})</h4>
              </div>
              <div class="card-body pb-0">
                <ul class="comments-list">
                  {comments && comments.length ? (
                    comments.map((com) => (
                      <li key={com.id}>
                        <div class="comment">
                          <div class="comment-author">
                            <img class="avatar" alt="" src={com.profilePicture?com.profilePicture:"../../assets/img/profiles/avatar-13.jpg"} />
                          </div>
                          <div class="comment-block">
                            <div class="comment-by">
                              <h5 class="blog-author-name">{com.firstname?com.lastname + com.firstname:com.fullname?com.fullname:com.code} <span class="blog-date"> <i class="feather-clock me-1"></i>{com.uploadedDate}</span></h5>
                            </div>
                            <p>{com.content}</p>
                            <a class="comment-btn" href="javascript:void(0);" onClick={() => setIdToRespond(com.id)}>
                              <i class="fa fa-reply me-2"></i> Reply
                            </a>
                          </div>
                        </div>  
                        <ul class="comments-list reply">
                        {com.responses.length>0?com.responses.map((response)=>(
                            <div key={response.id}>
                              <li>
                                <div class="comment">
                                  <div class="comment-author">
                                    <img class="avatar" alt="" src={response.profilePicture?response.profilePicture:"../../assets/img/profiles/avatar-13.jpg"} />
                                  </div>
                                  <div class="comment-block">
                                    <div class="comment-by">
                                      <h5 class="blog-author-name">{response.firstname?response.lastname + response.firstname:response.fullname?response.fullname:response.code}<span class="blog-date"> <i class="feather-clock me-1"></i> {response.uploadedDate}</span></h5>
                                    </div>
                                    <p>{response.UserToRespondName? <span>{response.UserToRespondName} ** </span>:''} {response.content}</p>
                                    <a class="comment-btn" href="javascript:void(0);" onClick={()=>{setIdToRespond(com.id)}}>
                                      <i class="fa fa-reply me-2"></i> Reply
                                    </a>
                                  </div>
                                </div>
                                {idToRespond === response.id && (
                                <div class="card new-comment clearfix">
                                  <div class="card-header">
                                      <h5 class="blog-author-name">Respond To {response.firstname?response.lastname + response.firstname:response.fullname?response.fullname:response.code}</h5>
                                  </div>
                                  <div class="card-body">
                                    <form>
                                      {msg?msg:''}
                                      <div class="form-group mb-6">
                                          <textarea rows="2" class="form-control bg-grey" placeholder="Comments" value={content} onChange = {(e)=>{setContent(e.target.value)}}></textarea>
                                      </div>
                                      <div class="submit-section">
                                          <button class="submit-btn btn-primary btn-blog" type="submit" onClick = {(e)=>{createComment(e, idToRespond, com.userId1?com.userId1:com.userId2, response.firstname?response.lastname + response.firstname:response.fullname?response.fullname:response.code)}} >Submit</button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                )}
                              </li>
                            </div>
                          )):(
                            <span>No responses yet</span>
                          )}
                          </ul>
                          {idToRespond === com.id && (
                           <div class="card new-comment clearfix">
                            <div class="card-header">
                                <h5 class="blog-author-name">Respond To {com.firstname?com.lastname + com.firstname:com.fullname?com.fullname:com.code}</h5>
                            </div>
                            <div class="card-body">
                              <form>
                                {msg?msg:''}
                                <div class="form-group mb-6">
                                    <textarea rows="2" class="form-control bg-grey" placeholder="Comments" value={content} onChange = {(e)=>{setContent(e.target.value)}}></textarea>
                                </div>
                                <div class="submit-section">
                                    <button class="submit-btn btn-primary btn-blog" type="submit" onClick = {(e)=>{createComment(e, idToRespond, com.userId?com.userId:null, com.firstname?com.lastname + com.firstname:com.fullname?com.fullname:com.code)}} >Submit</button>
                                </div>
                              </form>
                            </div>
                            </div>
                           )}
                      </li>
                    ))
                  ) : (
                    <span>No comments yet</span>
                  )}
                </ul>
              </div>
            </div>


            <div class="card blog-share clearfix">
            <div class="card-header">
            <h4 class="card-title">Share the post</h4>
            </div>
            <div class="card-body">
            <ul class="social-share">
            <li><a href="#"><i class="feather-twitter"></i></a></li>
            <li><a href="#"><i class="feather-facebook"></i></a></li>
            <li><a href="#"><i class="feather-linkedin"></i></a></li>
            <li><a href="#"><i class="feather-instagram"></i></a></li>
            <li><a href="#"><i class="feather-youtube"></i></a></li>
            </ul>
            </div>
            </div>
            </div>
            </div>
            </div>

            </div>
            </div>

</div>


    )}
    export default  CourseDetails;