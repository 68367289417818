import React, { useState, useEffect } from 'react';
import NavBar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import Axios from 'axios';
import ENCRYPTION_KEY from '../../key';
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'

function NiveauDetails() {

  const [niveau, setNiveau] = useState({studyUnits: [], options: []});
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const {id}  = useParams();
  const [userInf, setUserInf] = useState([]);
  const [userCode, setUserCode] = useState('');

  if(userCode === ''){
    try {
        const encryptedData = sessionStorage.getItem('userInfo');
        if (encryptedData) {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            const userInfo = JSON.parse(decryptedData);
            setUserInf(userInfo);
            setUserCode(userInfo.code)

        }
    }catch(err){
            
        }
    }

  const navigate = useNavigate();


  const url = new URL(window.location.href);
  const domainName = url.hostname.replace(/^www\./, '');
  
  useEffect(() => {
    Axios.get(`https://${domainName}/api/niveau-details/${id}`)
      .then((response) => {
        console.log(response.data);
        setNiveau(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

  },[id]);
  
  const displaystudyUnits = () => {
    if(niveau.studyUnits.length === 0) {
      return (
        <>
          This niveau has no study Unit yet, {userInf && userInf.type === 'admin' && (<Link to={niveau.niveau? `/study-unit-add/${niveau.niveau.id}/`:'#'}>Add one</Link>)}
        </>
      );
    } else {
      return (
        niveau.studyUnits.map((studyUnit, key) => (
          <tr
            key={key}
            onClick={() => navigate(`/study-unit-details/${studyUnit.id}`)}
            style={{ cursor: 'pointer', backgroundColor: hoveredIndex === key ? 'lightgray' : 'inherit'}}
            onMouseEnter={() => setHoveredIndex(key)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <td>{studyUnit.id}</td>
            <td>{studyUnit.code}</td>
            <td>{studyUnit.name}</td>
            <td>{studyUnit.credit}</td>
            <td>{studyUnit.description}</td>
            <td>{studyUnit.optional ? 'Yes' : 'No'}</td>
          </tr>
        ))
      );
    }
  }

  const displayOptions = () => {
    if(niveau.options.length === 0) {
      return (
        <>
          This option has no Option yet, {userInf && userInf.type === 'admin' && (<Link to={niveau.niveau? `/option-add/${niveau.niveau.id}`: '#'}>Add one</Link>)}
        </>
      );
    } else {
      return (
        niveau.options.map((option, index) => (
          <tr
            key={index}
            onClick={() => navigate(`/option-details/${option.id}`)}
            style={{ cursor: 'pointer', backgroundColor: hoveredIndex === index ? 'lightgray' : 'inherit'}}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <td>{option.id}</td>
            <td>{option.name}</td>
            <td>{option.creationDate}</td>
            <td>{option.description}</td>
          </tr>
        ))
      );
    }
  }

  return (
    <div className="main-wrapper">
      <NavBar />
      <SideBar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Niveau: {niveau.niveau ? niveau.niveau.name : ''}</h3>
                <ul className="breadcrumb"></ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                
                <div className="card-body">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="row">
                      <div class="page-header">
                        <div class="row align-items-center">
                          <div class="col">
                            <h3 class="page-title">All Studies Units: <span className='text-blue'>{niveau.studyUnits ? niveau.studyUnits.length : 0}</span></h3>
                          </div>
                              <div class="col-auto text-end float-end ms-auto download-grp">
                                <a href="#"  class="btn btn-outline-primary me-2"><i class="fas fa-download"></i> Download</a>
                                  {userInf && userInf.type === 'admin' && (<> <Link to={niveau.niveau? `/study-unit-add/${niveau.niveau.id}/` : '#'} title="Add a new studyUnit for this niveau" class="btn btn-primary"><i class="fas fa-plus"></i></Link>
                                 <Link to={niveau.niveau? `/niveau-update/${niveau.niveau.id}/` : '#'} title="Update this niveau" class="btn btn-primary"><i class="fas fa-edit"></i></Link></>)}
                              </div>
                            </div>
                          </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            className="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <label>
                              Show{' '}
                              <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                className="custom-select custom-select-sm form-control form-control-sm"
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>{' '}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6"></div>
                      </div>
                      <div className="row">
                          <div className="col-sm-12">
                            <table className="datatable table table-stripped dataTable no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                              <thead>
                                <tr role="row">
                                  <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '155.797px' }}>
                                    Id
                                  </th>
                                  <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '155.797px' }}>
                                    Code
                                  </th>

                                  <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '155.797px' }}>
                                    Study Unit Name
                                  </th>
                                  <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{ width: '48.3594px' }}>
                                    Credit
                                  </th>
                                  <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{ width: '48.3594px' }}>
                                    Description
                                  </th>
                                  <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{ width: '48.3594px' }}>
                                    Optional
                                  </th>

                                </tr>
                              </thead>
                              <tbody>
                                {displaystudyUnits()}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5"></div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="DataTables_Table_0_paginate"
                          >
                            <ul className="pagination">
                              <li
                                className="paginate_button page-item previous disabled"
                                id="DataTables_Table_0_previous"
                              >
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="0"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  Previous
                                </a>
                              </li>
                              <li className="paginate_button page-item active">
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="1"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  1
                                </a>
                              </li>
                              <li className="paginate_button page-item ">
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="2"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  2
                                </a>
                              </li>
                              <li className="paginate_button page-item next" id="DataTables_Table_0_next">
                                <a
                                  href="#"
                                  aria-controls="DataTables_Table_0"
                                  data-dt-idx="3"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  Next
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>



                       <div className="row">
                          <div class="page-header">
                            <div class="row align-items-center">
                              <div class="col">
                                <h3 class="page-title">All Options: <span className='text-blue'>{niveau.options ? niveau.options.length : 0}</span></h3>
                              </div>
                                 {userInf && userInf.type === 'admin' && (
                                 <>
                                  <div class="col-auto text-end float-end ms-auto download-grp">
                                    <a href="#"  class="btn btn-outline-primary me-2"><i class="fas fa-download"></i> Download</a>
                                     <Link to={`/option-add/${niveau.niveau ? (niveau.niveau.id ): ('#')}`} title="Add a new option for this niveau" class="btn btn-primary"><i class="fas fa-plus"></i></Link>
                                  </div>
                                  </>)}
                                </div>
                              </div>
                            <div className="col-sm-12 col-md-6">
                              <div
                                className="dataTables_length"
                                id="DataTables_Table_0_length"
                              >
                                <label>
                                  Show{' '}
                                  <select
                                    name="DataTables_Table_0_length"
                                    aria-controls="DataTables_Table_0"
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>{' '}
                                  entries
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6"></div>
                          </div>
                          <div className="row">
                              <div className="col-sm-12">
                                <table className="datatable table table-stripped dataTable no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                                  <thead>
                                    <tr role="row">
                                      <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '155.797px' }}>
                                        Id
                                      </th>
                                      <th className="sorting_asc" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '155.797px' }}>
                                        Option Name
                                      </th>
                                      <th className="sorting" tabIndex="0" aria-controls="DataTables_Table_0" rowSpan="1" colSpan="1" aria-label="Age: activate to sort column ascending" style={{ width: '48.3594px' }}>
                                        Description
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {displayOptions()}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-5"></div>
                            <div className="col-sm-12 col-md-7">
                              <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                <ul className="pagination">
                                  <li className="paginate_button page-item previous disabled" id="DataTables_Table_0_previous">
                                    <a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabIndex="0" className="page-link">Previous</a>
                                  </li>
                                  <li className="paginate_button page-item active">
                                    <a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabIndex="0" className="page-link">1</a>
                                  </li>
                                  <li className="paginate_button page-item ">
                                    <a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabIndex="0" className="page-link">2</a>
                                  </li>
                                  <li className="paginate_button page-item next" id="DataTables_Table_0_next">
                                    <a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabIndex="0" className="page-link">Next</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NiveauDetails;