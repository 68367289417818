import React, { swal, useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import Axios from 'axios';
import Themes from '../Student/discussion-themes'

function Discussion(){
    const {themeId} = useParams();
    const [discussions, setDiscussions] = useState([]);
    const [userInf, setUserInf] = useState([]);
    const [userCode, setUserCode] = useState('');
    const [userId , setUserId] = useState(null);
    const [message, setMessage] = useState('');

    const navigateTo = useNavigate();

    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');
    const numberOfstudent = localStorage.getItem("countStudent")
    let  decryptNumberOfStudents = null;
    if (numberOfstudent) {
        
     decryptNumberOfStudents = CryptoJS.AES.decrypt(numberOfstudent, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
}


    if(userId === null){
   try {
    const encryptedData = sessionStorage.getItem('userInfo');
    if (encryptedData) {
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
        const userInfo = JSON.parse(decryptedData);                           
            setUserCode(userInfo.code);
            setUserInf(userInfo);    
            setUserId(userInfo.user.userId);
    }}else{
        alert("Your're not allow to get this page !");
        navigateTo('/login');
    }}
catch(err){
    console.log(err)
    }
   }


   function fetchedThematik() {
    Axios.get(`https://${domainName}/api/discussions-get/${userId}`)
          .then((response) => {
            setDiscussions(response.data);
            console.log(response)
          })
          .catch((error) => {
            console.error(error);
            setMessage(error.message)
          });
   }



       useEffect(() => {
        fetchedThematik();

      },[]);
      
    return (

        <div className="main-wrapper">

            <NavBar user="Student"/>
            
            <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
            <div class="col">
            <h3 class="page-title">Discussions</h3>
            <ul class="breadcrumb">
        
            </ul>
            </div>
            </div>
            </div>

            <div class="row">
            <div class="col-sm-12">
            <div class="card card-table">
            <div class="card-body">

            <div class="page-header">
            <div class="row align-items-center">

            <ul class="breadcrumb">
            <li class="breadcrumb-item "><Link style={{color:"cadetblue"}} to="/discussions-list">back</Link></li>
            </ul>
            
          
            </div>
            </div>
                {message && <h3 style={{color:"mediumvioletred"}}>{message}  </h3>}

                    <Themes id={themeId}/>


            </div>
            </div>
            </div>
            </div>
                    
            </div>
            
        </div>
        </div>

    )}
    export default  Discussion;