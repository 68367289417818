import React, { swal, useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import { NavLink } from "react-router-dom";
import Axios from 'axios';


function AddTime(){

    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');
    
    return (

<div classNameName="main-wrapper">

        <NavBar user="Teacher"/>
            
        <SideBar />

    <div className="page-wrapper">
        <div class="content container-fluid">
                                    
            <div class="page-header">
            <div class="row align-items-center">
            <div class="col">
            <h3 class="page-title">Ajouter un emploi de temps</h3>
            <ul class="breadcrumb">
            </ul>
            </div>
            </div>
            </div>

            <div class="row">
            <div class="col-sm-12">
            <div class="card">
            <div class="card-body">
            <form>
            <div class="row">
            <div class="col-12">
            <h5 class="form-title"><span>Emploi de temps</span></h5>
            </div>
            <div class="col-12 col-sm-4">
            <div class="form-group local-forms">
            <label>Code Enseignant<span class="login-danger">*</span></label>
            <input type="text" class="form-control"/>
            </div>
            </div>
            <div class="col-12 col-sm-4">
            <div class="form-group local-forms">
            <label>Nom <span class="login-danger">*</span></label>
            <input type="text" class="form-control"/>
            </div>
            </div>
            <div class="col-12 col-sm-4">
            <div class="form-group local-forms">
            <label>Niveau<span class="login-danger">*</span></label>
            <input type="text" class="form-control"/>
            </div>
            </div>
            
            <div class="col-12 col-sm-4">
            <div class="form-group local-forms">
            <label>Cours <span class="login-danger">*</span></label>
            <input type="text" class="form-control"/>
            </div>
            </div>
            <div class="col-12 col-sm-4">
            <div class="form-group local-forms">
            <label>Date<span class="login-danger">*</span></label>
            <input class="form-control datetimepicker" type="date" placeholder="DD-MM-YYYY"/>
            </div>
            </div>
            <div class="col-12 col-sm-4">
            <div class="form-group local-forms">
            <label>Heure de debut <span class="login-danger">*</span></label>
            <input type="time" class="form-control"/>
            </div>
            </div>
            <div class="col-12 col-sm-4">
            <div class="form-group local-forms">
            <label>Heure de fin <span class="login-danger">*</span></label>
            <input type="time" class="form-control"/>
            </div>
            </div>
            <div className="col-12">
            <div class="student-submit">
            <button type="submit" class="btn btn-primary">Soumettre</button>
            </div>
            </div>
            </div>
            </form>
            </div>
            </div>
            </div>
            </div>
        

        </div>

                   

    </div>
                    


            
</div>

    )}
    
export default  AddTime;