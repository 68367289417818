const Comment = ({ comment }) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
    return (
      <div>
        <h4>Note:<span className="form-label text-danger"> {comment.note} / 100</span></h4>
        <p>{comment.content}</p>
        <div className="modal-footer">
          <p><h6>Posted at:</h6><span className="menu-title">{ new Date(comment.created_at).toLocaleDateString("en-FR", options)} </span></p>
        </div>
      </div>
    );
  };
  
  export default Comment;