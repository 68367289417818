import React, { useState, useEffect } from "react"
import Axios from "axios";
import {  useParams, useNavigate } from 'react-router-dom';


import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'

function StudyUnitAdd() {

    const [StudyUnitName, setStudyUnitName] = useState('');
    const [StudyUnitDescription, setStudyUnitDescripton] = useState('');
    const [StudyUnitCreationDate, setStudyUnitCreationDate]= useState('')

    const [AddStatus, setAddStatus] = useState('');

    const {filiereId}  = useParams()

    let navigate = useNavigate();
    const createTeacher = (e) => {
        e.preventDefault();

        


        const data = {
            name: StudyUnitName,
            description:StudyUnitDescription,
            description:StudyUnitDescription,
            creationDate: StudyUnitCreationDate
        }
        
        Axios.post(`http://localhost/api/option-add/${filiereId}`, data).then(() => {
            setStudyUnitName('');
            setStudyUnitCreationDate('');
            setStudyUnitDescripton('');


            setAddStatus(`The Study Unit has been created!`)
            navigate(`/niveau-details/${filiereId}`)
        })
    }
    useEffect(()=>{
    });

  return (
    <div className="main-wrapper">

        <NavBar />
        <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">

                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Add An Option </h3>
                            <ul className="breadcrumb">
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div class="card-header">
                                 <h5 class="card-title">Option Informations</h5>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Option's Name:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="StudyUnit Name" value={StudyUnitName}
                                                onChange={(event) => setStudyUnitName(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2">The Date Of Creation:</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="date" class="form-control input-height" placeholder="" aria-describedby="StudyUnit Name" value={StudyUnitCreationDate}
                                                onChange={(event) => setStudyUnitCreationDate(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>
                                        

                                    <div class="form-group row">
                                        <label class="col-form-label col-lg-2"> Description</label>
                                        <div class="col-lg-10">
                                            <div class="input-group input-group-lg">
                                                <span class="input-group-text" id="sizing-addon1">#</span>
                                                <input type="text" class="form-control input-height" placeholder="" aria-describedby="StudyUnit Address" value={StudyUnitDescription}
                                                onChange={(event) => setStudyUnitDescripton(event.target.value)} required/>
                                            </div>
                                         </div>
                                    </div>

                                    

                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="  btn btn-primary btn-rounded" onClick={createTeacher}>Submit</button>
                                            </div>
                                        </div>

                                        <h3>{AddStatus}</h3>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default StudyUnitAdd