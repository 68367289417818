import React, { swal, useState, useEffect } from 'react'
import NavBar from '../../Components/NavBar'
import SideBar from '../../Components/SideBar'
import ENCRYPTION_KEY from '../../key'
import encryptTextWithKey from './../../utils'
import { Link,  useParams, useNavigate, NavLink } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import Axios from 'axios';

function DiscussionList(){

    const [discussions, setDiscussions] = useState([]);
    const [userInf, setUserInf] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [userCode, setUserCode] = useState('');
    const [userId , setUserId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');
    const [description, setSetDescription] = useState('');
    const [theme, setTheme] = useState('');

    const navigateTo = useNavigate();

    const url = new URL(window.location.href);
    const domainName = url.hostname.replace(/^www\./, '');
    const numberOfstudent = localStorage.getItem("countStudent")
    let  decryptNumberOfStudents = null;
    if (numberOfstudent) {
        
     decryptNumberOfStudents = CryptoJS.AES.decrypt(numberOfstudent, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
}


    if(userId === null){
   try {
    const encryptedData = sessionStorage.getItem('userInfo');
    if (encryptedData) {
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
        const userInfo = JSON.parse(decryptedData);                           
            setUserCode(userInfo.code);
            setUserInf(userInfo);    
            setUserId(userInfo.user.userId);
    }}else{
        alert("Your're not allow to get this page !");
        navigateTo('/login');
    }}
catch(err){
    console.log(err)
    }
   }


   function fetchedThematik() {
    Axios.get(`https://${domainName}/api/discussions-get/${userId}`)
          .then((response) => {
            setDiscussions(response.data);
          })
          .catch((error) => {
            console.error(error);
            setMessage(error.message)
          });
   }

   const handleEditButtonClick = async (e) => {
    e.preventDefault();
    // Vérifiez si un fichier a été sélectionné
    if (theme.trim() !== '' && description.trim()!==0) {
      try {
        const ch  = userInf.type === "teacher" ? " (teacher)":""; 
        // Envoyez le fichier vers le backend
        await Axios.post(`https://${domainName}/api/theme-save`, {title:theme + ch, description:description , created_by: userInf.user.userId})
        .then((res)=>{

            setShowModal(false);
            setMessage(res.data.message);
            fetchedThematik();
            setTheme("");
            setSetDescription('');

            const id = setTimeout(() => {
            setMessage('');
          }, 4000);
          
            // Définir une fonction de rappel pour annuler la temporisation
            const cancel = () => {
              clearTimeout(id);
            };
          
            // Annuler la temporisation après 5 secondes
            setTimeout(cancel, 5000);
        })
        .catch((err)=>{
            console.log(err);
            setMessage(err.message)
        })
  
      } catch (error) {
        // Gérez les erreurs
        console.error('Error uploading file:', error);
      }
    }
  };
  

       useEffect(() => {
        fetchedThematik();

      },[]);
      
    return (

        <div className="main-wrapper">

            <NavBar user="Student"/>
            
            <SideBar />

        <div className="page-wrapper">
            <div className="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
            <div class="col">
            <h3 class="page-title">Discussions List {discussions && discussions.length && (<span style={{color:"mediumvioletred"}}> : {discussions.length}</span>)}</h3>
            <ul class="breadcrumb">
        
            </ul>
            </div>
            </div>
            </div>

            <div class="row">
            <div class="col-sm-12">
            <div class="card card-table">
            <div class="card-body">

            <div class="page-header">
            <div class="row align-items-center">
            
            {userInf && userInf.type && (userInf.type === 'teacher'  ||  userInf.type === 'student') &&(
                    <div class="col-auto text-end float-end ms-auto download-grp" title="start a new discussion thematic.">
                       
                    <a href="#" data-bs-toggle ='modal' data-bs-target='#create' class="btn btn-primary" >New discussion</a>

                    <div id="create" class="modal fade" tabindex="-1" style={{"display": "none"}} aria-hidden="true">
                                        <div className="modal-dialog ">
                                        <div className="modal-content ">
                                            <div className="modal-header">
                                            <h5 className="modal-title">Add a new discussion thematic</h5>
                                            <button type="button" className="btn-close" onClick={()=>{document.getElementById("create").style.display = "none"}} aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body text-center">
                                                     <div className="mb-4">
                                  <h5>Theme informations 

                                  </h5>
                                </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="fullname"
                                      placeholder="Title"
                                      value={theme}
                                      onChange={(e)=>{setTheme(e.target.value)}}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      name="theme"
                                      placeholder="description"
                                      value={description}
                                      onChange={(e)=>{setSetDescription(e.target.value)}}
                                      required></textarea>
                                    
                                  </div>
                                  
                                            </div>
                                            <div className="modal-footer">
                                                <span className="btn btn-info "  style={{marginRight:'70%'  }} onClick={handleEditButtonClick}> 
                                                    Save 
                                                </span>
                                                <button type="submit" className="btn btn-secondary" >{/*onClick={()=>{document.getElementById("create").style.display = "none"}*/}
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>

                    </div>)}
            </div>
            </div>
{message && <h3 style={{color:"mediumvioletred"}}>{message}  </h3>}
            <div class="table-responsive">
            <table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                <thead class="student-thread">
                    <tr>
                        <th>ID</th>
                        <th>Author</th>
                        <th>Title</th>
                        <th>Responses</th>
                        <th>Content</th>
                        <th class="text-end">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {discussions.map((mat, index)=>(
               <tr
                    key = {index}
                    onClick={() => navigateTo(`/discussions/${mat.id}`)}
                    style={{
                        cursor: 'pointer',
                        backgroundColor: hoveredIndex === index ? 'lightgray' : 'inherit',
                      }}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}

                    >
                        <td>{ mat.id }</td>
                        <td>{ mat.author    }</td>
                        <td>{ mat.title }</td>
                        <td>{mat.responses }</td>
                        <td>{mat.description ? mat.description.substring(0, 30):null }</td>
                        <td class="text-end">
                            <div class="actions">
                            <NavLink to={`/course-list/${mat.id}`} class="btn btn-sm bg-success-light me-2"> <i class="feather-eye"></i></NavLink>          
                        
                        </div>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>
            </div>
            </div>
            </div>
            </div>
            </div>
                    
            </div>
            
        </div>
        </div>

    )}
    export default  DiscussionList;